import ArrowRightIcon from '../../assets/icons/arrow-right.svg';
import {PageInfo} from '../../generated';

type PaginatorType = {
  count: number;
  pageInfo?: PageInfo;
  loadMore: (next: boolean) => Promise<void>;
};

export const PAGE_SIZE = 16;

const Paginator = ({count, pageInfo, loadMore}: PaginatorType) => {
  return (
    <div className="flex justify-between pb-8 items-center">
      <p className="font-semibold">{pageInfo?.entries} entries</p>
      <div className="flex items-center gap-6">
        {(pageInfo?.entries ?? 0) > PAGE_SIZE && (
          <>
            <div
              className={`flex h-[40px] w-[40px] items-center justify-center hover:border hover:border-mediumPurple rounded-lg bg-faintGrey ${
                pageInfo?.hasPrevPage
                  ? 'cursor-pointer'
                  : 'cursor-not-allowed opacity-50 '
              }`}
              onClick={() => pageInfo?.hasPrevPage && loadMore(false)}>
              <img
                className="h-[14px] w-[14px] rotate-180"
                src={ArrowRightIcon}
              />
            </div>
            <div className="flex  h-[40px] min-w-[40px] items-center justify-center rounded-lg border border-greyish px-3 py-1 text-center font-[16px]">
              {count || 1}
            </div>
            <div
              className={`flex h-[40px] w-[40px] items-center justify-center hover:border hover:border-mediumPurple rounded-lg bg-faintGrey ${
                pageInfo?.hasNextPage
                  ? 'cursor-pointer'
                  : 'cursor-not-allowed opacity-50'
              }`}
              onClick={() => pageInfo?.hasNextPage && loadMore(true)}>
              <img className="h-[14px] w-[14px]" src={ArrowRightIcon} />
            </div>
          </>
        )}
      </div>
      <p className="font-semibold">
        {pageInfo?.pages} page{(pageInfo?.pages ?? 0) !== 1 ? 's' : ''}
      </p>
    </div>
  );
};

export default Paginator;
