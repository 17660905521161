import React from 'react';

import {
  ComplianceStatus,
  UserStatus,
  OrderState,
  RefundStatus,
  PayoutStatus,
  TransactionStatus,
} from '../../generated';

type StatusProps = {
  status?:
    | ComplianceStatus
    | UserStatus
    | OrderState
    | RefundStatus
    | PayoutStatus
    | TransactionStatus;
};

export default function StatusDot({status}: StatusProps) {
  const statusColor = {
    [ComplianceStatus.AwaitingSubmission]: 'bg-wallGrey',
    [ComplianceStatus.Compliant]: 'bg-green-500',
    [ComplianceStatus.NonCompliant]: 'bg-red-500',
    [ComplianceStatus.Pending]: 'bg-yellow-500',
    [UserStatus.Active]: 'bg-green-500',
    [UserStatus.Created]: 'bg-orange',
    [UserStatus.Deactivated]: 'bg-red-500',
    [OrderState.Failed]: 'bg-failedRed',
    [OrderState.Success]: 'bg-successGreen',
    [PayoutStatus.Completed]: 'bg-successGreen',
    [PayoutStatus.Processing]: 'bg-greyBlueish',
    [PayoutStatus.PendingVerification]: 'bg-orange',
    [RefundStatus.Accepted]: 'bg-successGreen',
    [RefundStatus.InReview]: 'bg-yellow-500',
    [RefundStatus.Cancelled]: 'bg-failedRed',
    default: 'bg-gray-400',
  };

  return (
    <div
      className={`w-[12px] h-[12px] rounded-full ${
        status ? statusColor[status] : statusColor.default
      }`}
    />
  );
}
