import {PropsWithChildren, useCallback, useMemo} from 'react';
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';

import ActivityLogActiveIcon from '../assets/icons/activity-log-active.svg';
import ActivityLogIcon from '../assets/icons/activity-log.svg';
import DashBoardIcon from '../assets/icons/dash_category.svg';
import DashBoardActiveIcon from '../assets/icons/dash_categoryP.svg';
import Logout from '../assets/icons/logout.svg';
import MerchantActive from '../assets/icons/merchant-active.svg';
import Merchant from '../assets/icons/merchant.svg';
import MoneyChangeActiveIcon from '../assets/icons/money-change-active.svg';
import MoneyChangeIcon from '../assets/icons/money-change.svg';
import PayoutsActiveIcon from '../assets/icons/payouts-active.svg';
import PayoutsInactiveIcon from '../assets/icons/payouts-inactive.svg';
import ProfileActiveIcon from '../assets/icons/profile-active.svg';
import ProfileIcon from '../assets/icons/profile.svg';
import RefundsIcon from '../assets/icons/refunds.svg';
import RefundsActiveIcon from '../assets/icons/refunds_active.svg';
import {ReactComponent as RoleIcon} from '../assets/icons/role-icon.svg';
import SettingsIcon from '../assets/icons/setting.svg';
import SettingsActiveIcon from '../assets/icons/settingsActiveIcon.svg';
import UsersActive from '../assets/icons/users-active.svg';
import Users from '../assets/icons/users.svg';
import {useAuth} from '../utils/auth';
import {cn} from '../utils/reusablefunctions';
import userInitials from '../utils/reusablefunctions/getNameInitials';
import {useUserAuth} from '../utils/user';

interface MenuItemProps {
  title: string;
  icon: string;
  activeIcon: string;
  link: string;
  match?: string;
  isSpecial?: boolean;
}

interface MenuItemComponentProps {
  menu: MenuItemProps;
  index: number;
  isActive: (path: string) => boolean;
}

const MenuItem = ({menu, index, isActive}: MenuItemComponentProps) => {
  const navigate = useNavigate();

  return (
    <li
      key={index}
      onClick={() => navigate(menu.link)}
      className={cn(
        `mb-2 flex h-[48px] cursor-pointer items-center gap-x-4 rounded-lg p-2 text-sm text-foggy hover:bg-purpleGrey transition-all duration-200`,
        {
          'bg-lightPurple font-semibold text-darkPurple': isActive(
            menu.match || menu.link
          ),
        }
      )}>
      <img
        src={isActive(menu.match || menu.link) ? menu.activeIcon : menu.icon}
        className="block h-6 w-6"
      />
      <span className="flex-1 whitespace-nowrap break-keep text-base">
        {menu.title}
      </span>
    </li>
  );
};

const MainLayout = () => {
  const {logout} = useAuth();
  const {roleAccess} = useUserAuth();
  const {user} = useUserAuth();
  const {pathname} = useLocation();

  const menus: MenuItemProps[] = useMemo(
    () =>
      [
        {
          title: 'Dashboard',
          icon: DashBoardIcon,
          activeIcon: DashBoardActiveIcon,
          link: '/dashboard',
        },
        roleAccess.canViewAccounts && {
          title: 'Accounts',
          icon: Merchant,
          activeIcon: MerchantActive,
          link: '/accounts',
        },
        roleAccess.canViewBusinessProfiles && {
          title: 'Business Profiles',
          icon: ProfileIcon,
          activeIcon: ProfileActiveIcon,
          link: '/business-profiles',
        },
        roleAccess.canManageUsers && {
          title: 'Users',
          icon: Users,
          activeIcon: UsersActive,
          link: '/users',
          match: 'user',
        },
        roleAccess.canViewPayments && {
          title: 'Payments',
          icon: MoneyChangeIcon,
          activeIcon: MoneyChangeActiveIcon,
          link: '/payments',
        },
        roleAccess.canViewPayouts && {
          title: 'Payouts',
          icon: PayoutsInactiveIcon,
          activeIcon: PayoutsActiveIcon,
          link: '/payouts',
        },
        roleAccess.canViewRefundDetails && {
          title: 'Refunds',
          icon: RefundsIcon,
          activeIcon: RefundsActiveIcon,
          link: '/refunds',
        },
        roleAccess.canViewActivityLogs && {
          title: 'Activity Log',
          icon: ActivityLogIcon,
          activeIcon: ActivityLogActiveIcon,
          link: '/activity-log',
        },
        {
          title: 'Settings',
          icon: SettingsIcon,
          activeIcon: SettingsActiveIcon,
          link: '/settings',
          isSpecial: true,
        },
      ].filter((menu) => !!menu),
    [roleAccess]
  );

  const initials = useMemo(
    () =>
      user ? userInitials(user?.firstName || '', user?.lastName || '') : '',
    [user]
  );

  const isActive = useCallback(
    (path: string) => pathname.includes(path),
    [pathname]
  );

  const regularMenus = useMemo(
    () => menus.filter((menu) => !menu.isSpecial),
    [menus]
  );
  const specialMenus = useMemo(
    () => menus.filter((menu) => menu.isSpecial),
    [menus]
  );

  return (
    <RequireAuth>
      <div className="flex h-screen w-screen overflow-hidden">
        <div className="relative flex h-screen w-80 flex-col gap-7 bg-offWhite p-5 pt-10">
          <div className="flex flex-col">
            <div className="flex gap-2">
              <div className="relative inline-flex h-[45px] w-[45px] items-center justify-center rounded-full bg-mediumPurple text-xl font-bold text-white">
                {initials}
                <span className="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-green-500" />
              </div>
              <div className="flex flex-col">
                <h2 className="whitespace-nowrap text-black duration-300 capitalize">
                  {user?.firstName} {user?.lastName}
                </h2>
                <p className="whitespace-nowrap text-sm text-foggy duration-300">
                  online
                </p>
              </div>
            </div>
          </div>
          <div className="flex h-[55px] w-full items-center gap-2 rounded-[10px] border-[#D6D4DE] bg-purpleGrey px-2">
            <RoleIcon />
            <div className="flex flex-col">
              <p className="text-[16px] font-[500]">Role:</p>
              <p className="text-[12px] font-[600] text-greyscale">
                {user?.roles?.map((role, index) => (
                  <span key={role}>
                    {role}
                    {index !== (user?.roles?.length || 1) - 1 ? ',' : ''}{' '}
                  </span>
                ))}
              </p>
            </div>
          </div>
          <ul className="flex w-full flex-1 flex-col justify-between overflow-y-auto">
            <div id="side-menu">
              {regularMenus.map((menu, index) => (
                <MenuItem
                  key={index}
                  menu={menu}
                  index={index}
                  isActive={isActive}
                />
              ))}
            </div>
            <div id="side-menu">
              {specialMenus.map((menu, index) => (
                <MenuItem
                  key={index}
                  menu={menu}
                  index={index}
                  isActive={isActive}
                />
              ))}
              <li
                onClick={logout}
                className="mt-2 flex cursor-pointer items-center gap-x-4 rounded-md p-2 text-foggy hover:bg-purpleGrey hover:text-smoothRed"
                id="logoutButton">
                <img src={Logout} />
                <span className="flex-1 whitespace-nowrap break-keep text-smoothRed">
                  Log out
                </span>
              </li>
            </div>
          </ul>
        </div>

        <div className="w-full bg-white">
          <div className="flex-1 h-full overflow-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </RequireAuth>
  );
};

const RequireAuth = ({children}: PropsWithChildren) => {
  const {isAuthenticated} = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{from: location}} replace />;
  }
  return <>{children}</>;
};

export default MainLayout;
