import React, {useState} from 'react';

import Tooltip from './Tooltip';
import {ReactComponent as InfoCircleActive} from '../../../../assets/icons/info-circle-active.svg';
import {ReactComponent as InfoCircle} from '../../../../assets/icons/info-circle-filled.svg';

interface Props {
  icon: React.ReactNode;
  title: string;
  tooltip: string;
  currencyCode: string;
  balance: number;
}

const BalanceLarge: React.FC<Props> = ({
  icon,
  title,
  tooltip,
  currencyCode,
  balance,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div className="flex gap-5">
      {icon}
      <div className="flex flex-col">
        <p className="flex flex-row gap-2 text-xs font-semibold text-[#7F738E]">
          {title}
          <Tooltip text={tooltip}>
            <div
              className="relative cursor-pointer"
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}>
              {isHovering ? <InfoCircleActive /> : <InfoCircle />}
            </div>
          </Tooltip>
        </p>
        <p className="font-semibold text-2xl text-[#3A3441]">
          {currencyCode}{' '}
          {balance.toLocaleString('en-KE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || '0.00'}
        </p>
      </div>
    </div>
  );
};

export default BalanceLarge;
