import {ApolloError} from '@apollo/client';
import React, {useMemo, useState} from 'react';

import {FormSection} from './FormSection';
import {ReactComponent as DropdownIcon} from '../../../assets/icons/downArrow.svg';
import CustomInput from '../../../components/common/CustomInput';
import DropDown from '../../../components/common/DropDown';
import {
  useGetFeeProfilesQuery,
  useUpdateBusinessDetailsMutation,
} from '../../../generated';
import {cn} from '../../../utils/reusablefunctions';
import RenderErrorMessage from '../../../utils/reusablefunctions/errorMessage';
import {useUserAuth} from '../../../utils/user';

interface Props {
  businessId?: string;
  feeProfileId?: string;
  onSave: () => void;
  onError: (error: ApolloError) => void;
}

const Fees: React.FC<Props> = ({
  businessId,
  feeProfileId,
  onSave: onSaveCallback,
  onError,
}) => {
  const {roleAccess} = useUserAuth();
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [dropdownShown, setDropdownShown] = useState(false);

  const [values, setValues] = useState({feeProfileId});
  const [errors, setErrors] = useState<Partial<typeof values>>({});

  const valid = useMemo(() => {
    return !!values.feeProfileId;
  }, [values]);

  const {data: feeProfilesData} = useGetFeeProfilesQuery();
  const feeProfiles = feeProfilesData?.feeProfiles || [];

  const [updateBusiness] = useUpdateBusinessDetailsMutation({
    onCompleted: onSaveCallback,
    onError,
  });

  const onSave = async () => {
    try {
      setSubmitting(true);
      await updateBusiness({
        variables: {
          id: businessId || '',
          input: {
            feeProfileId: values.feeProfileId,
          },
        },
      });
      setSubmitting(false);
      setEditing(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onCancel = () => {
    setValues((prev) => ({...prev, feeProfileId}));
    setErrors({});
    setEditing(false);
  };

  return (
    <div id="fees">
      <FormSection
        preventEdit={!roleAccess.canEditBusinessProfile}
        title="Fees"
        isEditing={editing}
        isSubmitting={submitting}
        hasErrors={!valid}
        onEdit={() => setEditing(true)}
        onSave={onSave}
        onCancel={onCancel}>
        <div className="flex flex-col">
          <div className="flex gap-10">
            <div className="w-[480px]">
              <p className="flex sm:font-medium md:font-medium sm:text-[15px] md:text-[16px] font-[500] mb-1 gap-1 items-center leading-[16px] text-grey text-lg">
                Fees profile *
                {errors.feeProfileId && (
                  <RenderErrorMessage error={errors.feeProfileId} />
                )}
              </p>
              <p className="mb-1 font-light text-[12px] text-[#B2ABBF]">
                Defines fee structure applied for the various transactions.
              </p>
              {editing ? (
                <DropDown
                  dropDownPosition="center"
                  show={dropdownShown}
                  setShow={setDropdownShown}
                  className="w-full"
                  actionElement={
                    <div
                      className={cn(
                        'px-[10px] flex flex-row items-center justify-between w-[480px] bg-transparent border-[1px] rounded-[5px] h-[46px]',
                        dropdownShown
                          ? 'border-surfacePurple'
                          : 'border-dividerGrey'
                      )}>
                      <p className="w-[450px] overflow-hidden truncate font-[500] text-[14px] text-grey">
                        {feeProfiles?.find((x) => x.id === values.feeProfileId)
                          ?.name || 'Select Fee Profile'}
                      </p>
                      <DropdownIcon />
                    </div>
                  }>
                  <div className="shadow-sm cursor-pointer w-full overflow-y-scroll">
                    {feeProfiles?.map((profile) => (
                      <div
                        onClick={() => {
                          setDropdownShown(false);
                          setValues({...values, feeProfileId: profile.id});
                        }}
                        key={profile.id}
                        id={`dropdown-item-${profile.id}`}
                        className="hover:text-textPurple cursor-pointer flex items-center bg-white px-4 py-3 border-b-[1px] border-dividerGrey">
                        <p className="text-[14px] hover:text-textPurple text-grey font-[500]">
                          {profile.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </DropDown>
              ) : (
                <CustomInput
                  value={
                    feeProfiles.find((p) => p.id === values.feeProfileId)
                      ?.name || 'Select Fee Profile'
                  }
                />
              )}
            </div>
          </div>
        </div>
      </FormSection>
    </div>
  );
};

export default Fees;
