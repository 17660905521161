import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  Observable,
  FetchResult,
} from '@apollo/client';
import {toast} from '@cashiaApp/web-components';
import axios from 'axios';
import {GraphQLError} from 'graphql';
import React, {
  useContext,
  useCallback,
  useState,
  PropsWithChildren,
} from 'react';

import {
  localStorageRefreshTokenKey,
  localStorageAccessTokenKey,
  apiUrl,
  baseUrl,
} from './constants';
import {
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
  RefreshTokenDocument,
  Application,
  User,
} from '../generated';

interface SignInResponse {
  accessToken: string;
  refreshToken: string;
  user: User;
}

interface NewTokens {
  newAccessToken: string;
  newRefreshToken: string;
}

interface GraphQLResponse<T> {
  data?: {
    [key: string]: T | undefined;
  };
  errors?: GraphQLError[];
}

interface Context {
  isAuthenticated: boolean;
  logout(): Promise<void>;
  makeLoginWithPost(email: string, password: string): Promise<boolean>;
  LoginWithoutOTP(email: string, password: string): Promise<boolean>;
  loginError?: string;
  getNewTokens(): Promise<NewTokens | undefined>;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthContext = React.createContext<Context>({
  isAuthenticated: false,
  logout: async () => undefined,
  makeLoginWithPost: async () => false,
  LoginWithoutOTP: async () => false,
  getNewTokens: async () => undefined,
  setIsAuthenticated: () => false,
});

export const useAuth = (): Context => useContext(AuthContext);

export const promiseToObservable = (
  promise: Promise<NewTokens | undefined>
): Observable<unknown> =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });

export const setTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(localStorageAccessTokenKey, accessToken);
  localStorage.setItem(localStorageRefreshTokenKey, refreshToken);
};

const AuthProvider = ({children}: PropsWithChildren) => {
  const [loginError, setLoginError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem(localStorageAccessTokenKey)
  );

  const getNewTokens = async () => {
    const httpLink = createHttpLink({
      uri: apiUrl,
    });
    const client = new ApolloClient({
      cache: new InMemoryCache(),
      link: httpLink,
    });
    const localStorageRefreshToken = localStorage.getItem(
      localStorageRefreshTokenKey
    );
    if (!localStorageRefreshToken) return;
    let res: FetchResult<RefreshTokenMutation, Record<string, unknown>>;
    try {
      res = await client.mutate<
        RefreshTokenMutation,
        RefreshTokenMutationVariables
      >({
        mutation: RefreshTokenDocument,
        variables: {
          input: {
            refreshToken: localStorageRefreshToken,
          },
        },
      });
    } catch {
      await logout();
      return;
    }

    const newAccessToken = res.data?.refreshToken?.accessToken;
    const newRefreshToken = res.data?.refreshToken?.refreshToken;
    if (newAccessToken && newRefreshToken) {
      setTokens(newAccessToken, newRefreshToken);
      setIsAuthenticated(true);
      return {newAccessToken, newRefreshToken};
    }
  };

  const removeTokens = useCallback(() => {
    localStorage.removeItem(localStorageAccessTokenKey);
    localStorage.removeItem(localStorageRefreshTokenKey);
    setIsAuthenticated(false);
  }, []);

  const logout = useCallback(async (): Promise<void> => {
    removeTokens();
  }, [removeTokens]);

  const makeLoginWithPost = async (email: string, password: string) => {
    setLoginError('');
    const api = axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const response = await api.post<GraphQLResponse<boolean>>('/query', {
      query: `
        mutation RequestLoginOTP($input: LoginInput!) {
          requestLoginOTP(input: $input)
        }
      `,
      variables: {
        input: {
          password,
          email,
          app: Application.Admin,
        },
      },
    });

    if (response.data.errors && response.data.errors.length > 0) {
      const errorMessage =
        response.data.errors?.[0]?.message ??
        'Authentication failed. Please check your credentials.';
      if (errorMessage === 'user is deactivated') {
        toast.error(
          'Your account has been deactivated. Please contact support for assistance.'
        );
      } else {
        toast.error(errorMessage);
      }

      setLoginError(errorMessage);
      return false;
    }

    if (!response.data.data?.requestLoginOTP) {
      const errorMessage =
        'Unable to send verification code. Please try again later.';
      setLoginError(errorMessage);
      return false;
    }

    toast.success('Verification code sent to your email');
    return true;
  };

  const LoginWithoutOTP = async (email: string, password: string) => {
    const api = axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const response = await api.post<GraphQLResponse<SignInResponse>>('/query', {
      query: `
      mutation Login($input: LoginInput!) {
        login(input: $input) {
          accessToken
          refreshToken
          user {
            emailVerifiedAt
            createdAt
            roles
          }
        }
      }
    `,
      variables: {
        input: {
          password,
          email,
          app: Application.Admin,
        },
      },
    });

    if (response.data.errors && response.data.errors.length > 0) {
      const errorMessage =
        response.data.errors?.[0]?.message ??
        'Authentication failed. Please check your credentials.';
      if (errorMessage === 'user is deactivated') {
        toast.error(
          'Your account has been deactivated. Please contact support for assistance.'
        );
      } else {
        toast.error(errorMessage);
      }

      return false;
    }

    if (!response.data.data?.login) {
      const errorMessage = 'Unable to log in. Please try again later.';
      setLoginError(errorMessage);

      return false;
    }

    toast.dismiss();

    const {accessToken, refreshToken} = response.data.data.login;
    setTokens(accessToken, refreshToken);
    setIsAuthenticated(true);
    return true;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        logout,
        makeLoginWithPost,
        LoginWithoutOTP,
        getNewTokens,
        loginError,
        setIsAuthenticated,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
