import React, {useState} from 'react';

import BalanceLarge from './BalanceLarge';
import BalanceSmall from './BalanceSmall';
import Spoiler from './Spoiler';
import {ReactComponent as AvailableBalanceIcon} from '../../../../assets/icons/available_balance_icon.svg';
import {ReactComponent as BlockedBalanceIcon} from '../../../../assets/icons/balance_blocked.svg';
import {ReactComponent as HeldBalanceIcon} from '../../../../assets/icons/balance_held.svg';
import {ReactComponent as ReservedBalanceIcon} from '../../../../assets/icons/balance_reserved.svg';
import {ReactComponent as TickCircle} from '../../../../assets/icons/purple-tick-circle.svg';
import {ReactComponent as TotalBalanceIcon} from '../../../../assets/icons/total_balance_icon.svg';
import {
  BusinessBalanceFieldsFragment,
  CurrencyCode,
} from '../../../../generated';
import {cn} from '../../../../utils/reusablefunctions';

const readableCurrencyCodeInfo = (type: CurrencyCode) => {
  return {
    KES: {
      name: 'KSH',
      fullName: 'Kenya Shilling',
    },
    USD: {
      name: 'USD',
      fullName: 'US Dollar',
    },
  }[type];
};

interface Props {
  balances?: BusinessBalanceFieldsFragment[];
}

const Balances: React.FC<Props> = ({balances}) => {
  const [selectedCurrency, setSelectedCurrency] = useState(CurrencyCode.Kes);
  const currencyCode = readableCurrencyCodeInfo(selectedCurrency).name;

  return (
    <div className="m-6 p-8 flex flex-col items-center justify-between border-2  border-[#ececee] rounded-lg">
      <div className="flex gap-5 flex-col w-full">
        <h3 className="font-semibold text-2xl whitespace-nowrap">Balances</h3>
        <div className="flex gap-3">
          {Object.values(CurrencyCode).map((currency) => (
            <div
              key={currency}
              onClick={() => setSelectedCurrency(currency)}
              className={cn(
                'flex justify-between group w-40 p-3 rounded-md cursor-pointer transition-colors bg-transparent border border-[#D6D4DE] hover:bg-[#FAF7FF] hover:border-[#CCB1FF]',
                {
                  'bg-[#FAF7FF] border-[#CCB1FF]':
                    currency === selectedCurrency,
                }
              )}>
              <div className="flex flex-col gap-[4px]">
                <p
                  className={cn(
                    'text-[#B2ABBF] group-hover:text-[#9F55FF] text-sm font-semibold',
                    {
                      'text-[#9F55FF]': currency === selectedCurrency,
                    }
                  )}>
                  {readableCurrencyCodeInfo(currency).name} Balance
                </p>
                <p
                  className={cn(
                    'text-[#B2ABBF] group-hover:text-[#9F55FF] text-xs font-medium',
                    {
                      'text-[#9F55FF]': currency === selectedCurrency,
                    }
                  )}>
                  {readableCurrencyCodeInfo(currency).fullName}
                </p>
              </div>
              {currency === selectedCurrency && (
                <div className="flex justify-center items-center h-4 w-4 rounded-full">
                  <TickCircle />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex flex-col w-full">
          <div className="flex w-full">
            <div className="flex flex-1 p-8 border border-[#D6D4DE] rounded-md border-r-0 rounded-b-none rounded-r-none">
              <BalanceLarge
                title="Available Balance"
                tooltip="The funds that are immediately available for withdrawal."
                icon={<AvailableBalanceIcon />}
                currencyCode={currencyCode}
                balance={
                  balances?.find((bal) => bal.currency === selectedCurrency)
                    ?.available || 0
                }
              />
            </div>
            <div className="flex flex-1 p-8 border border-[#D6D4DE] border-l-0 rounded-b-none rounded-md rounded-l-none">
              <BalanceLarge
                title="Total Balance"
                tooltip="The sum of all funds associated with a merchant account, regardless of accessibility."
                icon={<TotalBalanceIcon />}
                currencyCode={currencyCode}
                balance={
                  balances?.find((bal) => bal.currency === selectedCurrency)
                    ?.total || 0
                }
              />
            </div>
          </div>
          <Spoiler>
            <div className="flex flex-row gap-6">
              <BalanceSmall
                title="Reserved Balance"
                tooltip="Funds withheld for
                chargebacks."
                currencyCode={currencyCode}
                balance={
                  balances?.find((bal) => bal.currency === selectedCurrency)
                    ?.reserved || 0
                }
                icon={<ReservedBalanceIcon />}
              />
              <BalanceSmall
                title="Held Balance"
                icon={<HeldBalanceIcon />}
                tooltip="Funds pending clearance or held for specific transactions."
                currencyCode={currencyCode}
                balance={
                  balances?.find((bal) => bal.currency === selectedCurrency)
                    ?.hold || 0
                }
              />
              <BalanceSmall
                title="Blocked Balance"
                icon={<BlockedBalanceIcon />}
                tooltip="Funds inaccessible due to transactions under investigation."
                currencyCode={currencyCode}
                balance={
                  balances?.find((bal) => bal.currency === selectedCurrency)
                    ?.blocked || 0
                }
              />
            </div>
          </Spoiler>
        </div>
      </div>
    </div>
  );
};

export default Balances;
