import {Button, Checkbox} from '@cashiaApp/web-components';
import {
  addHours,
  format,
  startOfDay,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import {enUS} from 'date-fns/locale';
import {useEffect, useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import {createStaticRanges, DateRangePicker} from 'react-date-range';
import {useSearchParams} from 'react-router-dom';

import {ReactComponent as Plus} from '../../../assets/icons/add-square-grey.svg';
import {ReactComponent as Close} from '../../../assets/icons/close-square.svg';
import {cn} from '../../../utils/reusablefunctions';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './custom-date-range.css';

type FilterButtonProps = {
  options?: string[];
  onApply: (filters: any[]) => void;
  className?: string;
  label: string;
  dateFilter?: boolean;
  initialFilters?: string[];
  displayLabels?: string[];
};

export type DateFilterObj = {
  startDate?: Date;
  endDate?: Date;
  key?: string;
};

const defaultDate: DateFilterObj = {
  startDate: addHours(startOfDay(new Date()), 3),
  endDate: new Date(),
  key: 'selection',
};

const customRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => {
      const start = startOfDay(new Date());
      const end = new Date();
      end.setHours(23, 59, 59, 999);
      return {
        startDate: start,
        endDate: end,
      };
    },
  },
  {
    label: 'Last 7 Days',
    range: () => {
      const end = new Date();
      end.setHours(23, 59, 59, 999);
      const start = subDays(startOfDay(new Date()), 6);
      return {
        startDate: start,
        endDate: end,
      };
    },
  },
  {
    label: 'Last 4 Weeks',
    range: () => ({
      startDate: subWeeks(startOfDay(new Date()), 4),
      endDate: new Date(),
    }),
  },
  {
    label: 'Last 3 Months',
    range: () => ({
      startDate: subMonths(startOfDay(new Date()), 3),
      endDate: new Date(),
    }),
  },
  {
    label: 'Last 12 Months',
    range: () => ({
      startDate: subMonths(startOfDay(new Date()), 12),
      endDate: new Date(),
    }),
  },
]);

const customInputRanges = [
  {
    label: 'days to today',
    range: (value: number) => ({
      startDate: subDays(new Date(), value),
      endDate: new Date(),
    }),
    getCurrentValue: (range: {startDate?: Date}) =>
      range.startDate
        ? Math.round(
            (new Date().getTime() - range.startDate.getTime()) /
              (1000 * 60 * 60 * 24)
          )
        : '',
  },
];

const FilterButton = ({
  options,
  onApply,
  label,
  dateFilter,
  displayLabels,
  className = '',
  initialFilters = [],
}: FilterButtonProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);

  const [filters, setFilters] = useState<string[]>(initialFilters);
  const [appliedFilters, setAppliedFilters] =
    useState<string[]>(initialFilters);
  const [date, setDate] = useState<DateFilterObj[]>([defaultDate]);
  const ref = useOnclickOutside(() => {
    setFilters(appliedFilters);
    setOpen(false);
  });

  useEffect(() => {
    const filterParam = searchParams.get(label.toLowerCase());
    if (filterParam) {
      const parsedFilters = filterParam.split(',');
      setFilters(parsedFilters);
      setAppliedFilters(parsedFilters);
    }
  }, [searchParams, label]);

  const handleSelect = (option: string) => {
    setFilters((prev) =>
      prev.includes(option)
        ? prev.filter((x) => x !== option)
        : [...prev, option]
    );
  };

  const handleDateApply = (selectedDate: DateFilterObj[]) => {
    const [filter] = selectedDate;
    if (filter?.startDate && filter?.endDate) {
      const startDate = new Date(filter.startDate);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(filter.endDate);
      endDate.setHours(23, 59, 59, 999);

      const adjustedDateRange = [
        {
          startDate,
          endDate,
          key: 'selection',
        },
      ];

      onApply(adjustedDateRange);
      setOpen(false);

      const formattedStartDate = format(startDate, 'dd/MM/yy');
      const formattedEndDate = format(endDate, 'dd/MM/yy');
      setAppliedFilters([`${formattedStartDate} - ${formattedEndDate}`]);
    }
  };

  return (
    <div className={`relative inline-block ${className}`}>
      <Button
        ref={ref}
        variant="outline"
        className={cn(
          `bg-faintGrey text-black gap-2 flex items-center font-semibold border-dividerGrey hover:shadow-sm hover:border-1
          hover:border-mediumPurple hover:bg-faintGrey`,
          {
            'bg-surfacePurple text-white hover:bg-surfacePurple hover:text-white':
              !!appliedFilters.length,
          }
        )}
        onClick={() => setOpen(!open)}>
        {!appliedFilters.length ? <Plus /> : null}
        {appliedFilters.length ? appliedFilters.join(', ') : label}
        {appliedFilters.length ? (
          <Close
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
              setAppliedFilters([]);
              setFilters([]);
              setDate([defaultDate]);
              onApply([]);
              setSearchParams((prev) => {
                prev.delete(label.toLowerCase());
                return prev;
              });
            }}
          />
        ) : null}
      </Button>

      {open && (
        <div
          ref={ref}
          className="absolute left-0 mt-2 bg-white shadow-md rounded-md w-max z-10">
          {dateFilter ? (
            <div>
              <div className="flex justify-center w-full my-3">
                <p className="font-semibold">Filter by Date</p>
              </div>
              <DateRangePicker
                showPreview
                retainEndDateOnFirstSelection
                preventSnapRefocus
                showDateDisplay={false}
                onChange={(item) => setDate([item.selection])}
                months={2}
                ranges={date}
                maxDate={new Date()}
                staticRanges={customRanges}
                inputRanges={customInputRanges}
                locale={enUS}
                direction="horizontal"
                calendarFocus="backwards"
                rangeColors={['#9F55FF']}
              />
              <div className="w-full flex justify-end mt-2">
                <div className="py-4 px-4 flex gap-10">
                  <Button
                    className="w-[300px] disabled:bg-surfaceGrey disabled:text-grey text-black bg-faintGrey font-semibold border-borderGrey border-[1px]"
                    onClick={() => setDate([defaultDate])}>
                    Clear
                  </Button>
                  <Button
                    onClick={() => handleDateApply(date)}
                    className="w-[300px] disabled:bg-surfaceGrey disabled:text-grey text-white bg-surfacePurple font-semibold">
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-3">
              <p className="font-semibold mb-2">Filter by {label}</p>
              {options?.map((option, i) => (
                <div
                  key={option}
                  className="flex items-center gap-3 mb-2 font-medium">
                  <Checkbox
                    className="border-[#BFBACB] checked:bg-surfacePurple checked:border-none"
                    checked={filters.includes(option)}
                    onClick={() => handleSelect(option)}
                  />{' '}
                  <span
                    className="cursor-pointer"
                    onClick={() => handleSelect(option)}>
                    {displayLabels?.length ? displayLabels[i] : option}
                  </span>
                </div>
              ))}
              <Button
                className="w-full disabled:bg-surfaceGrey disabled:text-grey text-white bg-surfacePurple font-semibold"
                disabled={!filters.length}
                onClick={() => {
                  onApply(filters);
                  setOpen(false);
                  setAppliedFilters(filters);
                  setSearchParams((prev) => {
                    prev.set(label.toLowerCase(), filters.join(','));
                    return prev;
                  });
                }}>
                Apply
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterButton;
