import {Modal, ModalContent, ModalTitle} from '@cashiaApp/web-components';
import React, {useCallback, useEffect, useState} from 'react';
import ReactJson from 'react-json-view';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as GreyExport} from '../../assets/icons/greyExport.svg';
import CustomSpinner from '../../components/common/CustomSpinner';
import EmptyState from '../../components/common/EmptyState';
import FilterButton, {
  DateFilterObj,
} from '../../components/common/FilterButton';
import Navbar from '../../components/common/Navbar';
import Paginator, {PAGE_SIZE} from '../../components/common/Paginator';
import Spinner from '../../components/common/Spinner';
import CommonTable from '../../components/common/table';
import useQueryParamState from '../../components/common/useQueryParamState';
import {
  ActivityType,
  ActorType,
  Entity,
  TargetEntityUnion,
  useGetActivityLogQuery,
  useGetActivityLogsQuery,
  UserRole,
} from '../../generated';
import capitalizeFirstLetter from '../../utils/reusablefunctions/capitalizeFirstLetter';
import formatDate from '../../utils/reusablefunctions/formatDate';
import {TableColumnType, TableDataType} from '../../utils/types/table.types';

const actionedByOptions = {
  Admin: ActorType.Admin,
  User: ActorType.User,
  System: ActorType.System,
};

const activityTypeOptions = {
  'Added Account': {
    activityType: ActivityType.Create,
    entity: Entity.User,
  },
  'Updated Account': {
    activityType: ActivityType.Update,
    entity: Entity.User,
  },
  'Added Business Profile': {
    activityType: ActivityType.Create,
    entity: Entity.Business,
  },
  'Updated Business Profile': {
    activityType: ActivityType.Update,
    entity: Entity.Business,
  },
};

const columns: TableColumnType[] = ['date', 'role', 'actioned_by', 'activity'];

const getTargetName = (target?: TargetEntityUnion | null) => {
  switch (target?.__typename) {
    case 'Business':
    case 'BusinessCategory':
      return target?.name;
    case 'User':
      return `${target?.firstName} ${target?.lastName}`;
    case 'PaymentLink':
      return target?.title;
    case 'Order':
      return target?.reference;
    case 'Payout':
      return target?.business?.name;
    default:
      return '';
  }
};

const targetType = (target?: TargetEntityUnion | null) =>
  target?.__typename === 'PaymentLink'
    ? 'payment link'
    : target?.__typename === 'User' && target?.roles?.[0] === UserRole.Merchant
      ? 'account'
      : target?.__typename?.toLocaleLowerCase();

const ActivityLog = () => {
  const {getParamsState, updateQueryParams} = useQueryParamState();

  const paramsState = getParamsState();
  const [count, setCount] = useState<number>(paramsState.page);
  const [currentCursor, setCurrentCursor] = useState<string | null>(
    paramsState.cursor
  );
  const [cursorStack, setCursorStack] = useState<string[]>([]);

  const [selectedDateFilters, setSelectedDateFilters] = useState<
    DateFilterObj[]
  >(paramsState.dateFilters || []);
  const [selectedActionedByFilters, setSelectedActionedByFilters] = useState<
    string[]
  >(paramsState.actionedByFilters || []);
  const [selectedActivityTypeFilters, setSelectedActivityTypeFilters] =
    useState<string[]>(paramsState.activityTypeFilters || []);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState('');
  const navigate = useNavigate();

  const {data, loading} = useGetActivityLogsQuery({
    variables: {
      input: {
        cursor: {
          first: PAGE_SIZE,
          after: currentCursor,
        },
        filter: {
          dateTime:
            selectedDateFilters.length &&
            selectedDateFilters[0]?.startDate &&
            selectedDateFilters[0]?.endDate
              ? {
                  endDate: selectedDateFilters[0].endDate,
                  startDate: selectedDateFilters[0].startDate,
                }
              : undefined,
          activityType: selectedActivityTypeFilters.length
            ? Array.from(
                new Set(
                  selectedActivityTypeFilters.map(
                    (filter) =>
                      activityTypeOptions[
                        filter as keyof typeof activityTypeOptions
                      ].activityType
                  )
                )
              )
            : undefined,
          entity: selectedActivityTypeFilters.length
            ? selectedActivityTypeFilters.map(
                (filter) =>
                  activityTypeOptions[
                    filter as keyof typeof activityTypeOptions
                  ].entity
              )
            : undefined,
          actorType: selectedActionedByFilters.length
            ? selectedActionedByFilters.map(
                (filter) =>
                  actionedByOptions[filter as keyof typeof actionedByOptions]
              )
            : undefined,
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    updateQueryParams({
      dateFilters: selectedDateFilters,
      actionedByFilters: selectedActionedByFilters,
      activityTypeFilters: selectedActivityTypeFilters,
      page: count,
      cursor: currentCursor,
    });
  }, [
    selectedDateFilters,
    selectedActionedByFilters,
    selectedActivityTypeFilters,
    count,
    currentCursor,
    cursorStack,
    updateQueryParams,
  ]);

  const resetPagination = () => {
    setCount(1);
    setCurrentCursor(null);
    setCursorStack([]);
  };

  const handleDateFilterChange = (filters: DateFilterObj[]) => {
    setSelectedDateFilters(filters);
    resetPagination();
  };

  const handleActionedByFilterChange = (filters: string[]) => {
    setSelectedActionedByFilters(filters);
    resetPagination();
  };

  const handleActivityTypeFilterChange = (filters: string[]) => {
    setSelectedActivityTypeFilters(filters);
    resetPagination();
  };

  const {data: selectedLogData, loading: selectedLogLoading} =
    useGetActivityLogQuery({
      variables: {
        id: selectedLog,
      },
      skip: !selectedLog,
    });

  const transformedData = React.useMemo(() => {
    if (!data?.activityLogs?.edges) return [];

    return data.activityLogs.edges.map((log) => {
      const target = log?.node?.target;
      return {
        id: log.node.id,
        date: formatDate(new Date(log.node?.createdAt as string), {
          withTime: true,
          withNumericDate: true,
        }),
        role: capitalizeFirstLetter(log.node.performedBy?.roles?.[0] || ''),
        actioned_by: [
          `${log.node.performedBy?.firstName} ${log.node.performedBy?.lastName}`,
          log?.node?.performedBy?.id,
        ],
        activity: [
          `${capitalizeFirstLetter(log.node.activityType)}d`,
          targetType(target as TargetEntityUnion),
          getTargetName(target as TargetEntityUnion),
        ],
      };
    }) as TableDataType[];
  }, [data]);

  const loadMore = useCallback(
    async (next: boolean) => {
      try {
        if (next && data?.activityLogs?.pageInfo?.hasNextPage) {
          const nextCursor = data?.activityLogs?.pageInfo?.endCursor;
          if (nextCursor) {
            if (currentCursor) {
              setCursorStack((prev) => [...prev, currentCursor]);
            }
            setCurrentCursor(nextCursor);
            setCount((prev) => prev + 1);
          }
        } else if (!next && count > 1) {
          if (cursorStack.length > 0) {
            const previousCursor = cursorStack[cursorStack.length - 1];
            if (previousCursor) {
              setCursorStack((prev) => prev.slice(0, -1));
              setCurrentCursor(previousCursor);
              setCount((prev) => prev - 1);
            }
          } else {
            setCurrentCursor(null);
            setCount(1);
          }
        }
      } catch (error) {
        console.error('Error loading more businesses:', error);
      }
    },
    [data, currentCursor, cursorStack, count]
  );

  return (
    <div className="h-screen">
      <Navbar omitSearch title="Activity Log" />
      <div className="px-6 py-4">
        <h2 className="text-2xl px-2 font-semibold" id="header">
          All Activities
        </h2>
        <div className="flex gap-4">
          <div id="filterByDate">
            <FilterButton
              label="Date"
              onApply={handleDateFilterChange}
              className="my-4"
              dateFilter
              initialFilters={
                selectedDateFilters?.length &&
                selectedDateFilters[0]?.startDate &&
                selectedDateFilters[0]?.endDate
                  ? [
                      `${formatDate(selectedDateFilters[0].startDate, {
                        withDate: true,
                        withTime: false,
                        withYear: true,
                        withMonth: true,
                        withNumericDate: true,
                      })} - ${formatDate(selectedDateFilters[0].endDate, {
                        withDate: true,
                        withTime: false,
                        withYear: true,
                        withMonth: true,
                        withNumericDate: true,
                      })}`,
                    ]
                  : []
              }
            />
          </div>
          <div id="filterByActionedBy">
            <FilterButton
              label="Actioned By"
              options={Object.keys(actionedByOptions)}
              onApply={handleActionedByFilterChange}
              className="my-4"
              initialFilters={selectedActionedByFilters}
            />
          </div>
          <div id="filterByActivity">
            <FilterButton
              label="Activity"
              options={Object.keys(activityTypeOptions)}
              onApply={handleActivityTypeFilterChange}
              className="my-4"
              initialFilters={selectedActivityTypeFilters}
            />
          </div>
        </div>
        {loading && !data ? (
          <CustomSpinner />
        ) : data?.activityLogs?.edges.length ? (
          <>
            <div className="flex flex-col h-full">
              <div className="bg-white rounded-lg" id="table">
                <CommonTable
                  columns={columns}
                  data={transformedData}
                  prettify
                  viewRecord={(id) => {
                    setModalOpen(true);
                    setSelectedLog(id);
                  }}
                />
              </div>
              <div className="p-6 mt-auto h-[60px]">
                <Paginator
                  count={count}
                  pageInfo={data?.activityLogs?.pageInfo}
                  loadMore={loadMore}
                />
              </div>
            </div>
          </>
        ) : (
          <EmptyState
            title="It's lonely out here"
            description="No activities. Looks like it was a quiet day, there were no activities."
          />
        )}
      </div>
      <Modal onOpenChange={setModalOpen} open={modalOpen}>
        <ModalContent className="p-8 border-lightGrey w-[640px] h-[520px] overflow-hidden overflow-y-scroll">
          <ModalTitle>
            <div className="flex mb-2">
              <p className="font-semibold text-2xl">Log Details</p>
            </div>
          </ModalTitle>
          {selectedLogLoading ? (
            <Spinner className="mt-0" />
          ) : (
            <div>
              <div className="flex justify-between items-center mb-3">
                <p className="text-textGrey">Actioned By:</p>
                <div className="flex gap-2 items-center">
                  <p className="font-semibold">{`${selectedLogData?.activityLog?.performedBy?.firstName || ''} ${selectedLogData?.activityLog?.performedBy?.lastName || ''}`}</p>
                  <GreyExport
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/users/user-details/${selectedLogData?.activityLog?.performedBy?.id}`
                      )
                    }
                  />
                </div>
              </div>
              <div className="flex justify-between items-center mb-3">
                <p className="text-textGrey">Role:</p>
                <div className="p-1 px-2 bg-lightPurple flex items-center justify-center rounded-[5px] border-[#CCB1FF] border-[1px]">
                  <p className="text-surfacePurple font-semibold">
                    {capitalizeFirstLetter(
                      selectedLogData?.activityLog?.performedBy?.roles?.[0] ||
                        ''
                    )}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center mb-3">
                <p className="text-textGrey">Date:</p>
                <p className="font-semibold">
                  {formatDate(
                    new Date(
                      (selectedLogData?.activityLog?.createdAt as string) || ''
                    ),
                    {
                      withTime: true,
                      withNumericDate: true,
                    }
                  )}
                </p>
              </div>
              <div className="mb-3">
                <p className="text-textGrey mb-1">Description:</p>
                <div className="w-full h-[85px] p-2 border-[1px] border-borderGrey rounded-md text-greyscale text-sm overflow-y-scroll">
                  <p className="font-semibold mb-1">
                    {`${capitalizeFirstLetter(
                      selectedLogData?.activityLog.performedBy?.firstName || ''
                    )}
                      ${selectedLogData?.activityLog.activityType.toLowerCase()}d
                      ${targetType(
                        selectedLogData?.activityLog
                          ?.target as TargetEntityUnion
                      )}:
                      ${getTargetName(
                        selectedLogData?.activityLog
                          ?.target as TargetEntityUnion
                      )}`}
                  </p>
                  <p>{selectedLogData?.activityLog?.description}</p>
                </div>
              </div>
              <div className="">
                <p className="text-textGrey mb-1">Metadata:</p>
                <div className="w-full  p-2 border-[1px] border-borderGrey rounded-md text-surfaceGrey h-[250px] overflow-y-scroll">
                  <div className="w-[430px]">
                    <ReactJson
                      displayDataTypes={false}
                      quotesOnKeys={false}
                      src={selectedLogData?.activityLog?.metadata as object}
                    />
                  </div>
                </div>
              </div>
              <div />
            </div>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ActivityLog;
