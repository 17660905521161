import React, {useEffect, useState} from 'react';

const HealthCheck = () => {
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    // Check if critical React dependencies are loaded
    const isReactHealthy = React && React.version;

    // Check if critical app state is accessible

    setStatus(isReactHealthy ? 'I am healthy' : 'unhealthy');
  }, []);

  // Return plain text for automated systems
  return (
    <div>
      <p>{status}</p>
    </div>
  );
};

export default HealthCheck;
