import React from 'react';

import Logo from '../../../assets/icons/cashia_logo.svg';
import {ReactComponent as CopyrightIcon} from '../../../assets/icons/copyright.svg';
import DashboardView from '../../../assets/images/admin_login_img.svg';

interface AuthLayoutProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
}

export default function AuthLayout({
  title,
  subtitle,
  children,
}: AuthLayoutProps) {
  const currentYear = new Date().getFullYear();

  return (
    <div className="flex h-screen">
      <div className="w-[40%] h-full flex flex-col justify-between items-center">
        <div className="pt-14">
          <img className="mb-4 w-[48px]" src={Logo} alt="Cashia Logo" />
          <p className="text-5xl font-bold text-fontGrey">{title}</p>
          {subtitle && (
            <p className="text-lg font-medium text-[#B2ABBF] mb-4">
              {subtitle}
            </p>
          )}

          {children}
        </div>

        <div className="pb-20 text-center flex flex-col items-center">
          <p className="text-sm">
            By submitting this form, I agree to the{' '}
            <span className="text-[#9330F7] cursor-pointer font-semibold">
              Terms & Conditions
            </span>
          </p>
          <div className="flex items-center gap-1">
            <CopyrightIcon />
            <p className="text-xs text-textGrey">
              {currentYear} Cashia Limited All Rights Reserved
            </p>
          </div>
        </div>
      </div>

      <div className="w-[60%] pt-6">
        <div className="h-[95%] w-[95%] flex flex-col bg-faintGrey pt-14 items-center text-center rounded-[24px]">
          <p className="text-4xl font-semibold w-[624px] mb-1">
            Manage Accounts, Monitor Activity, and Streamline Operations
          </p>
          <p className="text-sm text-textGrey font-medium px-10 w-[624px]">
            Seamlessly oversee accounts, track activities in real-time, and
            enhance operational efficiency with streamlined workflows and
            actionable insights.
          </p>
          <div className="mt-14 overflow-hidden rounded-br-[24px]">
            <img
              src={DashboardView}
              className="w-[1050px] h-[695px]"
              alt="Dashboard Preview"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
