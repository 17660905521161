import {
  Modal,
  ModalContent,
  ModalDescription,
  ModalTitle,
} from '@cashiaApp/web-components';
import React, {useMemo, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {ReactComponent as GreyExport} from '../../assets/icons/greyExport.svg';
import CustomSpinner from '../../components/common/CustomSpinner';
import ImgWithText from '../../components/common/ImgWithText';
import Navbar from '../../components/common/Navbar';
import {StatusBadge} from '../../components/common/StatusBadge';
import StatusDot from '../../components/common/StatusDot';
import {useGetOrderQuery, OrderPaymentMode, OrderState} from '../../generated';
import {groupDigits} from '../../utils/helper/validation';
import capitalizeFirstLetter from '../../utils/reusablefunctions/capitalizeFirstLetter';
import formatDate from '../../utils/reusablefunctions/formatDate';
import formatMoney from '../../utils/reusablefunctions/formatMoney';
import formatPaymentMode from '../../utils/reusablefunctions/formatPaymentMode';
import getPaymentIcon from '../../utils/reusablefunctions/getPaymentIcon';
import shortenTxt from '../../utils/reusablefunctions/shortenText';

const PaymentDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const {id, reference} = useParams<{id?: string; reference?: string}>();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const breadcrumbs = useMemo(() => {
    const params = searchParams.toString();
    return [
      {
        name: 'Payments',
        location: `/payments${params ? `?${params}` : ''}`,
      },
      {
        name: 'Payment Details',
      },
    ];
  }, [searchParams]);

  const {data, loading} = useGetOrderQuery({
    variables: {
      id: id || '',
      reference: reference || '',
    },
  });

  const paymentValues = useMemo(() => {
    const isFailed = data?.order?.state === OrderState?.Failed;

    return [
      {title: 'Transaction ID', value: `#${data?.order?.reference || ''}`},
      {
        title: 'Payment Status',
        value: (
          <StatusBadge
            className="rounded-md w-[79px] h-[28px]"
            status={data?.order?.state || ''}
          />
        ),
      },

      ...(isFailed
        ? [
            {
              title: 'Additional Information',
              value: (
                <ImgWithText
                  subText={<GreyExport onClick={() => setShowModal(true)} />}
                  text={
                    <span className="font-semibold text-base text-fontGrey">
                      {shortenTxt(String(data?.order?.message || ''))}
                    </span>
                  }
                />
              ),
            },
          ]
        : []),
      {
        title: 'Business Name',
        value: (
          <ImgWithText
            subText={
              <GreyExport
                className="w-4 h-4"
                onClick={() =>
                  navigate(
                    `/business-profiles/details/${data?.order?.paymentLink?.business?.id}`
                  )
                }
              />
            }
            text={
              <span className="font-semibold text-base text-fontGrey">
                {data?.order?.paymentLink?.business?.name || 'N/A'}
              </span>
            }
          />
        ),
      },
      {
        title: 'Payment Method',
        value: (
          <ImgWithText
            text={
              <span>
                {getPaymentIcon(
                  data?.order?.paymentMode || '',
                  data?.order?.card?.issuer
                )}
              </span>
            }
            subText={
              <span className="font-semibold text-base text-fontGrey">
                {formatPaymentMode(data?.order?.paymentMode || 'Unknown')}
              </span>
            }
          />
        ),
      },
      {
        title:
          data?.order?.paymentMode === OrderPaymentMode?.Card
            ? 'Card Number'
            : 'Phone Number',
        value:
          data?.order?.paymentMode === OrderPaymentMode?.Card
            ? data?.order?.card?.number || ''
            : `${data?.order?.phoneNumber?.countryCode}
                ${groupDigits(data?.order?.phoneNumber?.number || '')}`,
      },
      {
        title: 'Payment Date',
        value: formatDate(new Date(data?.order?.createdAt as string), {
          withTime: true,
          withNumericDate: true,
          with24Hour: true,
          withSeconds: true,
        }),
      },
    ];
  }, [data, navigate]);

  return (
    <div>
      <Navbar title="Payment Details" omitSearch breadcrumbs={breadcrumbs} />

      {loading ? (
        <CustomSpinner />
      ) : (
        <div className="h-[calc(100vh-64px)] overflow-y-auto px-2 py-6 bg-displayGrey ">
          <div className="mx-6 border border-dividerGrey p-8 rounded-lg h-[116px] flex items-center">
            <div>
              <h1 className="text-foggy font-semibold text-xs">
                Transaction ID
              </h1>
              <h1 className="text-fontGrey font-semibold text-2xl">
                #{data?.order?.reference || ''}
              </h1>
            </div>

            <div className="w-3/5 flex flex-row justify-evenly">
              <div>
                <h1 className="text-foggy font-semibold text-xs">
                  Payment Status
                </h1>

                <div className="flex items-center gap-2">
                  {data?.order?.state && (
                    <StatusDot status={data?.order?.state} />
                  )}
                  <span className="text-fontGrey text-base font-semibold">
                    {capitalizeFirstLetter(
                      String(data?.order?.state || '').toLowerCase()
                    )}
                  </span>
                </div>
              </div>
              <div>
                <h1 className="text-foggy font-semibold text-xs">
                  Payment Date
                </h1>
                <h1 className="font-semibold text-base">
                  {formatDate(new Date(data?.order?.createdAt as string), {
                    withTime: true,
                    withNumericDate: true,
                    with24Hour: true,
                    withSeconds: true,
                  })}
                </h1>
              </div>
            </div>
          </div>

          <div className="m-6 border border-dividerGrey p-8  rounded-lg h-auto">
            <div className="mb-6 flex justify-between">
              <h1 className="font-semibold text-2xl whitespace-nowrap">
                Payment Details
              </h1>
            </div>
            <div className="flex flex-col gap-5">
              {paymentValues.map((values) => (
                <div
                  key={values.title}
                  className="flex items-center justify-between">
                  <h1 className="text-greyscale font-medium text-base">
                    {values?.title}
                  </h1>
                  <h1 className="font-semibold text-base text-fontGrey">
                    {values?.value || ''}
                  </h1>
                </div>
              ))}
              <div className="flex items-center pt-5 border-t border-surfaceGrey justify-between">
                <h1 className="text-greyscale font-medium text-base">
                  Merchant Fees
                </h1>
                <h1 className="font-semibold text-base text-fontGrey">
                  KES 100
                </h1>
              </div>
              <div className="flex items-center pt-3 border-t border-surfaceGrey justify-between">
                <h1 className="text-fontGrey font-semibold text-lg">Amount</h1>
                <h1 className="font-semibold text-2xl text-fontGrey">
                  {data?.order?.cost?.currencyCode}{' '}
                  {data?.order?.cost &&
                    formatMoney(data?.order?.cost?.amountInCents)}
                </h1>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal onOpenChange={setShowModal} open={showModal}>
        <ModalContent className="w-[33%] pb-10 p-10 border-lightGrey">
          <ModalTitle>
            <div className="flex flex-col">
              <p className="font-semibold text-2xl text-fontGrey">
                Additional Information
              </p>
            </div>
          </ModalTitle>
          <ModalDescription>
            <div className="bg-displayGrey rounded-md h-[90px] p-4">
              {data?.order?.message}
            </div>
          </ModalDescription>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default PaymentDetails;
