import React from 'react';

interface Props {
  text: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<Props> = ({text, children}) => {
  return (
    <div className="relative flex items-center">
      <div className="relative peer">{children}</div>
      <div className="absolute left-full ml-1 w-max max-w-60 z-0 opacity-0 peer-hover:z-50 peer-hover:opacity-100 transition-opacity bg-[#FAF7FF] text-[#851EE3] text-xs font-semibold px-1 py-2 top-0 rounded-md shadow-md pointer-events-none">
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
