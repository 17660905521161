import {Input} from '@cashiaApp/web-components';
import {LoadScriptNext} from '@react-google-maps/api';
import React, {useState, useRef, useEffect} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

declare global {
  interface Window {
    Cypress?: object;
  }
}

const libraries: 'places'[] = ['places'];

interface Prediction {
  description: string;
  place_id: string;
  terms: {value: string}[];
}

type Props = {
  onSelect?: (x: string) => void;
  defaultValue?: string;
  onBlur?: (isValid: boolean) => void;
  error?: string;
};

const CustomAutocomplete: React.FC<Props> = ({
  onSelect,
  defaultValue,
  onBlur,
  error,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [lastValidAddress, setLastValidAddress] = useState<string>('');
  const [isCypressEnv, setIsCypressEnv] = useState<boolean>(false);
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(
    null
  );

  useEffect(() => {
    setIsCypressEnv(window.Cypress !== undefined);
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
      setLastValidAddress(defaultValue);
    } else {
      setInputValue('');
      setLastValidAddress('');
    }
  }, [defaultValue]);

  const ref = useOnclickOutside(() => {
    setPredictions([]);
  });

  const onLoad = () => {
    if (!autocompleteServiceRef.current && window.google) {
      autocompleteServiceRef.current =
        new google.maps.places.AutocompleteService();
    }
    if (!placesServiceRef.current && window.google) {
      const placesServiceDiv = document.createElement('div');
      placesServiceRef.current = new google.maps.places.PlacesService(
        placesServiceDiv
      );
    }
  };

  const validateAddress = async (value: string): Promise<boolean> => {
    if (!value) return false;
    if (value === lastValidAddress) return true;
    if (isCypressEnv) {
      return true;
    }

    return new Promise((resolve) => {
      if (!autocompleteServiceRef.current) {
        resolve(false);
        return;
      }

      autocompleteServiceRef.current.getPlacePredictions(
        {
          input: value,
          componentRestrictions: {country: 'KE'},
        },
        (predictionsList) => {
          if (predictionsList && predictionsList.length > 0) {
            const exactMatch = predictionsList.some(
              (pred) => pred.description.toLowerCase() === value.toLowerCase()
            );
            resolve(exactMatch);
          } else {
            resolve(false);
          }
        }
      );
    });
  };

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setInputValue(value);
    if (isCypressEnv) {
      setLastValidAddress(value);
      onSelect?.(value);
      return;
    }

    if (!value) {
      onSelect?.('');
      setPredictions([]);
      return;
    }

    if (!autocompleteServiceRef.current && window.google) {
      autocompleteServiceRef.current =
        new google.maps.places.AutocompleteService();
    }

    if (value.length > 0 && autocompleteServiceRef.current) {
      await autocompleteServiceRef.current.getPlacePredictions(
        {
          input: value,
          componentRestrictions: {country: 'KE'},
        },
        (predictionsList) => {
          if (predictionsList) {
            if (!isCypressEnv) {
              setPredictions(predictionsList as Prediction[]);
            }
          } else {
            setPredictions([]);
          }
        }
      );
    } else {
      setPredictions([]);
    }
  };

  const handleBlur = async () => {
    if (isCypressEnv) {
      setLastValidAddress(inputValue);
      onBlur?.(true);
      return;
    }

    const isValid = await validateAddress(inputValue);
    if (!isValid && inputValue) {
      setInputValue('');
      onSelect?.('');
      onBlur?.(false);
    } else {
      setLastValidAddress(inputValue);
      onBlur?.(true);
    }
  };

  const fetchPlaceDetails = (
    placeId: string
  ): Promise<google.maps.places.PlaceResult> =>
    new Promise((resolve, reject) => {
      if (placesServiceRef.current) {
        placesServiceRef.current.getDetails(
          {placeId},
          (placeDetails, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              resolve(placeDetails as google.maps.places.PlaceResult);
            } else {
              reject(new Error('Failed to fetch place details'));
            }
          }
        );
      } else {
        reject(new Error('PlacesService is not initialized'));
      }
    });

  const handleSelectPrediction = async (placeId: string) => {
    setPredictions([]);

    try {
      const placeDetails = await fetchPlaceDetails(placeId);
      const formattedAddress = `${placeDetails?.name || ''}, ${placeDetails?.vicinity || ''}`;
      setInputValue(formattedAddress);
      setLastValidAddress(formattedAddress);
      onSelect?.(formattedAddress);
    } catch (uploadErr) {
      console.error('Error fetching place details:', uploadErr);
    }
  };

  return (
    <LoadScriptNext
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
      libraries={libraries}
      onLoad={onLoad}
      loadingElement={
        <div className="max-md:w-[88vw] w-[497px] bg-transparent border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-smoothRed" />
      }>
      <div className="relative max-md:w-[88vw] max-w-[480px]">
        <Input
          className={`max-md:w-[86vw] w-full text-[14px] text-textGrey font-[500] bg-transparent h-[46px] focus:outline-none focus:ring-0 ${
            error
              ? 'border-smoothRed border-[1px] bg-pinkRed'
              : 'border-dividerGrey focus:border-surfacePurple'
          }`}
          value={inputValue}
          name="location"
          onChange={handleInputChange}
          onBlur={handleBlur}
          data-cy="location-input"
        />
        {predictions.length > 0 && !isCypressEnv && (
          <div
            ref={ref}
            className="shadow-lg cursor-pointer max-md:w-[88vw] w-full h-[327px] bg-white absolute top-[100%] left-0 right-0 z-[1000] rounded-[10px]">
            <ul>
              {predictions.map((prediction) => (
                <li
                  key={prediction.place_id}
                  id="location-suggestion"
                  onClick={() => handleSelectPrediction(prediction.place_id)}
                  className="bg-white px-4 py-2 border-b-[1px] border-dividerGrey">
                  <strong className="font-[500] text-[14px]">
                    {prediction?.terms?.[0]?.value}
                  </strong>
                  <br />
                  <small className="font-[400] text-[12px] leading-[12px] text-foggy">
                    {prediction.description}
                  </small>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </LoadScriptNext>
  );
};

export default CustomAutocomplete;
