import {ReactComponent as ValidationPointIcon} from '../../assets/icons/validation-point.svg';
import {ReactComponent as ValidationSuccessIcon} from '../../assets/icons/validation-success.svg';

interface ValidationIconProps {
  condition: boolean;
  className?: string;
}

const ValidationIcon = ({condition, className}: ValidationIconProps) => {
  return condition ? (
    <ValidationSuccessIcon className={className} />
  ) : (
    <ValidationPointIcon className={className} />
  );
};

export default ValidationIcon;
