const formatPaymentMode = (mode: string) => {
  switch (mode) {
    case 'MPESA':
      return 'M-Pesa';
    case 'CARD':
      return 'Card';
    case 'BANK_TRANSFER':
      return 'Bank Transfer';
    default:
      return mode;
  }
};

export default formatPaymentMode;
