import {Input} from '@cashiaApp/web-components';
import React, {ReactNode} from 'react';

import {cn} from '../../utils/reusablefunctions';

type InputProps = {
  label?: string;
  name?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  value?: string;
  moreInfo?: string;
  error?: JSX.Element | null;
  required?: boolean;
  rightElement?: ReactNode;
  leftElement?: ReactNode;
  labelIcon?: ReactNode;
  placeholder?: string;
  width?: string;
  type?: string;
  min?: string;
  max?: string;
  className?: string;
  maxLength?: number;
  onBlur?: () => void;
};

export default function FormInput({
  error,
  label,
  onChange,
  disabled,
  value,
  moreInfo,
  required,
  name,
  placeholder,
  rightElement,
  leftElement,
  type,
  min,
  max,
  maxLength,
  labelIcon,
  onBlur,
  className = '',
  width = '90%',
}: InputProps) {
  return (
    <div className="mb-6 max-md:mb-0">
      <p className="flex sm:font-medium md:font-medium  sm:text-[15px] md:text-[16px] font-[500] mb-2 gap-1 items-center leading-[16px] text-grey text-lg flex-wrap">
        {label} {labelIcon} {required ? '*' : ''}{' '}
      </p>
      {moreInfo && (
        <p className="text-[12px] font-[400] text-foggy">{moreInfo}</p>
      )}
      <Input
        onChange={(e) => onChange?.(e.target.value)}
        name={name || label}
        value={value}
        placeholder={placeholder}
        type={type}
        min={min}
        disabled={disabled}
        max={max}
        maxLength={maxLength}
        onBlur={onBlur}
        className={cn(
          `pl-2 max-md:w-[92vw] text-base font-medium w-[${width}] bg-offWhite border-dividerGrey h-[48px] focus:outline-none focus:ring-0  ${className}`,
          {
            'bg-faintGrey disabled:opacity-100 text-[14px] text-grey font-[500] border border-surfaceGrey ':
              disabled,

            'bg-white focus:border-mediumPurple focus:text-surfacePurple ':
              !disabled,
            'pl-[55px] mb-[-25px]': leftElement,
            'mb-[-34px]': rightElement,
            'border-red-400 border-[1px] bg-pinkRed': (
              error?.props as {error: string}
            )?.error,
          }
        )}
      />
      {leftElement && (
        <div className="relative top-[-11px] left-3 w-[32px]">
          {leftElement}
        </div>
      )}
      {rightElement && (
        <div className="relative top-[-8px] cursor-pointer left-[90%] w-[50px]">
          {rightElement}
        </div>
      )}
      <div>{error ? error : ''}</div>
    </div>
  );
}
