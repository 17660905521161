import {
  Modal,
  ModalContent,
  ModalDescription,
  ModalTitle,
} from '@cashiaApp/web-components';
import React, {useMemo, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {ReactComponent as GreyExport} from '../../assets/icons/greyExport.svg';
import CustomSpinner from '../../components/common/CustomSpinner';
import ImgWithText from '../../components/common/ImgWithText';
import Navbar from '../../components/common/Navbar';
import StatusBadge from '../../components/common/StatusBadge';
import StatusDot from '../../components/common/StatusDot';
import {
  OrderPaymentMode,
  RefundStatus,
  useGetRefundQuery,
} from '../../generated';
import {mapRefundStatus} from './utils/statusMap';
import {groupDigits} from '../../utils/helper/validation';
import formatDate from '../../utils/reusablefunctions/formatDate';
import formatMoney from '../../utils/reusablefunctions/formatMoney';
import formatPaymentMode from '../../utils/reusablefunctions/formatPaymentMode';
import getPaymentIcon from '../../utils/reusablefunctions/getPaymentIcon';
import shortenTxt from '../../utils/reusablefunctions/shortenText';

const RefundDetails = () => {
  const {id} = useParams<{id: string}>();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');

  const handleIconClick = (content: string, title: string) => {
    setModalTitle(title);
    setModalContent(content);
    setShowModal(true);
  };

  const [searchParams] = useSearchParams();

  const breadcrumbs = useMemo(() => {
    const params = searchParams.toString();
    return [
      {
        name: 'Refunds',
        location: `/refunds${params ? `?${params}` : ''}`,
      },
      {
        name: ' Refund Details',
      },
    ];
  }, [searchParams]);

  const {data, loading} = useGetRefundQuery({
    variables: {
      id: id || '',
    },
    skip: !id,
  });

  const refundValues = useMemo(() => {
    const isFailed = data?.refund?.status === RefundStatus.Cancelled;

    return [
      {title: 'Transaction ID', value: `#${data?.refund?.reference || ''}`},
      {
        title: 'Refund Status',
        value: (
          <StatusBadge
            className="rounded-md w-[92px] h-[28px]"
            status={mapRefundStatus(data?.refund?.status)}
          />
        ),
      },

      ...(isFailed
        ? [
            {
              title: 'Additional Information',
              value: (
                <ImgWithText
                  subText={
                    <GreyExport
                      className="w-5 h-5"
                      onClick={() =>
                        handleIconClick(
                          data?.refund?.order?.message || '',
                          'Additional Information'
                        )
                      }
                    />
                  }
                  text={
                    <span className="font-semibold text-base text-fontGrey">
                      {shortenTxt(String(data?.refund?.order?.message || ''))}
                    </span>
                  }
                />
              ),
            },
          ]
        : []),

      {
        title: 'Reason for Refund',
        value: (
          <ImgWithText
            subText={
              <GreyExport
                className="w-5 h-5 cursor-pointer"
                onClick={() =>
                  handleIconClick(
                    data?.refund?.reason || '',
                    'Reason for Refund '
                  )
                }
              />
            }
            text={
              <span className="font-semibold text-base text-fontGrey">
                {shortenTxt(String(data?.refund?.reason || ''))}
              </span>
            }
          />
        ),
      },
      {
        title: 'Refund Type',
        value: (
          <ImgWithText
            text={
              <span>
                {getPaymentIcon(
                  data?.refund?.order?.paymentMode || '',
                  data?.refund?.order?.card?.issuer
                )}
              </span>
            }
            subText={
              <span className="font-semibold text-base text-fontGrey">
                {formatPaymentMode(data?.refund?.order?.paymentMode || '')}
              </span>
            }
          />
        ),
      },
      {
        title:
          data?.refund?.order?.paymentMode === OrderPaymentMode?.Card
            ? 'Card Number'
            : 'Phone Number',
        value:
          data?.refund?.order?.paymentMode === OrderPaymentMode?.Card
            ? data?.refund?.order?.card?.number || ''
            : `${data?.refund?.order?.paymentLink?.business?.phoneNumber?.countryCode}
                ${groupDigits(data?.refund?.order?.paymentLink?.business?.phoneNumber?.number || '')}
                `,
      },
      {
        title: 'Created Date',
        value: formatDate(new Date(data?.refund?.createdAt as string), {
          withTime: true,
          withNumericDate: true,
          with24Hour: true,
          withSeconds: true,
        }),
      },
      {
        title: 'Refunded Date',
        value: formatDate(new Date(), {
          withTime: true,
          withNumericDate: true,
          with24Hour: true,
          withSeconds: true,
        }),
      },
    ];
  }, [data]);

  const businessinfo = useMemo(() => {
    return [
      {
        title: 'Business Name',
        value: (
          <ImgWithText
            subText={
              <GreyExport
                className="w-5 h-5 cursor-pointer"
                onClick={() =>
                  navigate(
                    `/business-profiles/details/${data?.refund?.order?.paymentLink?.business?.id}`
                  )
                }
              />
            }
            text={
              <span className="font-semibold text-base text-fontGrey">
                {data?.refund?.order?.paymentLink?.business?.name}
              </span>
            }
          />
        ),
      },

      {
        title: 'Original Payment',
        value: `#${data?.refund?.order?.reference || ''}`,
      },
    ];
  }, [data, navigate]);

  const accountInfo = useMemo(() => {
    return [
      {
        title: 'Full Name ',
        value: `${data?.refund?.order?.paymentLink?.business?.members?.[0]?.firstName} ${data?.refund?.order?.paymentLink?.business?.members?.[0]?.lastName}`,
      },
      {
        title: 'Email',
        value: data?.refund?.order?.paymentLink?.business?.members?.[0]?.email,
      },
      {
        title: 'Phone Number',
        value: `${data?.refund?.order?.paymentLink?.business?.phoneNumber?.countryCode}  ${groupDigits(data?.refund?.order?.paymentLink?.business?.phoneNumber?.number || '')}`,
      },
    ];
  }, [data]);

  return (
    <div>
      <Navbar title="Refund Details" omitSearch breadcrumbs={breadcrumbs} />

      {loading ? (
        <CustomSpinner />
      ) : (
        <div className="h-[calc(100vh-64px)] overflow-y-auto px-2 py-6 bg-displayGrey ">
          <div className="mx-6 border border-dividerGrey p-8 rounded-lg h-[116px] flex gap-48 items-center">
            <div>
              <h1 className="text-foggy font-semibold text-xs">
                Transaction ID
              </h1>
              <h1 className="text-fontGrey font-semibold text-2xl">
                #{data?.refund?.reference || ''}
              </h1>
            </div>

            <div className=" gap-12 flex flex-row justify-end">
              <div>
                <div className="flex items-center gap-2">
                  {data?.refund?.status && (
                    <StatusDot status={data?.refund?.status} />
                  )}
                  <div>
                    <h1 className="text-foggy font-semibold text-xs">
                      Refund Status
                    </h1>
                    <h1 className="font-semibold text-base">
                      {mapRefundStatus(data?.refund?.status)}
                    </h1>
                  </div>
                </div>
              </div>
              <div>
                <h1 className="text-foggy font-semibold text-xs">
                  Refund Amount
                </h1>
                <h1 className="font-semibold text-base">
                  {data?.refund?.amount?.currencyCode}{' '}
                  {formatMoney(data?.refund?.amount?.amountInCents || 0)}
                </h1>
              </div>
              <div>
                <h1 className="text-foggy font-semibold text-xs">
                  Business Name
                </h1>
                <h1 className="font-semibold text-base">
                  {data?.refund?.order?.paymentLink?.business?.name}
                </h1>
              </div>
              <div>
                <h1 className="text-foggy font-semibold text-xs">
                  Created Date
                </h1>
                <h1 className="font-semibold text-base">
                  {formatDate(new Date(data?.refund?.createdAt as string), {
                    withTime: true,
                    withNumericDate: true,
                    with24Hour: true,
                    withSeconds: true,
                  })}
                </h1>
              </div>
            </div>
          </div>

          <div className="m-6 border border-dividerGrey p-8  rounded-lg h-auto">
            <div className="mb-6 flex justify-between">
              <h1 className="font-semibold text-2xl whitespace-nowrap">
                Refund Details
              </h1>
            </div>
            <div className="flex flex-col gap-5">
              {refundValues.map((values) => (
                <div
                  key={values.title}
                  className="flex items-center justify-between">
                  <h1 className="text-greyscale font-medium text-base">
                    {values?.title}
                  </h1>
                  <h1 className="font-semibold text-base text-fontGrey">
                    {values?.value || ''}
                  </h1>
                </div>
              ))}

              <div className="flex items-center pt-3 border-t border-surfaceGrey justify-between">
                <h1 className="text-fontGrey font-semibold text-lg">
                  Refund Amount
                </h1>
                <h1 className="font-semibold text-2xl text-fontGrey">
                  {`${data?.refund.amount.currencyCode} ` +
                    formatMoney(data?.refund?.amount.amountInCents || 0)}
                </h1>
              </div>
            </div>
          </div>

          <div className="m-6 border border-dividerGrey p-8  rounded-lg h-auto">
            <div className="mb-6 flex justify-between">
              <h1 className="font-semibold text-2xl whitespace-nowrap">
                Business Information
              </h1>
            </div>
            <div className="flex flex-col gap-5">
              {businessinfo.map((values) => (
                <div
                  key={values.title}
                  className="flex items-center justify-between">
                  <h1 className="text-greyscale font-medium text-base">
                    {values?.title}
                  </h1>
                  <h1 className="font-semibold text-base text-fontGrey">
                    {values?.value || ''}
                  </h1>
                </div>
              ))}

              <div className="flex flex-col pt-3 border-t gap-3 border-surfaceGrey justify-between">
                <h1 className="text-fontGrey font-semibold text-lg">
                  Account Information
                </h1>
                {accountInfo.map((values) => (
                  <div
                    key={values.title}
                    className="flex items-center justify-between">
                    <h1 className="text-greyscale font-medium text-base">
                      {values?.title}
                    </h1>
                    <h1 className="font-semibold text-base text-fontGrey">
                      {values?.value || ''}
                    </h1>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal onOpenChange={setShowModal} open={showModal}>
        <ModalContent className="w-[33%] pb-10 p-10 border-lightGrey">
          <ModalTitle>
            <div className="flex flex-col">
              <p className="font-semibold text-2xl text-fontGrey">
                {modalTitle}
              </p>
            </div>
          </ModalTitle>
          <ModalDescription>
            <div className="bg-displayGrey rounded-md h-[90px] p-4">
              {modalContent}
            </div>
          </ModalDescription>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default RefundDetails;
