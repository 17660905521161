import React, {useState} from 'react';

import Tooltip from './Tooltip';
import {ReactComponent as InfoCircleActive} from '../../../../assets/icons/info-circle-active.svg';
import {ReactComponent as InfoCircle} from '../../../../assets/icons/info-circle-filled.svg';

interface Props {
  title: string;
  tooltip: string;
  icon: React.ReactNode;
  currencyCode: string;
  balance: number;
}

const BalanceSmall: React.FC<Props> = ({
  title,
  tooltip,
  icon,
  currencyCode,
  balance,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div className="px-7 py-4 flex flex-col items-center justify-center border-2 border-[#F2F1F6] rounded-lg">
      <div className="mb-4">{icon}</div>
      <div className="flex flex-row gap-2">
        <div className="text-xs font-medium text-[#7F738E]">{title}</div>
        <Tooltip text={tooltip}>
          <div
            className="relative cursor-pointer"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}>
            {isHovering ? <InfoCircleActive /> : <InfoCircle />}
          </div>
        </Tooltip>
      </div>
      <div className="flex flex-row font-semibold text-lg">
        {currencyCode}{' '}
        {balance.toLocaleString('en-KE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || '0.00'}
      </div>
    </div>
  );
};

export default BalanceSmall;
