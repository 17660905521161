import {Button} from '@cashiaApp/web-components';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {mapRefundStatus} from './utils/statusMap';
import {ReactComponent as Plus} from '../../assets/icons/add-square-grey.svg';
import {ReactComponent as ChevronDown} from '../../assets/icons/arrow-swap.svg';
import {ReactComponent as Close} from '../../assets/icons/close-square.svg';
import {ReactComponent as Emoji} from '../../assets/icons/happy_emoji.svg';
import CustomSpinner from '../../components/common/CustomSpinner';
import EmptyState from '../../components/common/EmptyState';
import FilterButton from '../../components/common/FilterButton';
import FormInput from '../../components/common/FormInput';
import Navbar from '../../components/common/Navbar';
import Paginator, {PAGE_SIZE} from '../../components/common/Paginator';
import CommonTable from '../../components/common/table';
import useQueryParamState, {
  DateFilterObj,
} from '../../components/common/useQueryParamState';
import {
  AmountComparisonOperator,
  CurrencyCode,
  RefundStatus,
  useGetRefundsQuery,
} from '../../generated';
import {cn} from '../../utils/reusablefunctions';
import formatDate from '../../utils/reusablefunctions/formatDate';
import formatMoney from '../../utils/reusablefunctions/formatMoney';
import {TableColumnType, TableDataType} from '../../utils/types/table.types';

const columns: TableColumnType[] = [
  'transaction_id',
  'business_name',
  'refund_amount',
  'original_payment',
  'created_date',
  'refund_status',
];

const refundStatusOptions = {
  [RefundStatus.Accepted]: mapRefundStatus(RefundStatus.Accepted),
  [RefundStatus.InReview]: mapRefundStatus(RefundStatus.InReview),
  [RefundStatus.Cancelled]: mapRefundStatus(RefundStatus.Cancelled),
};

interface AmountFilterState {
  operator: AmountComparisonOperator;
  amount?: {
    amountInCents: number;
    currencyCode: CurrencyCode;
  };
  betweenAmount?: {
    minAmount: {
      amountInCents: number;
      currencyCode: CurrencyCode;
    };
    maxAmount: {
      amountInCents: number;
      currencyCode: CurrencyCode;
    };
  };
}

interface AmountFilterProps {
  onApply: (filter: AmountFilterState | null) => void;
  initialAmountValue?: string;
  initialMaxAmountValue?: string;
  initialOperator?: AmountComparisonOperator;
  className?: string;
}

interface FilterState {
  open: boolean;
  dropdownOpen: boolean;
  operator: AmountComparisonOperator;
  amount: string;
  maxAmount: string;
  activeFilter: string;
}

type FilterAction =
  | {type: 'SET_OPEN'; payload: boolean}
  | {type: 'SET_DROPDOWN_OPEN'; payload: boolean}
  | {type: 'SELECT_OPERATOR'; payload: AmountComparisonOperator}
  | {type: 'SET_AMOUNT'; payload: string}
  | {type: 'SET_MAX_AMOUNT'; payload: string}
  | {type: 'SET_ACTIVE_FILTER'; payload: string}
  | {type: 'CLEAR_FILTER'};

function filterReducer(state: FilterState, action: FilterAction): FilterState {
  switch (action.type) {
    case 'SET_OPEN':
      return {...state, open: action.payload};
    case 'SET_DROPDOWN_OPEN':
      return {...state, dropdownOpen: action.payload};
    case 'SELECT_OPERATOR':
      return {...state, operator: action.payload, dropdownOpen: false};
    case 'SET_AMOUNT':
      return {...state, amount: action.payload};
    case 'SET_MAX_AMOUNT':
      return {...state, maxAmount: action.payload};
    case 'SET_ACTIVE_FILTER':
      return {...state, activeFilter: action.payload};
    case 'CLEAR_FILTER':
      return {...state, activeFilter: '', amount: '', maxAmount: ''};
    default:
      return state;
  }
}

interface OperatorDropdownProps {
  operator: AmountComparisonOperator;
  isOpen: boolean;
  onToggle: () => void;
  onSelect: (operator: AmountComparisonOperator) => void;
  getOperatorText: (op: AmountComparisonOperator) => string;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

function isValidAmountOperator(
  value: unknown
): value is AmountComparisonOperator {
  return (
    typeof value === 'string' &&
    Object.values(AmountComparisonOperator).includes(
      value as AmountComparisonOperator
    )
  );
}

function safeParseFloat(
  value: string | undefined,
  defaultValue: number = 0
): number {
  if (!value || value.trim() === '') return defaultValue;
  const parsed = parseFloat(value);
  return isNaN(parsed) ? defaultValue : parsed;
}

const OperatorDropdown: React.FC<OperatorDropdownProps> = ({
  operator,
  isOpen,
  onToggle,
  onSelect,
  getOperatorText,
  dropdownRef,
}) => {
  return (
    <div className="mb-4 relative" ref={dropdownRef}>
      <div
        className="w-full p-3 border border-borderColor rounded-lg flex justify-between items-center cursor-pointer"
        onClick={onToggle}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={isOpen}>
        <span className="text-grey">{getOperatorText(operator)}</span>
        <ChevronDown
          className={`w-5 h-5 text-grey transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
          aria-hidden="true"
        />
      </div>

      {isOpen && (
        <div
          className="absolute top-full left-0 right-0 bg-white border border-borderColor rounded-lg mt-1 shadow-lg z-20"
          role="listbox">
          {Object.values(AmountComparisonOperator)
            .filter((op) => op !== operator)
            .map((op) => (
              <div
                key={op}
                className="p-3 text-grey hover:bg-lightPurple hover:text-textPurple cursor-pointer"
                onClick={() => onSelect(op)}
                role="option">
                {getOperatorText(op)}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const AmountFilter: React.FC<AmountFilterProps> = ({
  onApply,
  initialAmountValue = '',
  initialMaxAmountValue = '',
  initialOperator = AmountComparisonOperator.Between,
  className = '',
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const getOperatorText = (op: AmountComparisonOperator): string => {
    switch (op) {
      case AmountComparisonOperator.EqualTo:
        return 'is equal to';
      case AmountComparisonOperator.LessThan:
        return 'is less than';
      case AmountComparisonOperator.GreaterThan:
        return 'is greater than';
      case AmountComparisonOperator.Between:
        return 'is between';
      default:
        return 'is between';
    }
  };

  const getInitialActiveFilter = useCallback(() => {
    if (initialOperator && initialAmountValue) {
      if (
        initialOperator === AmountComparisonOperator.Between &&
        initialMaxAmountValue
      ) {
        return `is between ${initialAmountValue} - ${initialMaxAmountValue}`;
      } else {
        return `${getOperatorText(initialOperator)} ${initialAmountValue}`;
      }
    }
    return '';
  }, [initialOperator, initialAmountValue, initialMaxAmountValue]);

  const [state, dispatch] = useReducer(filterReducer, {
    open: false,
    dropdownOpen: false,
    operator: initialOperator,
    amount: initialAmountValue,
    maxAmount: initialMaxAmountValue,
    activeFilter: getInitialActiveFilter(),
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        dispatch({type: 'SET_OPEN', payload: false});
      }
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        state.dropdownOpen
      ) {
        dispatch({type: 'SET_DROPDOWN_OPEN', payload: false});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, dropdownRef, state.dropdownOpen]);

  const clearFilter = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch({type: 'CLEAR_FILTER'});
    onApply(null);
  };

  const handleOperatorSelect = (op: AmountComparisonOperator) => {
    dispatch({type: 'SELECT_OPERATOR', payload: op});
  };

  const handleApply = () => {
    if (state.operator === AmountComparisonOperator.Between) {
      if (state.amount && state.maxAmount) {
        const minAmount = safeParseFloat(state.amount);
        const maxAmount = safeParseFloat(state.maxAmount);

        if (isNaN(minAmount) || isNaN(maxAmount)) {
          console.warn('Invalid amount values:', {
            min: state.amount,
            max: state.maxAmount,
          });
          return;
        }

        const filterText = `is between ${state.amount} - ${state.maxAmount}`;
        dispatch({type: 'SET_ACTIVE_FILTER', payload: filterText});
        onApply({
          operator: state.operator,
          betweenAmount: {
            minAmount: {
              amountInCents: minAmount * 100,
              currencyCode: CurrencyCode.Kes,
            },
            maxAmount: {
              amountInCents: maxAmount * 100,
              currencyCode: CurrencyCode.Kes,
            },
          },
        });
      }
    } else if (state.amount) {
      const amount = safeParseFloat(state.amount);

      if (isNaN(amount)) {
        console.warn('Invalid amount value:', state.amount);
        return;
      }

      const operatorText = getOperatorText(state.operator);
      const filterText = `${operatorText} ${state.amount}`;
      dispatch({type: 'SET_ACTIVE_FILTER', payload: filterText});
      onApply({
        operator: state.operator,
        amount: {
          amountInCents: amount * 100,
          currencyCode: CurrencyCode.Kes,
        },
      });
    }
    dispatch({type: 'SET_OPEN', payload: false});
  };

  return (
    <div className={`${className}`} ref={ref}>
      <Button
        variant="outline"
        className={cn(
          `bg-faintGrey text-black gap-2 flex items-center font-semibold border-dividerGrey hover:shadow-sm hover:border-1
          hover:border-mediumPurple hover:bg-faintGrey`,
          {
            'bg-surfacePurple text-white font-semibold hover:bg-surfacePurple hover:text-white':
              !!state.activeFilter,
          }
        )}
        onClick={() => dispatch({type: 'SET_OPEN', payload: !state.open})}
        aria-expanded={state.open}
        aria-haspopup="dialog">
        {!state.activeFilter ? (
          <Plus className="w-5 h-5 text-gray-600" aria-hidden="true" />
        ) : null}
        {state.activeFilter ? state.activeFilter : 'Amount'}
        {state.activeFilter ? (
          <Close onClick={clearFilter} aria-label="Clear filter" />
        ) : null}
      </Button>

      {state.open && (
        <div className="bg-white shadow-lg absolute mt-2 z-10 rounded-lg p-5 w-[300px]">
          <h3 className="text-lg font-semibold mb-6 text-fontGrey">
            Filter by Amount
          </h3>

          <OperatorDropdown
            operator={state.operator}
            isOpen={state.dropdownOpen}
            onToggle={() =>
              dispatch({
                type: 'SET_DROPDOWN_OPEN',
                payload: !state.dropdownOpen,
              })
            }
            onSelect={handleOperatorSelect}
            getOperatorText={getOperatorText}
            dropdownRef={dropdownRef}
          />

          {state.operator === AmountComparisonOperator.Between ? (
            <div className="space-y">
              <FormInput
                type="number"
                placeholder="Enter Min Amount"
                value={state.amount}
                onChange={(value) =>
                  dispatch({type: 'SET_AMOUNT', payload: value})
                }
                className="rounded-lg text-grey text-[14px] font-[500] w-full placeholder:text-surfaceGrey"
                aria-label="Minimum amount"
              />
              <div className="text-center font-medium text-trueBlack">&</div>
              <FormInput
                type="number"
                placeholder="Enter Max Amount"
                value={state.maxAmount}
                onChange={(value) =>
                  dispatch({type: 'SET_MAX_AMOUNT', payload: value})
                }
                className="rounded-lg text-grey text-[14px] font-[500] w-full placeholder:text-surfaceGrey"
                aria-label="Maximum amount"
              />
            </div>
          ) : (
            <FormInput
              type="number"
              placeholder="Enter Amount"
              value={state.amount}
              onChange={(value) =>
                dispatch({type: 'SET_AMOUNT', payload: value})
              }
              className="rounded-lg text-grey text-[14px] font-[500] w-full placeholder:text-surfaceGrey"
              aria-label="Amount"
            />
          )}

          <div className="mt-6">
            <Button
              onClick={handleApply}
              className="w-full py-3 bg-purple-500 hover:bg-purple-600 text-white font-medium rounded-lg"
              disabled={
                !state.amount ||
                (state.operator === AmountComparisonOperator.Between &&
                  !state.maxAmount)
              }>
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const Refunds = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {getParamsState, updateQueryParams} = useQueryParamState();
  const paramsState = getParamsState();
  const [count, setCount] = useState<number>(paramsState.page);
  const [currentCursor, setCurrentCursor] = useState<string | null>(
    paramsState.cursor
  );
  const [cursorStack, setCursorStack] = useState<string[]>(
    paramsState.cursorStack || []
  );

  const [statusFilters, setStatusFilters] = useState<string[]>(
    paramsState.statusFilters || []
  );
  const [dateFilters, setDateFilters] = useState<DateFilterObj[]>(
    paramsState.dateFilters || []
  );
  const [amountFilter, setAmountFilter] = useState<AmountFilterState | null>(
    paramsState.amountFilter
      ? {
          operator: isValidAmountOperator(paramsState.amountFilter.operator)
            ? paramsState.amountFilter.operator
            : AmountComparisonOperator.Between,
          amount: paramsState.amountFilter.amount
            ? {
                amountInCents:
                  safeParseFloat(paramsState.amountFilter.amount) * 100,
                currencyCode: CurrencyCode.Kes,
              }
            : undefined,
          betweenAmount: paramsState.amountFilter.maxAmount
            ? {
                minAmount: {
                  amountInCents:
                    safeParseFloat(paramsState.amountFilter.amount, 0) * 100,
                  currencyCode: CurrencyCode.Kes,
                },
                maxAmount: {
                  amountInCents:
                    safeParseFloat(paramsState.amountFilter.maxAmount) * 100,
                  currencyCode: CurrencyCode.Kes,
                },
              }
            : undefined,
        }
      : null
  );

  useEffect(() => {
    const amountFilterForParams = amountFilter
      ? {
          operator: amountFilter.operator,
          amount: amountFilter.betweenAmount
            ? (
                amountFilter.betweenAmount.minAmount.amountInCents / 100
              ).toString()
            : amountFilter.amount
              ? (amountFilter.amount.amountInCents / 100).toString()
              : undefined,
          maxAmount: amountFilter.betweenAmount
            ? (
                amountFilter.betweenAmount.maxAmount.amountInCents / 100
              ).toString()
            : undefined,
        }
      : undefined;

    updateQueryParams({
      page: count,
      cursor: currentCursor,
      cursorStack,
      statusFilters,
      dateFilters,
      amountFilter: amountFilterForParams,
    });
  }, [
    count,
    currentCursor,
    cursorStack,
    statusFilters,
    dateFilters,
    amountFilter,
    updateQueryParams,
  ]);

  const getAmountFilterInput = useCallback(() => {
    if (!amountFilter) return undefined;

    if (
      amountFilter.operator === AmountComparisonOperator.Between &&
      amountFilter.betweenAmount
    ) {
      return {
        operator: amountFilter.operator,
        BetweenAmount: {
          minAmount: amountFilter.betweenAmount.minAmount,
          maxAmount: amountFilter.betweenAmount.maxAmount,
        },
      };
    }

    if (amountFilter.amount) {
      return {
        operator: amountFilter.operator,
        amount: amountFilter.amount,
      };
    }

    return undefined;
  }, [amountFilter]);

  const {data: refunds, loading: refundsLoading} = useGetRefundsQuery({
    variables: {
      input: {
        cursor: {
          first: PAGE_SIZE,
          after: currentCursor,
        },
        filter: {
          status: statusFilters.length
            ? statusFilters.map((status) => {
                switch (status) {
                  case 'Success':
                    return RefundStatus.Accepted;
                  case 'Pending':
                    return RefundStatus.InReview;
                  case 'Failed':
                    return RefundStatus.Cancelled;
                  default:
                    return RefundStatus.Accepted;
                }
              })
            : undefined,
          dateTime:
            dateFilters.length &&
            dateFilters[0]?.startDate &&
            dateFilters[0]?.endDate
              ? {
                  startDate: dateFilters[0].startDate,
                  endDate: dateFilters[0].endDate,
                }
              : undefined,
          amount: getAmountFilterInput(),
        },
      },
    },
  });

  const transformedData = useMemo(() => {
    if (!refunds?.refunds?.edges) return [];

    return refunds?.refunds?.edges?.map(({node}) => ({
      id: node?.id,
      transaction_id: `#${node?.reference || ''}`,
      business_name: node?.order?.paymentLink?.business?.name || '',
      refund_amount:
        node?.amount?.currencyCode +
        ' ' +
        formatMoney(node?.amount?.amountInCents),
      original_payment: `#${node?.order?.reference}`,
      created_date: node?.createdAt
        ? formatDate(new Date(node?.createdAt as string), {
            withTime: true,
            withNumericDate: true,
          })
        : '---',
      refund_status: [mapRefundStatus(node?.status)],
    })) as TableDataType[];
  }, [refunds]);

  const loadMore = useCallback(
    async (next: boolean) => {
      try {
        if (next && refunds?.refunds?.pageInfo?.hasNextPage) {
          const nextCursor = refunds?.refunds?.pageInfo?.endCursor;
          if (!nextCursor) {
            console.warn(
              'Next page cursor is missing even though hasNextPage is true'
            );
            return;
          }

          if (currentCursor) {
            setCursorStack((prev) => [...prev, currentCursor]);
          }
          setCurrentCursor(nextCursor);
          setCount((prev) => prev + 1);
        } else if (!next && count > 1) {
          if (cursorStack.length > 0) {
            const previousCursor = cursorStack[cursorStack.length - 1];
            if (previousCursor) {
              setCursorStack((prev) => prev.slice(0, -1));
              setCurrentCursor(previousCursor);
              setCount((prev) => prev - 1);
            }
          } else {
            setCurrentCursor(null);
            setCount(1);
          }
        }
      } catch (error) {
        console.error('Error loading more refunds:', error);
      }
    },
    [refunds, currentCursor, cursorStack, count]
  );

  const handleViewDetails = useCallback(
    (id: string) => {
      const currentParams = searchParams.toString();
      navigate(
        `/refunds/refund-details/${id}${currentParams ? `?${currentParams}` : ''}`
      );
    },
    [navigate, searchParams]
  );

  const statusFilterOptions = useMemo(
    () => Object.values(refundStatusOptions),
    []
  );

  const dateFilterInitialFilters = useMemo(() => {
    if (
      dateFilters?.length &&
      dateFilters[0]?.startDate &&
      dateFilters[0]?.endDate
    ) {
      return [
        `${formatDate(dateFilters[0].startDate, {
          withDate: true,
          withTime: false,
          withYear: true,
          withMonth: true,
          withNumericDate: true,
        })} - ${formatDate(dateFilters[0].endDate, {
          withDate: true,
          withTime: false,
          withYear: true,
          withMonth: true,
          withNumericDate: true,
        })}`,
      ];
    }
    return [];
  }, [dateFilters]);

  const amountFilterProps = useMemo(() => {
    let initialOp = AmountComparisonOperator.Between;
    if (paramsState.amountFilter?.operator) {
      const opValue = paramsState.amountFilter.operator;
      if (isValidAmountOperator(opValue)) {
        initialOp = opValue;
      }
    }

    return {
      onApply: setAmountFilter,
      className: 'my-4',
      initialAmountValue: paramsState.amountFilter?.amount || '',
      initialMaxAmountValue: paramsState.amountFilter?.maxAmount || '',
      initialOperator: initialOp,
    };
  }, [paramsState.amountFilter, setAmountFilter]);

  const hasActiveFilters = useMemo(() => {
    return (
      statusFilters.length > 0 ||
      dateFilters.length > 0 ||
      amountFilter !== null
    );
  }, [statusFilters, dateFilters, amountFilter]);

  return (
    <div className="h-screen">
      <Navbar omitSearch title="Refunds" />
      <div className="px-6 py-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl px-2 font-semibold">Refunds List</h2>
        </div>

        <div className="flex gap-4 mb-4">
          <FilterButton
            label="Status"
            options={statusFilterOptions}
            onApply={setStatusFilters}
            className="my-4"
            initialFilters={statusFilters}
          />
          <FilterButton
            label="Refund Date"
            onApply={setDateFilters}
            className="my-4"
            dateFilter
            initialFilters={dateFilterInitialFilters}
          />
          <AmountFilter {...amountFilterProps} />
        </div>

        {refundsLoading && !refunds ? (
          <CustomSpinner />
        ) : refunds?.refunds?.edges.length ? (
          <>
            <div className="flex flex-col h-full">
              <h2 className="text-2xl px-2 font-semibold" id="header">
                Refunds List
              </h2>

              <div className="bg-white rounded-lg flex-1" id="table">
                <CommonTable
                  columns={columns}
                  data={transformedData}
                  prettify
                  viewRecord={handleViewDetails}
                  aria-label="Refunds table"
                />
              </div>

              <div className="mt-auto h-[60px] p-6">
                <Paginator
                  count={count}
                  pageInfo={{
                    ...refunds.refunds.pageInfo,
                    hasPrevPage: count > 1,
                  }}
                  loadMore={loadMore}
                />
              </div>
            </div>
          </>
        ) : (
          <EmptyState
            icon={<Emoji />}
            title={hasActiveFilters ? 'No refunds found' : "We're Good"}
            description={
              hasActiveFilters
                ? 'No refunds found for the selected criteria.'
                : 'It seems like no refunds have been processed yet. Once refunds are made, they will appear here for easy access and management.'
            }
          />
        )}
      </div>
    </div>
  );
};

export default Refunds;
