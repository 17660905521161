import {
  Button,
  Modal,
  ModalClose,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalFooter,
  toast,
} from '@cashiaApp/web-components';
import debounce from 'lodash/debounce';
import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';

import {ReactComponent as EyeSlashIcon} from '../../assets/icons/eye-slash.svg';
import {ReactComponent as EyeIcon} from '../../assets/icons/eye.svg';
import {ReactComponent as Tick} from '../../assets/icons/tick_green.svg';
import FormInput from '../../components/common/FormInput';
import CustomSpinner from '../../components/common/Spinner';
import ValidationIcon from '../../components/common/validationIcon';
import {useUpdatePasswordMutation} from '../../generated';
import {useAuth} from '../../utils/auth';
import {
  hasDigits,
  hasLowerCaseChars,
  hasSpecialChars,
  hasUpperCaseChars,
} from '../../utils/helper/validation';
import RenderErrorMessage from '../../utils/reusablefunctions/errorMessage';

interface UpdatePasswordProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ValidationState {
  hasLowerCaseChars: boolean;
  hasUpperCaseChars: boolean;
  hasDigits: boolean;
  hasSpecialChars: boolean;
  hasRequiredLength: boolean;
}

interface NewPasswordValidationState extends ValidationState {
  passwordsMatch: boolean;
  isDifferentFromOld: boolean;
}

const requiredLength = 8;

const ValidationChecklist = ({
  validations,
  showMatchValidation = false,
  showDifferentValidation = false,
}: {
  validations: ValidationState | NewPasswordValidationState;
  showMatchValidation?: boolean;
  showDifferentValidation?: boolean;
}) => (
  <div className="flex flex-wrap gap-8 mb-4">
    <div>
      <div className="flex items-center gap-1">
        <ValidationIcon
          condition={validations.hasLowerCaseChars}
          className="w-4 h-4"
        />
        <p className="text-sm text-textGrey">Lowercase Characters</p>
      </div>
      <div className="flex items-center gap-1">
        <ValidationIcon condition={validations.hasDigits} className="w-4 h-4" />
        <p className="text-sm text-textGrey">Number</p>
      </div>
      <div className="flex items-center gap-1">
        <ValidationIcon
          condition={validations.hasSpecialChars}
          className="w-4 h-4"
        />
        <p className="text-sm text-textGrey">Special Characters</p>
      </div>
      {showDifferentValidation && 'isDifferentFromOld' in validations && (
        <div className="flex items-center gap-1">
          <ValidationIcon
            condition={validations.isDifferentFromOld}
            className="w-4 h-4"
          />
          <p className="text-sm text-textGrey">Different from old password</p>
        </div>
      )}
    </div>
    <div>
      <div className="flex items-center gap-1">
        <ValidationIcon
          condition={validations.hasUpperCaseChars}
          className="w-4 h-4"
        />
        <p className="text-sm text-textGrey">Uppercase Characters</p>
      </div>
      <div className="flex items-center gap-1">
        <ValidationIcon
          condition={validations.hasRequiredLength}
          className="w-4 h-4"
        />
        <p className="text-sm text-textGrey">8 Characters Minimum</p>
      </div>
      {showMatchValidation && 'passwordsMatch' in validations && (
        <div className="flex items-center gap-1">
          <ValidationIcon
            condition={validations.passwordsMatch}
            className="w-4 h-4"
          />
          <p className="text-sm text-textGrey">Passwords match</p>
        </div>
      )}
    </div>
  </div>
);

const debouncedValidation = debounce(
  (
    setNewPasswordValidations: React.Dispatch<
      React.SetStateAction<NewPasswordValidationState>
    >,
    newPwd: string,
    confirmPwd: string,
    oldPwd: string
  ) => {
    setNewPasswordValidations((prev) => ({
      ...prev,
      hasDigits: hasDigits(newPwd),
      hasSpecialChars: hasSpecialChars(newPwd),
      hasLowerCaseChars: hasLowerCaseChars(newPwd),
      hasUpperCaseChars: hasUpperCaseChars(newPwd),
      hasRequiredLength: newPwd.length >= requiredLength,
      passwordsMatch: confirmPwd.trim() === newPwd.trim(),
      isDifferentFromOld: Boolean(
        newPwd.trim() && newPwd.trim() !== oldPwd.trim()
      ),
    }));
  },
  100
);

const UpdatePassword = ({
  isOpen,
  onClose,
}: UpdatePasswordProps): JSX.Element => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [logoutCountdown, setLogoutCountdown] = useState(5);
  const oldPasswordRef = useRef<HTMLDivElement>(null);
  const newPasswordRef = useRef<HTMLDivElement>(null);
  const confirmPasswordRef = useRef<HTMLDivElement>(null);
  const countdownTimerRef = useRef<NodeJS.Timeout | null>(null);

  const {logout} = useAuth();

  const [
    updatePasswordMutation,
    {loading: mutationLoading, error: mutationError},
  ] = useUpdatePasswordMutation();

  const [newPasswordValidations, setNewPasswordValidations] =
    useState<NewPasswordValidationState>({
      hasLowerCaseChars: false,
      hasUpperCaseChars: false,
      hasDigits: false,
      hasSpecialChars: false,
      hasRequiredLength: false,
      passwordsMatch: false,
      isDifferentFromOld: false,
    });

  const isOldPasswordValid = useCallback(
    () => oldPassword.trim() !== '',
    [oldPassword]
  );

  const isNewPasswordValid = useCallback(
    () => Object.values(newPasswordValidations).every(Boolean),
    [newPasswordValidations]
  );

  const allValidationsPassed = useMemo(
    () => isOldPasswordValid() && isNewPasswordValid(),
    [isOldPasswordValid, isNewPasswordValid]
  );

  const updateNewPasswordValidations = useCallback(
    (newPwd: string, confirmPwd: string, oldPwd: string) => {
      debouncedValidation(
        setNewPasswordValidations,
        newPwd,
        confirmPwd,
        oldPwd
      );
    },
    []
  );

  useEffect(() => {
    if (mutationError) {
      const errorMessage = mutationError.message || 'An error occurred';
      toast.error(`Failed to update password. ${errorMessage}`);

      if (
        errorMessage.toLowerCase().includes('old password') ||
        errorMessage.toLowerCase().includes('incorrect password') ||
        errorMessage.toLowerCase().includes('authentication')
      ) {
        setOldPasswordError(errorMessage);
      }
    }
  }, [mutationError]);

  useEffect(() => {
    updateNewPasswordValidations(newPassword, confirmPassword, oldPassword);
  }, [
    newPassword,
    confirmPassword,
    oldPassword,
    oldPasswordError,
    updateNewPasswordValidations,
  ]);

  useEffect(() => {
    if (showSuccessModal && logoutCountdown > 0) {
      countdownTimerRef.current = setTimeout(() => {
        setLogoutCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else if (logoutCountdown === 0) {
      logout();
    }

    return () => {
      if (countdownTimerRef.current) {
        clearTimeout(countdownTimerRef.current);
      }
    };
  }, [showSuccessModal, logoutCountdown, logout]);

  useEffect(() => {
    if (!showSuccessModal) {
      setLogoutCountdown(5);
      if (countdownTimerRef.current) {
        clearTimeout(countdownTimerRef.current);
      }
    }
  }, [showSuccessModal]);

  const handleOldPasswordChange = (text: string): void => {
    setOldPassword(text);
    setOldPasswordError(text.trim() ? '' : 'Old password is required');
  };

  const handleNewPasswordChange = (text: string): void => {
    setNewPassword(text);
    setNewPasswordError('');
  };

  const handleConfirmPasswordChange = (text: string): void => {
    setConfirmPassword(text);
  };

  const resetForm = useCallback((): void => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setOldPasswordError('');
    setNewPasswordError('');
    setNewPasswordValidations({
      hasLowerCaseChars: false,
      hasUpperCaseChars: false,
      hasDigits: false,
      hasSpecialChars: false,
      hasRequiredLength: false,
      passwordsMatch: false,
      isDifferentFromOld: false,
    });
  }, []);

  const handleCancel = (): void => {
    resetForm();
    onClose();
  };

  const handleSubmit = async (): Promise<void> => {
    if (!isOldPasswordValid()) {
      setOldPasswordError('Old password is required');
      return;
    }

    if (!isNewPasswordValid()) {
      setNewPasswordError('New password must meet all criteria');
      return;
    }

    try {
      const result = await updatePasswordMutation({
        variables: {
          input: {
            oldPassword,
            newPassword,
          },
        },
      });

      if (result.data?.updatePassword === true) {
        onClose();
        toast.success(
          'Password updated successfully. Logging you out for security reasons.'
        );
        setTimeout(() => {
          setShowSuccessModal(true);
        }, 300);
      } else if (!mutationError) {
        toast.error('Failed to update password. Please try again.');
      }
    } catch (error) {
      console.error('Password update error:', error);
    }
  };

  return (
    <>
      <Modal open={isOpen} onOpenChange={handleCancel}>
        <ModalContent className="w-full">
          <ModalHeader>
            <ModalTitle>Update Password</ModalTitle>
            <ModalClose />
          </ModalHeader>

          <div className="p-4">
            <div className="mb-4 w-[440px]" ref={oldPasswordRef}>
              <FormInput
                label="Old Password"
                required
                type={showOldPassword ? 'text' : 'password'}
                value={oldPassword}
                className="w-full mb-2"
                onChange={(text) => handleOldPasswordChange(text)}
                error={<RenderErrorMessage error={oldPasswordError} />}
                rightElement={
                  <div
                    className="p-2 cursor-pointer"
                    onClick={() => setShowOldPassword(!showOldPassword)}>
                    {showOldPassword ? (
                      <EyeIcon className="w-5 h-5 text-gray-700" />
                    ) : (
                      <EyeSlashIcon className="w-5 h-5 text-gray-700" />
                    )}
                  </div>
                }
              />
            </div>

            <div className="mb-2 w-[440px]" ref={newPasswordRef}>
              <FormInput
                label="New Password"
                required
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                className="w-full mb-2"
                onChange={(text) => handleNewPasswordChange(text)}
                error={<RenderErrorMessage error={newPasswordError} />}
                rightElement={
                  <div
                    className="p-2 cursor-pointer"
                    onClick={() => {
                      setShowNewPassword(!showNewPassword);
                    }}>
                    {showNewPassword ? (
                      <EyeIcon className="w-5 h-5 text-gray-700" />
                    ) : (
                      <EyeSlashIcon className="w-5 h-5 text-gray-700" />
                    )}
                  </div>
                }
              />
            </div>

            <div className="mb-4 overflow-hidden">
              <ValidationChecklist
                validations={newPasswordValidations}
                showMatchValidation={!!confirmPassword}
                showDifferentValidation
              />
            </div>

            <div className="mb-4 w-[440px]" ref={confirmPasswordRef}>
              <FormInput
                label="Confirm New Password"
                required
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                className="w-full mb-2"
                onChange={(text) => handleConfirmPasswordChange(text)}
                error={
                  <RenderErrorMessage
                    error={
                      confirmPassword && !newPasswordValidations.passwordsMatch
                        ? "Passwords don't match"
                        : undefined
                    }
                  />
                }
                rightElement={
                  <div
                    className="p-2 cursor-pointer"
                    onClick={() =>
                      setShowConfirmPassword(!showConfirmPassword)
                    }>
                    {showConfirmPassword ? (
                      <EyeIcon className="w-5 h-5 text-gray-700" />
                    ) : (
                      <EyeSlashIcon className="w-5 h-5 text-gray-700" />
                    )}
                  </div>
                }
              />
            </div>
          </div>

          <ModalFooter>
            <div className="flex justify-between w-full">
              <ModalClose asChild>
                <Button
                  onClick={handleCancel}
                  className="font-medium border border-dividerGrey rounded-md bg-faintGrey text-fontGrey hover:shadow-sm hover:border-mediumPurple">
                  Cancel
                </Button>
              </ModalClose>
              <Button
                onClick={handleSubmit}
                disabled={!allValidationsPassed || mutationLoading}
                className="font-medium rounded-md bg-surfacePurple hover:bg-mediumPurple text-white">
                {mutationLoading ? (
                  <div className="flex items-center justify-center gap-2">
                    <CustomSpinner className="w-5 h-5 fill-white" />
                  </div>
                ) : (
                  'Submit'
                )}
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        open={showSuccessModal}
        onOpenChange={() => setShowSuccessModal(false)}>
        <ModalContent className="flex flex-col items-center justify-center p-8 max-w-md">
          <div className="bg-green-100 p-3 rounded-full mb-4">
            <Tick className="w-12 h-12" />
          </div>
          <h2 className="text-2xl font-semibold text-center mb-1">
            Password Updated
          </h2>
          <p className="text-2xl font-semibold text-center mb-4">
            Successfully
          </p>
          <p className="text-center text-grey">
            For security reasons, you will be logged out in{' '}
            <span className="font-semibold">{logoutCountdown}</span> seconds.
          </p>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdatePassword;
