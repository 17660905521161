import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {ReactComponent as MpesaIcon} from '../../assets/icons/MpesaIcon.svg';
import {ReactComponent as DefaultBankIcon} from '../../assets/logos/default_bank.svg';
import CustomSpinner from '../../components/common/CustomSpinner';
import EmptyState from '../../components/common/EmptyState';
import FilterButton, {
  DateFilterObj,
} from '../../components/common/FilterButton';
import Navbar from '../../components/common/Navbar';
import Paginator, {PAGE_SIZE} from '../../components/common/Paginator';
import CommonTable from '../../components/common/table';
import useQueryParamState from '../../components/common/useQueryParamState';
import {PayoutMode, PayoutStatus, useGetPayoutsQuery} from '../../generated';
import formatDate from '../../utils/reusablefunctions/formatDate';
import formatMoney from '../../utils/reusablefunctions/formatMoney';
import formatPayoutMode from '../../utils/reusablefunctions/formatPaymentMode';
import {TableColumnType, TableDataType} from '../../utils/types/table.types';

const columns: TableColumnType[] = [
  'transaction_id',
  'business_name',
  'amount',
  'payout_method',
  'payout_date',
  'payout_status',
];

const payoutStatusOptions = {
  [PayoutStatus.Failed]: 'Failed',
  [PayoutStatus.PendingVerification]: 'Pending',
  [PayoutStatus.Completed]: 'Successful',
  [PayoutStatus.Processing]: 'Processing',
};

const Payouts = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {getParamsState, updateQueryParams} = useQueryParamState();
  const paramsState = getParamsState();
  const [count, setCount] = useState<number>(paramsState.page);
  const [currentCursor, setCurrentCursor] = useState<string | null>(
    paramsState.cursor
  );
  const [cursorStack, setCursorStack] = useState<string[]>(
    paramsState.cursorStack || []
  );
  const [statusFilters, setStatusFilters] = useState<string[]>(
    paramsState.statusFilters || []
  );
  const [dateFilters, setDateFilters] = useState<DateFilterObj[]>(
    paramsState.dateFilters || []
  );

  useEffect(() => {
    updateQueryParams({
      page: count,
      cursor: currentCursor,
      cursorStack,
      statusFilters,
      dateFilters,
    });
  }, [
    count,
    currentCursor,
    cursorStack,
    statusFilters,
    dateFilters,
    updateQueryParams,
  ]);
  const {data: payouts, loading: payoutsLoading} = useGetPayoutsQuery({
    variables: {
      input: {
        cursor: {
          first: PAGE_SIZE,
          after: currentCursor,
        },
        filter: {
          status: statusFilters.length
            ? statusFilters.map((x) => {
                if (x.toUpperCase() === 'PENDING') {
                  return PayoutStatus.PendingVerification;
                }
                const upperCase = x.toUpperCase().replace(' ', '_');
                return upperCase as PayoutStatus;
              })
            : undefined,
          dateTime:
            dateFilters.length &&
            dateFilters[0]?.startDate &&
            dateFilters[0]?.endDate
              ? {
                  startDate: dateFilters[0].startDate,
                  endDate: dateFilters[0].endDate,
                }
              : undefined,
        },
      },
    },
  });

  const transformedData = React.useMemo(() => {
    if (!payouts?.payouts?.edges) return [];

    return payouts?.payouts?.edges.map(({node}) => ({
      id: node.id,
      transaction_id: `#${node?.reference || ''}`,
      amount:
        node.amount.currencyCode +
        ' ' +
        formatMoney(node?.amount.amountInCents),
      payout_method:
        node.mode === PayoutMode.Mpesa
          ? [
              <MpesaIcon className="w-[32px] h-[32px]" />,
              formatPayoutMode('M-Pesa'),
            ]
          : [
              <DefaultBankIcon className="w-[32px] h-[32px]" />,
              formatPayoutMode('Bank'),
            ],
      business_name: node.business.name || '',

      payout_date: node.createdAt
        ? formatDate(new Date(node?.createdAt as string), {
            withTime: true,
            withNumericDate: true,
          })
        : '---',

      payout_status: [
        node?.status === PayoutStatus.PendingVerification
          ? 'PENDING'
          : node?.status || '',
      ],
    })) as TableDataType[];
  }, [payouts]);

  const loadMore = useCallback(
    async (next: boolean) => {
      try {
        if (next && payouts?.payouts?.pageInfo?.hasNextPage) {
          const nextCursor = payouts?.payouts?.pageInfo?.endCursor;
          if (nextCursor) {
            if (currentCursor) {
              setCursorStack((prev) => [...prev, currentCursor]);
            }
            setCurrentCursor(nextCursor);
            setCount((prev) => prev + 1);
          }
        } else if (!next && count > 1) {
          if (cursorStack.length > 0) {
            const previousCursor = cursorStack[cursorStack.length - 1];
            if (previousCursor) {
              setCursorStack((prev) => prev.slice(0, -1));
              setCurrentCursor(previousCursor);
              setCount((prev) => prev - 1);
            }
          } else {
            setCurrentCursor(null);
            setCount(1);
          }
        }
      } catch (error) {
        console.error('Error loading more businesses:', error);
      }
    },
    [payouts, currentCursor, cursorStack, count]
  );

  const handleViewDetails = (id: string) => {
    const currentParams = searchParams.toString();
    navigate(
      `/payouts/payout-details/${id}${currentParams ? `?${currentParams}` : ''}`
    );
  };

  const hasActiveFilters = React.useMemo(() => {
    return statusFilters.length > 0 || dateFilters.length > 0 || count > 1;
  }, [statusFilters, dateFilters, count]);

  return (
    <div className="h-screen">
      <Navbar omitSearch title="Payouts" />
      <div className="px-6 py-4">
        <h2 className="text-2xl px-2 font-semibold">Payout List</h2>
        <div className="flex gap-4">
          <FilterButton
            label="Status"
            options={Object.values(payoutStatusOptions)}
            onApply={setStatusFilters}
            className="my-4"
            initialFilters={statusFilters}
          />
          <FilterButton
            label="Date"
            onApply={setDateFilters}
            className="my-4"
            dateFilter
            initialFilters={
              dateFilters?.length &&
              dateFilters[0]?.startDate &&
              dateFilters[0]?.endDate
                ? [
                    `${formatDate(dateFilters[0].startDate, {
                      withDate: true,
                      withTime: false,
                      withYear: true,
                      withMonth: true,
                      withNumericDate: true,
                    })} - ${formatDate(dateFilters[0].endDate, {
                      withDate: true,
                      withTime: false,
                      withYear: true,
                      withMonth: true,
                      withNumericDate: true,
                    })}`,
                  ]
                : []
            }
          />
        </div>
        {payoutsLoading && !payouts ? (
          <CustomSpinner />
        ) : payouts?.payouts.edges.length ? (
          <>
            <div className="flex flex-col h-full">
              <div className="bg-white rounded-lg">
                <CommonTable
                  columns={columns}
                  data={transformedData}
                  prettify
                  viewRecord={handleViewDetails}
                />
              </div>
              <div className="mt-auto h-[60px] p-6">
                <Paginator
                  count={count}
                  pageInfo={payouts?.payouts.pageInfo}
                  loadMore={loadMore}
                />
              </div>
            </div>
          </>
        ) : (
          <EmptyState
            title="It's lonely out here"
            description={
              hasActiveFilters
                ? 'No payouts found for the selected criteria.'
                : 'It seems like no payouts have been processed yet. Once payouts are made from merchant accounts, they will appear here for easy access and management.'
            }
          />
        )}
      </div>
    </div>
  );
};

export default Payouts;
