import {ApolloError} from '@apollo/client';
import {
  Button,
  toast,
  Modal,
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalTitle,
  ModalTrigger,
} from '@cashiaApp/web-components';
import {useMemo, ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import addCategoryIcon from '../../assets/icons/add-square.svg';
import documentIcon from '../../assets/icons/docIcon.svg';
import CustomPhoneInput from '../../components/common/CustomPhoneInput';
import CustomSpinner from '../../components/common/CustomSpinner';
import EditButton from '../../components/common/EditButton';
import FormInput from '../../components/common/FormInput';
import Navbar from '../../components/common/Navbar';
import StatusDot from '../../components/common/StatusDot';
import {useGetUserQuery, useUpdateUserDetailsMutation} from '../../generated';
import {
  hasDigits,
  hasSpaces,
  isValidEmail,
} from '../../utils/helper/validation';
import capitalizeFirstLetter from '../../utils/reusablefunctions/capitalizeFirstLetter';
import RenderErrorMessage from '../../utils/reusablefunctions/errorMessage';
import formatDate from '../../utils/reusablefunctions/formatDate';
import userInitials from '../../utils/reusablefunctions/getNameInitials';
import {useUserAuth} from '../../utils/user';
import validateKenyanPhoneNumber from '../../utils/validatePhoneNumber';
import UserActionSection from '../Login/components/UserActionCenter';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: {
    countryCode: string;
    number: string;
  };
}

interface FormErrors {
  [key: string]: string;
}

type ValidateFunction = (values: FormValues) => FormErrors;

const useFormValidation = (
  initialState: FormValues,
  validate: ValidateFunction
) => {
  const [values, setValues] = useState<FormValues>(initialState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const reset = useCallback(() => {
    setValues(initialState);
    setErrors({});
    setIsSubmitting(false);
  }, [initialState]);

  return {
    handleChange,
    reset,
    values,
    setValues,
    errors,
    setErrors,
    isSubmitting,
    validate,
  };
};

const initialState: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: {
    countryCode: '',
    number: '',
  },
};

const validate: ValidateFunction = (values) => {
  const errors: FormErrors = {};

  const trimmedFirstName = values.firstName.trim();
  if (!trimmedFirstName) {
    errors.firstName = 'First name is required';
  } else if (hasSpaces(values.firstName)) {
    errors.firstName = 'First name cannot contain spaces between letters';
  } else if (values.firstName && hasDigits(values.firstName)) {
    errors.firstName = 'First name can only contain letters';
  }

  const trimmedLastName = values.lastName.trim();
  if (!trimmedLastName) {
    errors.lastName = 'Last name is required';
  } else if (hasSpaces(values.lastName)) {
    errors.lastName = 'Last name cannot contain spaces between letters';
  } else if (values.lastName && hasDigits(values.lastName)) {
    errors.lastName = 'Last name can only contain letters';
  }

  if (!values.email.trim()) {
    errors.email = 'Email is required';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Email address is invalid';
  }

  if (
    !values.phoneNumber?.number.trim() ||
    !validateKenyanPhoneNumber(values.phoneNumber.number.trim())
  ) {
    errors.phoneNumber = 'Phone number is required and must be valid';
  }

  return errors;
};

const AccountDetails = () => {
  const navigate = useNavigate();
  const {roleAccess} = useUserAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState<FormValues>(initialState);
  const {handleChange, values, setValues, errors, setErrors} =
    useFormValidation(initialState, validate);
  const [updateUser, {loading: updateLoading}] = useUpdateUserDetailsMutation();

  const [searchParams] = useSearchParams();

  const breadcrumbs = useMemo(() => {
    const params = searchParams.toString();
    return [
      {
        name: 'Accounts',
        location: `/accounts${params ? `?${params}` : ''}`,
      },
      {
        name: 'Account Details',
      },
    ];
  }, [searchParams]);

  useEffect(() => {
    setErrors(validate(values));
  }, [values, setErrors]);

  const {id} = useParams<{id: string}>();

  const {data, loading, refetch} = useGetUserQuery({
    variables: {
      id: id || '',
    },
    skip: !id,
  });

  useEffect(() => {
    if (loading) return;
    setErrors(validate(values));
  }, [values, setErrors, loading]);

  const userBusiness = useMemo(() => {
    return data?.user?.businesses || [];
  }, [data]);

  const newInitialValues = useMemo(() => {
    const userData = data?.user;
    return {
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      email: userData?.email || '',
      phoneNumber: {
        countryCode: userData?.phone?.countryCode || '',
        number: userData?.phone?.number || '',
      },
    };
  }, [data]);

  useEffect(() => {
    setInitialValues(newInitialValues);
    setValues(newInitialValues);
  }, [newInitialValues, setValues]);

  const userDetails = useMemo(
    () => [
      {
        title: 'Account Status',
        status: data?.user?.status,
        description: capitalizeFirstLetter(
          (data?.user?.status || '').toLowerCase()
        ),
      },
      {
        title: 'Created Date',
        description: formatDate(new Date(data?.user?.createdAt as string), {
          withTime: true,
          withNumericDate: true,
          with24Hour: true,
          withSeconds: true,
        }),
      },
    ],
    [data]
  );

  const initials = useMemo(
    () =>
      data
        ? userInitials(data?.user?.firstName || '', data?.user?.lastName || '')
        : '',
    [data]
  );

  const handleSubmit = () => {
    if (Object.keys(errors).length > 0) return;
    setIsSubmitting(true);

    updateUser({
      variables: {
        input: {
          id,
          ...(values.firstName !== newInitialValues.firstName && {
            firstName: values.firstName,
          }),
          ...(values.lastName !== newInitialValues.lastName && {
            lastName: values.lastName,
          }),
          ...(values.email !== newInitialValues.email && {email: values.email}),
          ...(values.phoneNumber?.number !==
            newInitialValues.phoneNumber?.number && {
            phone: {
              number: values.phoneNumber.number,
              countryCode: values.phoneNumber.countryCode,
            },
          }),
        },
      },
    })
      .then((res) => {
        if (res.data?.updateUser) {
          void refetch();
        }
      })
      .catch((e: ApolloError) => {
        toast.error(`Error: ${e.message}`);
        setIsEditing(true);
      });
    setIsSubmitting(false);
  };

  const handleAddAccount = () => {
    const currentParams = searchParams.toString();
    navigate(
      `/accounts/account-details/${id}/add-business-profile${currentParams ? `?${currentParams}` : ''}`
    );
  };

  return (
    <div className="h-screen">
      <Navbar title="Account Details" omitSearch breadcrumbs={breadcrumbs} />

      {loading ? (
        <CustomSpinner />
      ) : (
        <div className="p-8 bg-displayGrey">
          <div className="mx-6 border border-dividerGrey p-8 rounded-lg min-h-[136px] w-[75vw] flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="relative inline-flex h-[56px] w-[56px] items-center justify-center rounded-full bg-mediumPurple text-xl font-medium text-white  ">
                {initials}
              </div>

              <h1 className="font-semibold text-2xl whitespace-nowrap">
                {data?.user?.firstName || ''} {data?.user?.lastName || ''}
              </h1>
            </div>

            <div className="flex flex-row gap-20">
              {userDetails.map((detail) => (
                <div key={detail.title} className="flex gap-2 items-center">
                  {detail.status && <StatusDot status={detail.status} />}

                  <div>
                    <h1 className="text-foggy font-semibold text-xs">
                      {detail?.title}
                    </h1>
                    <h1 className="font-semibold text-base">
                      {detail.description}
                    </h1>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="m-6 border p-8 flex-col border-dividerGrey rounded-lg min-h-[370px] w-[75vw] flex">
            <div className="flex gap-40 justify-between items-center w-full mb-8">
              <h1 className="font-semibold text-2xl">Account Information</h1>

              {isEditing ? (
                <Modal>
                  <div className="flex gap-3">
                    <ModalTrigger asChild>
                      <Button
                        disabled={Object.keys(errors).length > 0}
                        className="w-[134px] h-[44px] whitespace-nowrap border-dividerGrey font-semibold border text-base rounded-lg bg-surfacePurple hover:bg-mediumPurple text-white">
                        Save Changes
                      </Button>
                    </ModalTrigger>

                    <Button
                      onClick={() => {
                        setValues(initialValues);
                        setIsEditing(false);
                      }}
                      className="w-[77px] h-[44px] border-dividerGrey font-semibold border text-base hover:shadow-sm hover:border-1
                      hover:border-mediumPurple rounded-lg bg-faintGrey text-fontGrey">
                      Cancel
                    </Button>
                  </div>
                  <ModalContent>
                    <ModalTitle>Save Changes</ModalTitle>
                    <ModalDescription>
                      Please review your updates to ensure all information is
                      correct. This will permanently update your account with
                      new details.
                    </ModalDescription>
                    <ModalDescription>
                      Would you like to proceed?
                    </ModalDescription>
                    <div className="flex justify-between">
                      <ModalClose asChild>
                        <Button
                          className="border border-dividerGrey bg-faintGrey hover:border-1
        hover:border-mediumPurple text-black rounded-lg p-2.5">
                          Cancel
                        </Button>
                      </ModalClose>
                      <ModalClose asChild>
                        <Button
                          className="whitespace-nowrap border-dividerGrey font-semibold border text-base rounded-lg bg-surfacePurple hover:bg-mediumPurple text-white"
                          onClick={() => {
                            setIsEditing(false);
                            handleSubmit();
                          }}>
                          {isSubmitting && updateLoading ? (
                            <CustomSpinner />
                          ) : (
                            '  Save Changes'
                          )}
                        </Button>
                      </ModalClose>
                    </div>
                  </ModalContent>
                </Modal>
              ) : (
                roleAccess.canEditAccount && (
                  <EditButton onButtonClick={() => setIsEditing(true)} />
                )
              )}
            </div>

            <form onSubmit={(e) => e.preventDefault()}>
              <div className=" flex text-grey gap-6">
                <div className="w-[480px] " id="firstName">
                  <FormInput
                    label="First Name"
                    required
                    value={values.firstName}
                    className="w-full"
                    disabled={!isEditing}
                    name="firstName"
                    maxLength={60}
                    error={<RenderErrorMessage error={errors.firstName} />}
                    onChange={(val: string) =>
                      handleChange({
                        target: {
                          name: 'firstName',
                          value: val,
                        },
                      } as ChangeEvent<HTMLInputElement>)
                    }
                  />
                </div>
                <div className="w-[480px]" id="lastName">
                  <FormInput
                    label="Last Name"
                    name="lastName"
                    required
                    onChange={(val: string) =>
                      handleChange({
                        target: {
                          name: 'lastName',
                          value: val,
                        },
                      } as ChangeEvent<HTMLInputElement>)
                    }
                    value={values.lastName}
                    maxLength={60}
                    error={<RenderErrorMessage error={errors.lastName} />}
                    className=" w-full"
                    disabled={!isEditing}
                  />
                </div>
              </div>
              <div className=" flex text-grey gap-6">
                <div className="w-[480px]" id="email">
                  <FormInput
                    label="Email"
                    required
                    value={values.email}
                    className=" w-full"
                    error={<RenderErrorMessage error={errors.email} />}
                    maxLength={60}
                    disabled={!isEditing}
                    onChange={(val: string) =>
                      handleChange({
                        target: {
                          name: 'email',
                          value: val,
                        },
                      } as ChangeEvent<HTMLInputElement>)
                    }
                  />
                </div>
                <div className="w-[480px]  ">
                  <label className="sm:font-medium md:font-medium text-textGrey sm:text-[15px] md:text-[16px] text-sm font-bold flex mb-1 ml-1 items-center">
                    Phone Number *
                  </label>
                  <div id="phoneNumber">
                    <CustomPhoneInput
                      countryCode={values.phoneNumber?.countryCode || ''}
                      phoneNumber={values.phoneNumber?.number || ''}
                      onChange={(countryCode: string, number: string) =>
                        setValues((prevValues) => ({
                          ...prevValues,
                          phoneNumber: {countryCode, number},
                        }))
                      }
                      isDisabled={!isEditing}
                      error={<RenderErrorMessage error={errors?.phoneNumber} />}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="m-6 border p-8 border-dividerGrey rounded-lg min-h-[226px] w-[75vw] flex flex-col">
            {userBusiness && userBusiness.length > 0 ? (
              <>
                <div className="flex justify-between items-center w-full mb-8">
                  <h1 className="font-semibold text-2xl">Business Profiles</h1>
                </div>
                <div className="grid grid-cols-12 gap-4 px-2 text-base text-foggy font-semibold mb-2">
                  <div className="col-span-3">
                    <h3>Business Name</h3>
                  </div>
                  <div className="col-span-4">
                    <h3>Business Category</h3>
                  </div>
                  <div className="col-span-3">
                    <h3>Compliance Status</h3>
                  </div>
                </div>

                {userBusiness.map((business) => (
                  <div
                    key={business.id}
                    className="grid grid-cols-12 gap-4 text-foggy bg-gray-50 rounded-lg border border-dividerGrey h-[56px] pl-3">
                    <div className="col-span-3 flex items-center border-r border-dividerGrey">
                      <span>{business?.name || ''}</span>
                    </div>
                    <div className="col-span-4 flex items-center border-r border-dividerGrey">
                      <span
                        className="truncate"
                        title={business?.category.name}>
                        {business?.category.name}
                      </span>
                    </div>
                    <div className="col-span-3 flex items-center">
                      <div className="flex items-center gap-2">
                        <StatusDot status={business.complianceStatus} />
                        <span>
                          {capitalizeFirstLetter(
                            String(
                              business?.complianceStatus || ''
                            ).toLowerCase()
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-span-2 flex justify-center  border-l border-dividerGrey items-center w-[150px]">
                      <Button
                        onClick={() =>
                          navigate(`/business-profiles/details/${business.id}`)
                        }
                        className="font-semibold text-black text-sm bg-faintGrey border-dividerGrey rounded-lg hover:shadow-sm hover:border-[1px]
                        hover:border-mediumPurple">
                        View Profile
                      </Button>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className=" gap-1  flex flex-col items-center justify-center ">
                <img
                  src={documentIcon}
                  alt="Document"
                  className="p-2 rounded-md  bg-lightPurple mb-2"
                />
                <h1 className="text-foggy text-2xl font-semibold">
                  No Business Profiles
                </h1>
                <p className="text-base font-light text-foggy">
                  Any Business Profiles Linked to this Account will be listed
                  here
                </p>

                {roleAccess.canCreateBusinessProfile && (
                  <Button
                    className="w-[220px] h-[44px] my-2 font-semibold text-white text-sm bg-surfacePurple hover:bg-mediumPurple border rounded-lg"
                    onClick={handleAddAccount}
                    id="addBusinessButton">
                    <img src={addCategoryIcon} alt="" className="mr-2" />
                    Add Business Profile
                  </Button>
                )}
              </div>
            )}
          </div>
          {data?.user && (
            <UserActionSection
              user={data.user}
              isAdmin={false}
              entityType="Merchant"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AccountDetails;
