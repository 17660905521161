export const getTermsAndConditionsData = () => {
  return [
    {
      id: 'tab_item_0',
      title: 'Introduction',
      content: [
        'These Administration Terms of Service (the "Admin TOS") outline your rights and responsibilities when accessing the Cashia Platform, our products, software, data feed and services, provided to you on, and use of our applications (collectively, "Services").',
        'If/When you operate the Administration ("Admin") Portal these Admin TOS govern your access and use of our Services.',
      ],
    },
    {
      id: 'tab_item_1',
      title: 'Background and Purpose',
      content: [
        'This Agreement sets out the terms and conditions for the delivery of the Cashia Product by the staff to the Merchant.',
        'The main purpose of this admin portal is to empower user access, monitor account analytics, configure product integrations, ensure compliance with necessary settings and monitor all backend processes. This streamlined command center simplifies administrative tasks, ensuring your team has the tools they need for success.',
      ],
    },
    {
      id: 'tab_item_2',
      title: 'Term',
      content: [
        'These Terms of Service come into effect upon acceptance by the User.',
        'An audit trail and record of acceptance shall be retained.',
      ],
    },
    {
      id: 'tab_item_3',
      title: 'Initial Access and Use',
      content: [
        'Cashia grants the user a non-exclusive, non-transferable right to access and use the admin portal.',
        'The user shall use reasonable endeavors, including reasonable security measures relating to administrator login details, to ensure that no unauthorized person may gain access to the Cashia Product using an administrator account.',
        'The user must not use the admin portal in any way that causes, or may cause, damage to the Cashia Product or impairment of the availability or accessibility of the Cashia Product.',
      ],
    },
    {
      id: 'tab_item_4',
      title: 'User Account',
      content: [
        'The use of the Cashia Admin Portal requires the creation of a user account for each Authorized User. User accounts are comprised of a user name and password ("User Account"). User Accounts may be created by the system administrator, only after the user has duly submitted an authorized User Access Form. User Accounts shall not be shared and shall be treated by each Authorized User as confidential information. Cashia shall not share Authorized User access credentials with any other third parties. Cashia shall be responsible for the actions of Authorized Users and shall ensure each Authorized User uses and accesses the Cashia Admin Products in accordance with the terms of this Agreement and the relevant internal processes.',
        'Upon registration the user shall be require to fill in a User Access Form containing their credentials which shall be used to give them access to the admin portal.',
      ],
    },
    {
      id: 'tab_item_5',
      title: 'Rights granted to user',
      content: [
        '1. During the sign up process the user shall be able to produce his/her information (herein referred to as "user information") which shall be used by Cashia to provide services to the user.',
        '2. By  acceptance  to  the  TOC  the  user  grants  Cashia  to  access  to  their  account information for easier and safe transactions',
        '3. The user shall indemnify Cashia against arising from any user information in regards to liability arising from it',
        '4. The  user  herein  hereby  grants  Cashia  a  right  to  lien  in  case  of  any  breach  of  this contract',
      ],
    },
    {
      id: 'tab_item_6',
      title: 'Support',
      content: [
        'Cashia will provide the Support Services on Business Days and during the Business Hours to the user. Timelines for Support shall be as determined by the Cashia Customer Support team in collaboration with the Engineering team. Service  delivery  shall  be  in  accordance  with  internal departmental SLAs or as captured on the Customer Support Framework.',
      ],
    },
    {
      id: 'tab_item_7',
      title: 'Data Protection',
      content: [
        '1. Cashia shall protect all personal data that belongs to the user in which it shall uphold at most confidentiality and integrity while handling the user’s data.',
        '2. Cashia shall act in accordance with the role of a Data Controller as per the provisions of the Data Protection Act, 2019.',
        '3. Cashia shall not;',
        'a. Disclose personal data of the user to any third party without seeking consent and approval from the user except during operation of law',
        'b. Modify or alter personal data of the user and;',
        'c. Disclose personal data during any technical malfunction.',
      ],
    },
    {
      id: 'tab_item_8',
      title: 'Intellectual Property',
      content: [
        'We respect the intellectual property rights of others and ask you to do the same. We respond to all valid notices of such infringement, and our policy is to suspend or terminate the accounts of repeat infringers.',
      ],
    },
    {
      id: 'tab_item_9',
      title: 'Security',
      content: [
        'We have implemented technical and organizational measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. However, we cannot guarantee that unauthorized third parties will never be able to defeat those measures or use your personal information for improper purposes. You provide your personal information to us at your own risk.',
        'You are responsible for safeguarding your password and for restricting access to the Services from your compatible mobile devices and computers. You will immediately notify us of any unauthorized use of your password or Cashia Account or any other breach of security.  Notwithstanding  in  the  event  of  any  dispute  between  two  or  more  parties  as  to  account ownership, we will be the sole arbiter of such dispute in our sole discretion. Our decision (which may include termination or suspension of any Cashia Account subject to dispute) will be final and binding on all parties.',
      ],
    },
    {
      id: 'tab_item_10',
      title: 'Privacy',
      content: [
        'When you process information that identifies or is reasonably capable of identifying an individual to  Cashia  in  connection  with  the Services  (including  information  collected  by  Cashia  on  your behalf),  you  agree  to  comply  with  applicable  laws  regarding the  collection,  use,  disclosure, protection, and retention of this information.',
      ],
    },
    {
      id: 'tab_item_11',
      title: 'Communications',
      content: [
        'You  consent  to  accept  and  receive  communications  from  us,  including  e-mail,  text  messages, calls, and push notifications to the cellular telephone number you provide to us when you sign-up for  a  admin  portal  account  or  update  the  contact  information  associated  with  your  account. Such communications may include, but are not limited to requests for secondary authentication, receipts,  reminders,  notifications  regarding  updates  to  your  account  or  account  support,  and marketing  or  promotional  communications.  You  acknowledge  that  you  are  not  required  to consent  to  receive  promotional  texts  or  calls  as  a  condition  of  using  the  Services.  Call  and  text message communications may be generated by automatic telephone dialing systems. Standard message and data rates applied by your cell phone carrier may apply to the text messages we  send you.',
        'You may opt-out of receiving promotional email communications we send to you by following the unsubscribe options on such emails. You may opt out of any promotional phone calls by informing the caller that you would not like to receive future promotional calls. You may only opt-out of text messages from Cashia by replying STOP. You acknowledge that opting out of receiving  communications may impact your use of the Services.',
      ],
    },
    {
      id: 'tab_item_12',
      title: 'Modification and Termination',
      content: [
        'We may modify or discontinue the Admin Portal, its features, or access to it at any time for operational, security, or compliance reasons. Additionally, we may suspend or terminate your access to the Admin Portal if you violate company policies, misuse the system, or for other justifiable business reasons. Where feasible, we will provide prior notice via email or through an internal communication channel.',
        'If you wish to deactivate your Admin Portal account, please follow the internal account deactivation process or contact Customer Support',
      ],
    },
    {
      id: 'tab_item_13',
      title: 'Limitations of Liability',
      content: [
        'Each party is liable for any damages incurred due to breach of the Agreement, subject to the limitations.',
      ],
    },
    {
      id: 'tab_item_14',
      title: 'Effect of Termination',
      content: [
        'If your Admin Portal account is suspended or terminated for any reason: ',
        '1. We may, in accordance with our data retention policies, delete your account data from our systems. However, this does not affect any operational records necessary for compliance or business continuity.',
        '2. We will not be liable to you for compensation, reimbursement, or damages arising from the termination or deletion of your account data.',
      ],
    },
    {
      id: 'tab_item_15',
      title: 'Ownership',
      content: [
        'Cashia retains all rights, title, and interest, including copyrights and other intellectual property rights, in the Admin Portal and its associated features, workflows, and systems. These Terms do not grant you any rights to our trademarks or service marks.',
        'For the purposes of these Terms, “Intellectual Property Rights” include all patent rights, copyrights, moral rights, trade secrets, trademarks, goodwill, and other proprietary rights, along with any applications, registrations, renewals, or extensions, as recognized by applicable laws.',
      ],
    },
    {
      id: 'tab_item_16',
      title: 'Indemnity',
      content: [
        'You agree to indemnify and hold [Company Name] harmless from claims, losses, and liabilities arising from:',
        '1. Any breach of your obligations under these Terms;',
        '2. Unauthorized or improper use of the Admin Portal;',
        '3. Violation of applicable privacy, data protection, or intellectual property laws;',
        '4. Any unauthorized access resulting from your failure to maintain confidentiality of login  credentials',
      ],
    },
    {
      id: 'tab_item_17',
      title: 'Confidentiality',
      content: [
        '1. Maintain Confidentiality: Keep the other Party’s Confidential Information strictly confidential.',
        '2. Restrict Disclosure: Not disclose Confidential Information to any third party without prior written consent, except under confidentiality terms approved in writing by the disclosing Party.',
        '3. Protect Information: Use the same level of care to safeguard the other Party’s Confidential Information as it does for its own confidential information of a similar nature, with a minimum standard of reasonable care.',
        '4. Act in Good Faith: Always act in good faith concerning the other Party’s Confidential Information',
        '5. Limit Use: Use the Confidential Information solely for the agreed Permitted Purpose.',
      ],
    },
    {
      id: 'tab_item_18',
      title: 'Dispute Resolution & Accountability',
      content: [
        '1. Internal Disciplinary Action',
        'Any disputes, misconduct, or breaches of these Terms involving employees shall be handled in accordance with the Employee Handbook, including but not limited to internal investigations, disciplinary measures, and corrective actions as deemed appropriate by management.',
        '2. Personal Liability',
        'Individuals may be held personally liable for any actions that result in financial loss, reputational damage, or regulatory violations affecting the company. This may include recovery of losses, termination of access to services, and legal action where applicable. ',
        '3.Regulatory Compliance and Enforcement',
        'Any disputes or violations that fall within the jurisdiction of regulatory bodies shall be escalated to the relevant regulatory authorities for investigation and enforcement. This may include but is not limited to:',
        'a. Fines and penalties imposed by regulators.',
        'b. Suspension or revocation of licenses or approvals.',
        'c. Legal action initiated by the company or regulatory authorities.',
      ],
    },
    {
      id: 'tab_item_19',
      title: 'Jurisdiction & Legal Recourse',
      content: [
        'Any  legal  proceedings  arising  from  a  violation  of  these  Terms  shall  be  subject  to  the  laws  and courts of the Republic of Kenya, ensuring compliance with local and international regulations.',
      ],
    },
    {
      id: 'tab_item_20',
      title: 'Force Majeure Event',
      content: [
        'Neither Party shall be liable for any failure or delay in performing its obligations under these Terms due to a Force Majeure Event, including but not limited to acts of God, natural disasters, strikes, labor disputes, war, civil unrest, government actions, telecommunications or internet failures, or any other event beyond the reasonable control of the affected Party. The affected Party shall promptly notify the other Party of such an event and make reasonable efforts to resume performance as soon as possible.',
      ],
    },
    {
      id: 'tab_item_21',
      title: 'Assignment',
      content: [
        'You  may  not  assign,  transfer,  or  delegate  any  of  your  rights  or  obligations  under  these  Terms without our prior written consent. Any attempted assignment, transfer, or delegation in violation of this provision will be null and void. We may freely assign or transfer our rights and obligations under these Terms without restriction',
      ],
    },
  ];
};

export default getTermsAndConditionsData;
