import {ApolloError} from '@apollo/client';
import {toast} from '@cashiaApp/web-components';
import React, {useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import AuthLayout from './components/AuthLayout';
import PasswordInputForm from './components/PasswordInputForm';
import {ReactComponent as Tick} from '../../assets/icons/tick_green.svg';
import Modal from '../../components/common/Modal';
import {useSetPasswordMutation} from '../../generated';
import {useAuth} from '../../utils/auth';

export default function SetPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const [showModal, setShowModal] = useState(false);
  const {isAuthenticated, logout} = useAuth();

  const [updatePassword, {loading}] = useSetPasswordMutation();

  const handleSubmit = async (password: string) => {
    if (!token) {
      toast.error('Setup token is missing');
      return;
    }

    try {
      const res = await updatePassword({
        variables: {
          input: {
            token,
            password,
          },
        },
      });

      if (res.data?.setAdminPassword) {
        if (isAuthenticated) {
          await logout();
          toast.info(
            'Previous session has been logged out for security purposes'
          );
        }
        setShowModal(true);
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        toast.error('Unable to complete request. Please try again');
      }
    } catch (err: unknown) {
      if (err instanceof ApolloError) {
        toast.error(err.message);
      } else {
        toast.error('An unexpected error occurred');
        console.error('Unexpected error:', err);
      }
    }
  };

  return (
    <>
      <AuthLayout title="Set Your Password" subtitle="Welcome to Cashia">
        <PasswordInputForm
          onSubmit={handleSubmit}
          buttonText="Set Password"
          loading={loading}
        />
      </AuthLayout>

      <Modal
        onClose={() => setShowModal(false)}
        isVisible={showModal}
        className="h-[270px] w-[525px]">
        <div className="flex flex-col items-center justify-center h-full">
          <Tick className="mb-5 h-16 w-16" />
          <div className="flex flex-col items-center text-2xl font-semibold">
            <p>Password Set</p>
            <p>Successfully</p>
          </div>
        </div>
      </Modal>
    </>
  );
}
