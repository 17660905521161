import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import CustomSpinner from '../../components/common/CustomSpinner';
import EmptyState from '../../components/common/EmptyState';
import Navbar from '../../components/common/Navbar';
import Paginator, {PAGE_SIZE} from '../../components/common/Paginator';
import CommonTable from '../../components/common/table';
import useQueryParamState from '../../components/common/useQueryParamState';
import {useGetBusinessesQuery} from '../../generated';
import formatDate from '../../utils/reusablefunctions/formatDate';
import {TableColumnType, TableDataType} from '../../utils/types/table.types';

const columns: TableColumnType[] = [
  'business_name',
  'category',
  'owner_name',
  'phone_number',
  'date_created',
  'compliance_status',
];

const BusinessProfiles = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {getParamsState, updateQueryParams} = useQueryParamState();
  const paramsState = getParamsState();

  const [count, setCount] = useState<number>(paramsState.page);
  const [currentCursor, setCurrentCursor] = useState<string | null>(
    paramsState.cursor
  );
  const [cursorStack, setCursorStack] = useState<string[]>(
    paramsState.cursorStack || []
  );

  useEffect(() => {
    updateQueryParams({
      page: count,
      cursor: currentCursor,
      cursorStack,
    });
  }, [count, currentCursor, cursorStack, updateQueryParams]);

  const {data, loading} = useGetBusinessesQuery({
    variables: {
      input: {
        cursor: {
          first: PAGE_SIZE,
          after: currentCursor,
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const transformedData = React.useMemo(() => {
    if (!data?.businesses.edges) return [];

    return data.businesses.edges.map(({node}) => ({
      id: node.id,
      business_name: [node.name, ''],
      category: node.category?.name,
      owner_name: node.owner
        ? `${node.owner.firstName} ${node.owner.lastName}`
        : '',
      phone_number: node.phoneNumber
        ? `${node.phoneNumber.countryCode}${node.phoneNumber.number}`
        : '',
      date_created: node.createdAt
        ? formatDate(new Date(node.createdAt as string), {
            withTime: true,
            withNumericDate: true,
          })
        : '---',
      compliance_status: [node.complianceStatus, '', ''],
    })) as TableDataType[];
  }, [data]);

  const loadMore = useCallback(
    async (next: boolean) => {
      try {
        if (next && data?.businesses.pageInfo.hasNextPage) {
          const nextCursor = data.businesses.pageInfo.endCursor;
          if (nextCursor) {
            if (currentCursor) {
              setCursorStack((prev) => [...prev, currentCursor]);
            }
            setCurrentCursor(nextCursor);
            setCount((prev) => prev + 1);
          }
        } else if (!next && count > 1) {
          if (cursorStack.length > 0) {
            const previousCursor = cursorStack[cursorStack.length - 1];
            if (previousCursor) {
              setCursorStack((prev) => prev.slice(0, -1));
              setCurrentCursor(previousCursor);
              setCount((prev) => prev - 1);
            }
          } else {
            setCurrentCursor(null);
            setCount(1);
          }
        }
      } catch (error) {
        console.error('Error loading more businesses:', error);
      }
    },
    [data, currentCursor, cursorStack, count]
  );

  const handleViewDetails = (id: string) => {
    const currentParams = searchParams.toString();
    navigate(
      `/business-profiles/details/${id}${currentParams ? `?${currentParams}` : ''}`
    );
  };

  return (
    <div className="h-screen">
      <Navbar omitSearch placeholder="" title="Business Profiles" />
      <div className="px-6 py-4">
        {loading && !data ? (
          <CustomSpinner />
        ) : data?.businesses.edges.length ? (
          <>
            <div className="flex flex-col h-full">
              <h2 className="text-2xl px-2 font-semibold" id="header">
                Business Profile List
              </h2>

              <div className="bg-white rounded-lg flex-1" id="table">
                <CommonTable
                  columns={columns}
                  data={transformedData}
                  prettify
                  viewRecord={handleViewDetails}
                />
              </div>

              <div className="mt-auto h-[60px] p-6">
                <Paginator
                  count={count}
                  pageInfo={{
                    ...data.businesses.pageInfo,
                    hasPrevPage: count > 1,
                  }}
                  loadMore={loadMore}
                />
              </div>
            </div>
          </>
        ) : (
          <EmptyState
            title="It's lonely out here"
            description="All business profiles will be displayed here"
          />
        )}
      </div>
    </div>
  );
};

export default BusinessProfiles;
