import React from 'react';

import {ReactComponent as ArrowDown} from '../../../../assets/icons/arrow-down-gray.svg';
import {ReactComponent as ArrowUp} from '../../../../assets/icons/arrow-up.svg';

interface Props {
  children: React.ReactNode;
}

const Spoiler: React.FC<Props> = ({children}) => {
  return (
    <details className="group rounded-lg rounded-t-none p-6 w-full border border-[#D6D4DE] border-t-0">
      <summary
        style={{listStyle: 'none'}}
        className="font-medium text-lg text-[#B2ABBF] hover:text-[#851EE3] cursor-pointer">
        <span className="flex items-center text-xs group-open:hidden">
          View more
          <ArrowDown className="w-3 h-3 ml-1" />
        </span>
        <span className="hidden group-open:flex flex-row items-center gap-2 text-xs">
          View less
          <ArrowUp className="w-3 h-3 ml-1" />
        </span>
      </summary>
      <div className="mt-2 max-h-0 group-open:max-h-96 transition-all duration-1000 ease-in-out">
        {children}
      </div>
    </details>
  );
};

export default Spoiler;
