import Lottie from 'react-lottie';

import animationData from '../../assets/animation/preloader.json';

const LoadingModal = () => {
  const defaultConfig = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="fixed top-0 bg-offWhite left-0 w-screen h-screen">
      <div className="fixed bg-white p-5 rounded-xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
        <Lottie options={defaultConfig} height={100} width={100} />
      </div>
    </div>
  );
};

export default LoadingModal;
