import {Button} from '@cashiaApp/web-components';
import React, {useState, useEffect} from 'react';

import {ReactComponent as EyeSlashIcon} from '../../../assets/icons/eye-slash.svg';
import {ReactComponent as EyeIcon} from '../../../assets/icons/eye.svg';
import FormInput from '../../../components/common/FormInput';
import Spinner from '../../../components/common/Spinner';
import ValidationIcon from '../../../components/common/validationIcon';
import {
  hasDigits,
  hasLowerCaseChars,
  hasSpecialChars,
  hasUpperCaseChars,
} from '../../../utils/helper/validation';
import RenderErrorMessage from '../../../utils/reusablefunctions/errorMessage';

const requiredLength = 8;

interface PasswordInputFormProps {
  onSubmit: (password: string) => void;
  buttonText: string;
  loading: boolean;
}

export default function PasswordInputForm({
  onSubmit,
  buttonText,
  loading,
}: PasswordInputFormProps) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [validations, setValidations] = useState({
    hasLowerCaseChars: false,
    hasUpperCaseChars: false,
    hasDigits: false,
    hasSpecialChars: false,
    hasRequiredLength: false,
    passwordsMatch: false,
  });

  useEffect(() => {
    const allValid = Object.values(validations).every(Boolean);
    setIsValid(allValid);
  }, [validations]);

  const handleChange = (
    field: 'password' | 'confirmPassword',
    text: string
  ) => {
    if (field === 'password') {
      setValidations((currentValidations) => ({
        ...currentValidations,
        hasDigits: hasDigits(text),
        hasSpecialChars: hasSpecialChars(text),
        hasLowerCaseChars: hasLowerCaseChars(text),
        hasUpperCaseChars: hasUpperCaseChars(text),
        hasRequiredLength: text.length >= requiredLength,
        passwordsMatch: confirmPassword.trim() === text.trim(),
      }));
      setPassword(text);
    } else {
      setConfirmPassword(text);
      setValidations((currentValidations) => ({
        ...currentValidations,
        passwordsMatch: password.trim() === text.trim(),
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isValid && !loading) {
      onSubmit(password.trim());
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormInput
        className="w-[440px] mb-2"
        label="Password"
        required
        type={showPassword ? 'text' : 'password'}
        onChange={(text) => handleChange('password', text)}
        rightElement={
          <div
            className="p-2 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <EyeIcon className="w-5 h-5 text-gray-700" />
            ) : (
              <EyeSlashIcon className="w-5 h-5 text-gray-700" />
            )}
          </div>
        }
      />

      <div className="flex gap-16 mt-[-14px] mb-6">
        <div className="">
          <div className="flex items-center gap-1">
            <ValidationIcon
              condition={validations.hasLowerCaseChars}
              className="w-4 h-4"
            />
            <p className="text-sm text-textGrey">Lowercase Characters</p>
          </div>
          <div className="flex items-center gap-1">
            <ValidationIcon
              condition={validations.hasDigits}
              className="w-4 h-4"
            />
            <p className="text-sm text-textGrey">Number</p>
          </div>
          <div className="flex items-center gap-1">
            <ValidationIcon
              condition={validations.hasSpecialChars}
              className="w-4 h-4"
            />
            <p className="text-sm text-textGrey">Special Characters</p>
          </div>
        </div>
        <div>
          <div className="flex items-center gap-1">
            <ValidationIcon
              condition={validations.hasUpperCaseChars}
              className="w-4 h-4"
            />
            <p className="text-sm text-textGrey">Uppercase Characters</p>
          </div>
          <div className="flex items-center gap-1">
            <ValidationIcon
              condition={validations.hasRequiredLength}
              className="w-4 h-4"
            />
            <p className="text-sm text-textGrey">8 Characters Minimum</p>
          </div>
        </div>
      </div>

      <FormInput
        className="w-[440px]"
        label="Re-enter Password"
        required
        type={showConfirmPassword ? 'text' : 'password'}
        onChange={(text) => handleChange('confirmPassword', text)}
        error={
          <RenderErrorMessage
            error={
              confirmPassword && !validations.passwordsMatch
                ? "Passwords don't match"
                : undefined
            }
          />
        }
        rightElement={
          <div
            className="p-2 cursor-pointer"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
            {showConfirmPassword ? (
              <EyeIcon className="w-5 h-5 text-gray-700" />
            ) : (
              <EyeSlashIcon className="w-5 h-5 text-gray-700" />
            )}
          </div>
        }
      />

      <Button
        type="submit"
        disabled={!isValid || loading}
        className="w-[440px] h-[44px] font-semibold mt-3 text-white text-sm bg-surfacePurple hover:bg-mediumPurple rounded-lg">
        {loading ? <Spinner className="fill-white" /> : buttonText}
      </Button>
    </form>
  );
}
