import {ReactComponent as MastercardIcon} from '../../assets/icons/MasterCard.svg';
import {ReactComponent as MpesaIcon} from '../../assets/icons/MpesaIcon.svg';
import {ReactComponent as VisaCardIcon} from '../../assets/icons/VisaCard.svg';
import {ReactComponent as DefaultCardIcon} from '../../assets/icons/default_card.svg';
import {ReactComponent as DefaultBankIcon} from '../../assets/logos/default_bank.svg';
import {CardIssuer, PaymentProvider} from '../../generated';

const getPaymentIcon = (method: string, cardIssuer?: CardIssuer | null) => {
  if (method === 'CARD' && cardIssuer) {
    switch (cardIssuer) {
      case CardIssuer.Visa:
        return <VisaCardIcon />;
      case CardIssuer.Mastercard:
        return <MastercardIcon />;
      default:
        return <DefaultCardIcon />;
    }
  }
  if (method === 'MPESA') {
    return <MpesaIcon />;
  }
  return <MpesaIcon />;
};

export const getTransationTypeIcon = (
  mode: PaymentProvider | null | undefined,
  cardIssuer?: CardIssuer | null
) => {
  if (!mode) return <DefaultCardIcon />;
  switch (mode) {
    case PaymentProvider.Mpesa:
      return <MpesaIcon />;
    case PaymentProvider.Card:
      return getCardIssuerCard(cardIssuer);
    case PaymentProvider.Bank:
      return <DefaultBankIcon className="w-[32px] h-[32px]" />;
    default:
      return <DefaultCardIcon />;
  }
};

const getCardIssuerCard = (cardIssuer?: CardIssuer | null) => {
  switch (cardIssuer) {
    case CardIssuer.Mastercard:
      return <MastercardIcon />;
    case CardIssuer.Visa:
      return <VisaCardIcon />;
    default:
      return <DefaultCardIcon />;
  }
};

export const formatPaymentProvider = (
  mode: PaymentProvider | null | undefined
) => {
  if (!mode) return 'Unknown';
  switch (mode) {
    case PaymentProvider.Mpesa:
      return 'M-Pesa';
    case PaymentProvider.Card:
      return 'Card';
    case PaymentProvider.Bank:
      return 'Bank Transfer';
    case PaymentProvider.Manual:
      return 'Manual';
    case PaymentProvider.Unknown:
      return 'Unknown';
    default:
      return 'Unknown';
  }
};

export default getPaymentIcon;
