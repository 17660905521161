import {Resolvers} from '@apollo/client';
import {nanoid} from 'nanoid/non-secure';

import {
  Address,
  Business,
  BusinessCategory,
  CardIssuer,
  ComplianceStatus,
  CurrencyCode,
  OrderPaymentMode,
  OrderState,
  Refund,
  RefundConnection,
  RefundStatus,
  RootMutationTypeResolvers,
  RootQueryTypeResolvers,
  User,
  UserRole,
  UserStatus,
  VerificationIdType,
} from '../../../generated';

export const mockUser: User = {
  __typename: 'User',
  id: '123',
  firstName: 'Melissa',
  lastName: 'Mukami',
  email: 'melissamukami@gmail.com',
  phone: {
    __typename: 'PhoneNumber',
    countryCode: '+254',
    number: '123456789',
  },
  createdAt: new Date().toISOString(),
  logo: {
    __typename: 'Image',
    id: nanoid(),
    small:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
    medium:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
    large:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
  },
  emailVerifiedAt: new Date().toISOString(),
  roles: [UserRole.Merchant],
  status: UserStatus.Active,
  businesses: [
    {
      address: {
        __typename: 'Address',
        buildingName: 'Sunset Tower',
        id: 'address-001',
        location: {
          __typename: 'Location',
          coordinates: {
            __typename: 'GeoCoordinates',
            lat: 40.7128,
            long: -74.006,
          },
          formattedAddress: '123 5th Avenue, New York, NY 10001, USA',
        },
      },
      balance: [
        {
          available: 0,
          currency: CurrencyCode.Kes,
          hold: 0,
          reserved: 0,
          blocked: 0,
          total: 0,
        },
      ],
      bankAccount: {
        __typename: 'BankAccount',
        accountHolder: 'John Doe',
        accountNumber: '1234567890',
        bank: {
          __typename: 'Bank',
          id: 'bank-001',
          name: 'Chase Bank',
        },
        id: 'bankAccount-001',
      },
      category: {
        __typename: 'BusinessCategory',
        code: 'BUS001',
        id: 'category-001',
        name: 'Retail',
      },
      complianceStatus: ComplianceStatus.AwaitingSubmission,
      createdAt: '2024-01-15T10:00:00.000Z',
      description: 'A retail store specializing in electronics.',
      id: 'business-001',
      members: [],
      name: 'ElectroMart',
      owner: {
        __typename: 'BusinessOwner',
        address: {
          __typename: 'Address',
          buildingName: 'Sunset Tower',
          id: 'owner-address-001',
          location: {
            __typename: 'Location',
            coordinates: {
              __typename: 'GeoCoordinates',
              lat: 40.7128,
              long: -74.006,
            },
            formattedAddress: '123 5th Avenue, New York, NY 10001, USA',
          },
        },
        firstName: 'Jane',
        id: 'owner-001',
        idPhotos: [],
        idType: VerificationIdType.AlienCard,
        lastName: 'Doe',
      },
      phoneNumber: {
        __typename: 'PhoneNumber',
        countryCode: '+1',
        number: '555-1234',
      },
      socialMedia: {
        __typename: 'BusinessSocialMedia',
        facebook: 'https://facebook.com/electromart',
        instagram: 'https://instagram.com/electromart',
        snapchat: 'https://snapchat.com/electromart',
        tiktok: 'https://tiktok.com/electromart',
        website: 'https://electromart.com',
      },
    },
  ],
};

export const mockAdmin: User = {
  __typename: 'User',
  id: '456',
  firstName: 'Melisa',
  lastName: 'Mukami',
  email: 'melissam@gmail.com',
  phone: {
    __typename: 'PhoneNumber',
    countryCode: '+254',
    number: '123456780',
  },
  createdAt: new Date().toISOString(),
  logo: {
    __typename: 'Image',
    id: nanoid(),
    small:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
    medium:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
    large:
      'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
  },
  emailVerifiedAt: new Date().toISOString(),
  roles: [UserRole.Admin],
  status: UserStatus.Active,
  businesses: [],
};

const mockAddress: Address = {
  __typename: 'Address',
  id: 'address_123',
  buildingName: '123 Main St',
  location: {
    __typename: 'Location',
    coordinates: {
      __typename: 'GeoCoordinates',
      lat: 1.2345,
      long: 2.3456,
    },
    formattedAddress: '123 Main St, City, State, Zip Code',
  },
};

const mockBusinessCategory: BusinessCategory = {
  __typename: 'BusinessCategory',
  code: '123',
  id: 'BusinessCategory_123',
  name: 'Travel Agencies & Tour Operators',
};

const mockRefunds: RefundConnection = {
  __typename: 'RefundConnection',
  edges: [
    {
      __typename: 'RefundEdge',
      cursor: 'refund1_cursor',
      node: {
        __typename: 'Refund',
        id: '123',
        amount: {
          __typename: 'Money',
          amountInCents: 50000,
          currencyCode: CurrencyCode.Kes,
        },
        reason: 'Product defective',
        reference: 'REF-2024-001',
        order: {
          __typename: 'Order',
          id: 'order_123',
          message: 'Successful request',
          reference: 'REF-2023-001',
          cost: {
            __typename: 'Money',
            amountInCents: 790000,
            currencyCode: CurrencyCode.Kes,
          },

          paymentLink: {
            __typename: 'PaymentLink',
            id: '',
            title: '',
            cost: {
              __typename: 'Money',
              amountInCents: 790000,
              currencyCode: CurrencyCode.Kes,
            },
            reference: 'REF-2024-003',
            active: true,
            business: {
              __typename: 'Business',
              id: 'business_123',
              complianceStatus: ComplianceStatus.AwaitingSubmission,
              createdAt: new Date().toISOString(),
              description: 'This is just a mocked business profile',
              members: [mockUser],
              name: 'Erregati',
              address: mockAddress,
              balance: [
                {
                  __typename: 'BusinessBalance',
                  currency: CurrencyCode.Kes,
                  hold: 0,
                  available: 89200.48,
                  total: 120000.55,
                  reserved: 0,
                  blocked: 0,
                  message: undefined,
                },
                {
                  __typename: 'BusinessBalance',
                  currency: CurrencyCode.Usd,
                  hold: 0,
                  available: 2400.0,
                  total: 2600.0,
                  reserved: 0,
                  blocked: 0,
                  message: undefined,
                },
              ],
              bankAccount: {
                __typename: 'BankAccount',
                accountHolder: 'Mock Bank Account',
                accountNumber: '1234567890',
                bank: {
                  __typename: 'Bank',
                  id: 'Bank_123',
                  name: 'Mock Bank',
                },
                id: 'bankAccount_123',
              },
              category: mockBusinessCategory,
              owner: {
                __typename: 'BusinessOwner',
                address: mockAddress,
                firstName: 'Melissa',
                id: 'BusinessOwner_123',
                idPhotos: [],
                idType: VerificationIdType.NationalId,
                lastName: 'Mukami',
              },
              phoneNumber: {
                __typename: 'PhoneNumber',
                countryCode: '254',
                number: '722000009',
              },
              socialMedia: {
                __typename: 'BusinessSocialMedia',
                facebook: undefined,
                instagram: undefined,
                snapchat: undefined,
                tiktok: undefined,
                website: undefined,
              },
            },
          },
          paymentMode: OrderPaymentMode.Card,
          state: OrderState.Failed,
        },
        status: RefundStatus.InReview,
        createdAt: '2024-01-15T10:30:00Z',
        successfulAt: '2024-01-16T11:45:00Z',
      },
    },
    {
      __typename: 'RefundEdge',
      cursor: 'refund2_cursor',
      node: {
        __typename: 'Refund',
        id: 'refund_002',
        amount: {
          __typename: 'Money',
          amountInCents: 5000,
          currencyCode: CurrencyCode.Usd,
        },
        reason: 'Product defective',
        reference: 'REF-2024-001',
        order: {
          __typename: 'Order',
          id: 'order_123',
          message: 'Successful request',
          cost: {
            __typename: 'Money',
            amountInCents: 790000,
            currencyCode: CurrencyCode.Kes,
          },
          reference: 'REF-2024-001',
          paymentLink: {
            __typename: 'PaymentLink',
            id: '',
            title: '',
            cost: {
              __typename: 'Money',
              amountInCents: 790000,
              currencyCode: CurrencyCode.Kes,
            },
            reference: 'REF-2024-001',
            active: true,
            business: {
              __typename: 'Business',
              id: 'business_123',
              complianceStatus: ComplianceStatus.AwaitingSubmission,
              createdAt: new Date().toISOString(),
              description: 'This is just a mocked business profile',
              members: [mockUser],
              name: 'Khan Business',
              address: mockAddress,
              balance: [
                {
                  __typename: 'BusinessBalance',
                  currency: CurrencyCode.Kes,
                  hold: 0,
                  available: 89200.48,
                  total: 120000.55,
                  reserved: 0,
                  blocked: 0,
                  message: undefined,
                },
                {
                  __typename: 'BusinessBalance',
                  currency: CurrencyCode.Usd,
                  hold: 0,
                  available: 2400.0,
                  total: 2600.0,
                  reserved: 0,
                  blocked: 0,
                  message: undefined,
                },
              ],
              bankAccount: {
                __typename: 'BankAccount',
                accountHolder: 'Mock Bank Account',
                accountNumber: '1234567890',
                bank: {
                  __typename: 'Bank',
                  id: 'Bank_123',
                  name: 'Mock Bank',
                },
                id: 'bankAccount_123',
              },
              category: mockBusinessCategory,
              owner: {
                __typename: 'BusinessOwner',
                address: mockAddress,
                firstName: 'Melissa',
                id: 'BusinessOwner_123',
                idPhotos: [],
                idType: VerificationIdType.NationalId,
                lastName: 'Mukami',
              },
              phoneNumber: {
                __typename: 'PhoneNumber',
                countryCode: '254',
                number: '722000009',
              },
              socialMedia: {
                __typename: 'BusinessSocialMedia',
                facebook: undefined,
                instagram: undefined,
                snapchat: undefined,
                tiktok: undefined,
                website: undefined,
              },
            },
          },
          paymentMode: OrderPaymentMode.Card,
          state: OrderState.Failed,
        },
        status: RefundStatus.Cancelled,
        createdAt: '2024-01-15T10:30:00Z',
        successfulAt: '2024-01-16T11:45:00Z',
      },
    },
    {
      __typename: 'RefundEdge',
      cursor: 'refund1_cursor',
      node: {
        __typename: 'Refund',
        id: 'refund_003',
        amount: {
          __typename: 'Money',
          amountInCents: 5000,
          currencyCode: CurrencyCode.Usd,
        },
        reason: 'Product defective',
        reference: 'REF-2024-001',
        order: {
          __typename: 'Order',
          id: 'order_123',
          message: 'Successful request',
          cost: {
            __typename: 'Money',
            amountInCents: 790000,
            currencyCode: CurrencyCode.Kes,
          },
          reference: 'REF-2024-001',
          paymentLink: {
            __typename: 'PaymentLink',
            id: '',
            title: '',
            cost: {
              __typename: 'Money',
              amountInCents: 790000,
              currencyCode: CurrencyCode.Kes,
            },
            reference: 'REF-2024-001',
            active: true,
            business: {
              __typename: 'Business',
              id: 'business_123',
              complianceStatus: ComplianceStatus.AwaitingSubmission,
              createdAt: new Date().toISOString(),
              description: 'This is just a mocked business profile',
              members: [mockUser],
              name: 'Mock Business',
              address: mockAddress,
              balance: [
                {
                  __typename: 'BusinessBalance',
                  currency: CurrencyCode.Kes,
                  hold: 0,
                  available: 89200.48,
                  total: 120000.55,
                  reserved: 0,
                  blocked: 0,
                  message: undefined,
                },
                {
                  __typename: 'BusinessBalance',
                  currency: CurrencyCode.Usd,
                  hold: 0,
                  available: 2400.0,
                  total: 2600.0,
                  reserved: 0,
                  blocked: 0,
                  message: undefined,
                },
              ],
              bankAccount: {
                __typename: 'BankAccount',
                accountHolder: 'Mock Bank Account',
                accountNumber: '1234567890',
                bank: {
                  __typename: 'Bank',
                  id: 'Bank_123',
                  name: 'Mock Bank',
                },
                id: 'bankAccount_123',
              },
              category: mockBusinessCategory,
              owner: {
                __typename: 'BusinessOwner',
                address: mockAddress,
                firstName: 'Melissa',
                id: 'BusinessOwner_123',
                idPhotos: [],
                idType: VerificationIdType.NationalId,
                lastName: 'Mukami',
              },
              phoneNumber: {
                __typename: 'PhoneNumber',
                countryCode: '254',
                number: '722000009',
              },
              socialMedia: {
                __typename: 'BusinessSocialMedia',
                facebook: undefined,
                instagram: undefined,
                snapchat: undefined,
                tiktok: undefined,
                website: undefined,
              },
            },
          },
          paymentMode: OrderPaymentMode.Card,
          state: OrderState.Failed,
        },
        status: RefundStatus.Accepted,
        createdAt: '2024-01-15T10:30:00Z',
        successfulAt: '2024-01-16T11:45:00Z',
      },
    },
  ],
  pageInfo: {
    hasNextPage: false,
    hasPrevPage: false,
    startCursor: 'refund1_cursor',
    endCursor: 'refund3_cursor',
    entries: 3,
    pages: 1,
  },
};

const mockRefund: Refund = {
  __typename: 'Refund',
  id: '123',
  amount: {
    __typename: 'Money',
    amountInCents: 50000,
    currencyCode: CurrencyCode.Usd,
  },
  reason: 'Product defective',
  reference: 'REF-2024-001',
  order: {
    __typename: 'Order',
    card: {
      __typename: 'Card',
      number: '23456797656789',
      issuer: CardIssuer.Mastercard,
    },
    id: 'order_123',
    message: 'Request Failed',
    cost: {
      __typename: 'Money',
      amountInCents: 790000,
      currencyCode: CurrencyCode.Kes,
    },
    reference: 'REF-2024-001',
    paymentLink: {
      __typename: 'PaymentLink',
      id: '',
      title: '',
      cost: {
        __typename: 'Money',
        amountInCents: 790000,
        currencyCode: CurrencyCode.Kes,
      },
      reference: 'REF-2024-001',
      active: true,
      business: {
        __typename: 'Business',
        id: 'business_123',
        complianceStatus: ComplianceStatus.AwaitingSubmission,
        createdAt: new Date().toISOString(),
        description: 'This is just a mocked business profile',
        members: [mockUser],
        name: 'Erregati',
        address: mockAddress,
        balance: [
          {
            __typename: 'BusinessBalance',
            currency: CurrencyCode.Kes,
            hold: 0,
            available: 89200.48,
            total: 120000.55,
            reserved: 0,
            blocked: 0,
            message: undefined,
          },
          {
            __typename: 'BusinessBalance',
            currency: CurrencyCode.Usd,
            hold: 0,
            available: 2400.0,
            total: 2600.0,
            reserved: 0,
            blocked: 0,
            message: undefined,
          },
        ],
        bankAccount: {
          __typename: 'BankAccount',
          accountHolder: 'Mock Bank Account',
          accountNumber: '1234567890',
          bank: {
            __typename: 'Bank',
            id: 'Bank_123',
            name: 'Mock Bank',
          },
          id: 'bankAccount_123',
        },
        category: mockBusinessCategory,
        owner: {
          __typename: 'BusinessOwner',
          address: mockAddress,
          firstName: 'Melissa',
          id: 'BusinessOwner_123',
          idPhotos: [],
          idType: VerificationIdType.NationalId,
          lastName: 'Mukami',
        },
        phoneNumber: {
          __typename: 'PhoneNumber',
          countryCode: '+254',
          number: '705674893',
        },
        socialMedia: {
          __typename: 'BusinessSocialMedia',
          facebook: undefined,
          instagram: undefined,
          snapchat: undefined,
          tiktok: undefined,
          website: undefined,
        },
      },
    },
    paymentMode: OrderPaymentMode.Card,
    state: OrderState.Failed,
  },
  status: RefundStatus.InReview,
  createdAt: '2024-01-15T10:30:00Z',
  successfulAt: '2024-01-16T11:45:00Z',
};

const queryResolvers: RootQueryTypeResolvers = {
  user: () => {
    return mockUser;
  },
  refunds: () => {
    return mockRefunds;
  },
  refund: () => {
    return mockRefund;
  },
  business: async (_, {id}): Promise<Business> => {
    return {
      __typename: 'Business',
      id: id || 'business_123',
      complianceStatus: ComplianceStatus.AwaitingSubmission,
      createdAt: new Date().toISOString(),
      description: 'This is just a mocked business profile',
      members: [mockUser],
      name: 'Mock Business',
      address: mockAddress,
      balance: [
        {
          __typename: 'BusinessBalance',
          currency: CurrencyCode.Kes,
          hold: 0,
          available: 89200.48,
          total: 120000.55,
          reserved: 0,
          blocked: 0,
          message: undefined,
        },
        {
          __typename: 'BusinessBalance',
          currency: CurrencyCode.Usd,
          hold: 0,
          available: 2400.0,
          total: 2600.0,
          reserved: 0,
          message: undefined,
          blocked: 0,
        },
      ],
      bankAccount: {
        __typename: 'BankAccount',
        accountHolder: 'Mock Bank Account',
        accountNumber: '1234567890',
        bank: {
          __typename: 'Bank',
          id: 'Bank_123',
          name: 'Mock Bank',
        },
        id: 'bankAccount_123',
      },
      category: mockBusinessCategory,
      owner: {
        __typename: 'BusinessOwner',
        address: mockAddress,
        firstName: 'Melissa',
        id: 'BusinessOwner_123',
        idPhotos: [],
        idType: VerificationIdType.NationalId,
        lastName: 'Mukami',
      },
      phoneNumber: {
        __typename: 'PhoneNumber',
        countryCode: '254',
        number: '722000009',
      },
      socialMedia: {
        __typename: 'BusinessSocialMedia',
        facebook: undefined,
        instagram: undefined,
        snapchat: undefined,
        tiktok: undefined,
        website: undefined,
      },
    };
  },
};

const mutationResolvers: RootMutationTypeResolvers = {
  updatePassword: () => {
    return true;
  },
};

const resolvers: Resolvers = {
  Query: {...queryResolvers},
  Mutation: {...mutationResolvers},
};

export default resolvers;
