import React from 'react';
import {useNavigate} from 'react-router-dom';

import ImgWithText from './ImgWithText';
import {StatusBadge} from './StatusBadge';
import More from '../../assets/icons/more_icon.svg';
import {isArrayAtKey} from '../../utils/helpers';
import {TableColumnType, TableDataType} from '../../utils/types/table.types';

type Props = {
  columns: TableColumnType[];
  data: TableDataType[];
  prettify?: boolean;
  openModal?: (row: TableDataType) => void;
  viewRecord?: (id: string) => void;
  textstyle?: string;
};

const CommonTable = ({
  columns,
  data,
  prettify,
  openModal,
  viewRecord,
  textstyle,
}: Props) => {
  const navigate = useNavigate();
  const formatColumnName = (name: TableColumnType) => {
    if (name.includes('_')) return name.split('_').join(' ');
    if (name === 'identificationNumber') return 'National ID';
    return name;
  };

  const shortenTxt = (txt: string, col?: TableColumnType) => {
    if (col === 'date_created' || col === 'date' || col === 'activity') {
      return txt;
    }
    if ((col === 'category' || col === 'owner_name') && txt?.length > 18) {
      return `${txt.substring(0, 12)}...`;
    }
    if (txt?.length > 20) return `${txt.substring(0, 15)}...`;
    return txt;
  };

  return data.length ? (
    <table
      className="w-full table-auto text-left text-base text-greyscale"
      id="table">
      <thead className="mb-3 border-b border-cashia-grey font-semibold text-darkPurple uppercase leading-5">
        <tr
          className={`${prettify ? 'rounded-t-2xl border-none' : 'border-b'} border-cashia-grey`}
          id="table-row">
          {columns.map((column, key) => (
            <th
              className={`${prettify && key === 0 ? 'pl-2' : ''} ${prettify ? 'py-5' : ''} py-3`}
              key={key}>
              <p className="flex gap-1 font-medium">
                {formatColumnName(column)}
              </p>
            </th>
          ))}
          <th />
        </tr>
      </thead>
      <tbody className="font-medium">
        {data.map((row: TableDataType, rowKey) => (
          <tr
            key={rowKey}
            id={`table-row-${rowKey}`}
            onClick={() => row?.id && viewRecord?.(row.id)}
            className="border-b border-cashia-grey py-5 leading-5 hover:bg-faintGrey cursor-pointer">
            {columns.map((col, colKey) => (
              <td
                key={colKey}
                id={`table-col-${colKey}`}
                className={`${prettify && colKey === 0 ? 'pl-2' : ''} py-3`}>
                {col === 'status' ||
                col === 'compliance_status' ||
                col === 'payment_status' ||
                col === 'refund_status' ||
                col === 'payout_status' ? (
                  <StatusBadge status={row[col]?.[0] || ''} type={col} />
                ) : col === 'activity' ? (
                  <div>
                    <span> {row[col]?.[0]} </span>
                    <span> {row[col]?.[1]} </span>
                    <span className="bg-darkGrey py-2 px-1 rounded-[5px] text-sm font-semibold">
                      {row[col]?.[2]}
                    </span>
                  </div>
                ) : col === 'actioned_by' ? (
                  <span
                    className="text-surfacePurple cursor-pointer hover:opacity-[0.6]"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/users/user-details/${row[col]?.[1]}`);
                    }}>
                    {row[col]?.[0]}
                  </span>
                ) : isArrayAtKey(row, col) ? (
                  <ImgWithText
                    variant="square"
                    text={
                      React.isValidElement(row[col]?.[0])
                        ? row[col]?.[0]
                        : shortenTxt(row[col]?.[0] || '')
                    }
                    subText={
                      React.isValidElement(row[col]?.[1])
                        ? row[col]?.[1]
                        : shortenTxt(row[col]?.[1] || '')
                    }
                  />
                ) : col === 'original_payment' ? (
                  <span
                    className="text-surfacePurple cursor-pointer hover:opacity-[0.6]"
                    id="originalPayment"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/payments/payment-details-r/${row[col]?.replace('#', '')}`
                      );
                    }}>
                    {row[col]}
                  </span>
                ) : (
                  <div className={textstyle || ''}>
                    {shortenTxt(row[col] as string, col)}
                  </div>
                )}
              </td>
            ))}
            <td className="pt-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  openModal?.(row);
                }}>
                <img src={More} alt="more" className="cursor-pointer" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <></>
  );
};

export default CommonTable;
