import {ApolloError} from '@apollo/client';
import {
  Button,
  Modal,
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalTitle,
  ModalTrigger,
  toast,
} from '@cashiaApp/web-components';
import React, {useEffect, useMemo, useState} from 'react';

import UpdatePassword from './UpdatePassword';
import {ReactComponent as ResetPwdLock} from '../../assets/icons/resetPasswordLock .svg';
import CustomPhoneInput from '../../components/common/CustomPhoneInput';
import CustomSpinner from '../../components/common/CustomSpinner';
import EditButton from '../../components/common/EditButton';
import FormInput from '../../components/common/FormInput';
import Navbar from '../../components/common/Navbar';
import Spinner from '../../components/common/Spinner';
import {
  MeDocument,
  MeQuery,
  useMeQuery,
  UserRole,
  useUpdateUserDetailsMutation,
} from '../../generated';
import {useAuth} from '../../utils/auth';
import {hasDigits} from '../../utils/helper/validation';
import RenderErrorMessage from '../../utils/reusablefunctions/errorMessage';
import validateKenyanPhoneNumber from '../../utils/validatePhoneNumber';

type FormValues = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  role?: UserRole | string;
};

interface FormErrors {
  [key: string]: string;
}

const MyProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState<FormValues>();
  const [errors, setErrors] = useState<FormErrors>({});
  const [updateUser, {loading: updateLoading}] = useUpdateUserDetailsMutation();
  const {isAuthenticated} = useAuth();
  const {data: user, loading, refetch} = useMeQuery({skip: !isAuthenticated});
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const initialValues = useMemo(
    () => ({
      firstName: user?.me?.firstName || '',
      lastName: user?.me?.lastName || '',
      phoneNumber: user?.me?.phone?.number || '',
    }),
    [user]
  );

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleChange = (field: string, value: string) => {
    const _errors: FormErrors = {...errors};
    setValues({
      ...values,
      [field]: value,
    });
    switch (field) {
      case 'phoneNumber':
        if (value?.trim() && !validateKenyanPhoneNumber(value.trim())) {
          _errors.phoneNumber = 'Phone number is invalid';
        } else {
          _errors.phoneNumber = '';
        }
        if (value === '') {
          _errors.phoneNumber = 'Phone number is required';
        }
        break;
      case 'firstName':
        if (value === '') {
          _errors.firstName = 'First name is required';
        } else if (value && hasDigits(value)) {
          _errors.firstName = 'First name can only contain letters';
        } else {
          _errors.firstName = '';
        }
        break;
      case 'lastName':
        if (value === '') {
          _errors.lastName = 'Last name is required';
        } else if (value && hasDigits(value)) {
          _errors.lastName = 'First name can only contain letters';
        } else {
          _errors.lastName = '';
        }
        break;
      default:
        break;
    }
    setErrors(_errors);
  };

  const handleSubmit = () => {
    if (!values || Object.values(errors).filter(Boolean).length > 0) return;
    updateUser({
      variables: {
        input: {
          ...(initialValues.firstName !== values.firstName && {
            firstName: values.firstName,
          }),
          ...(initialValues.lastName !== values.lastName && {
            lastName: values.lastName,
          }),

          ...(initialValues.phoneNumber !== values.phoneNumber && {
            phone: {
              number: values.phoneNumber || '',
              countryCode: user?.me?.phone?.countryCode || '',
            },
          }),
        },
      },
      update: (cache, {data: updatedUser}) => {
        if (!updatedUser) return;
        const cached = cache.readQuery<MeQuery>({
          query: MeDocument,
        });
        cache.writeQuery({
          query: MeDocument,
          data: {
            ...cached,
            me: updatedUser,
          },
        });
      },
    })
      .then((res) => {
        if (res.data?.updateUser) {
          toast.success('Profile updated successfully!');
          setIsEditing(false);
          void refetch();
        }
      })
      .catch((e: ApolloError) => {
        toast.error(`Error: ${e.message}`);
        setIsEditing(true);
      });
  };

  return (
    <>
      <Navbar title="Settings" omitSearch />

      {loading ? (
        <CustomSpinner />
      ) : (
        <div className="h-[calc(100vh-64px)] overflow-y-auto px-2 py-6 bg-displayGrey ">
          <div className="m-6 border p-8 flex-col border-dividerGrey rounded-lg h-auto flex">
            <div className="flex gap-40 justify-between items-center w-full mb-6">
              <h1 className="font-semibold text-2xl">My Profile</h1>
              {isEditing ? (
                <Modal>
                  <div className="flex gap-3">
                    <ModalTrigger asChild>
                      <Button
                        disabled={
                          Object.values(errors).filter(Boolean).length > 0
                        }
                        className="w-[134px] h-[44px] whitespace-nowrap border-dividerGrey font-semibold border text-base rounded-lg bg-surfacePurple hover:bg-mediumPurple text-white">
                        Save Changes
                      </Button>
                    </ModalTrigger>

                    <Button
                      onClick={() => {
                        setValues(initialValues);
                        setIsEditing(false);
                        setErrors({});
                      }}
                      className="w-[77px] h-[44px] border-dividerGrey font-semibold border text-base hover:shadow-sm hover:border-1
                      hover:border-mediumPurple rounded-lg bg-faintGrey text-fontGrey">
                      Cancel
                    </Button>
                  </div>
                  <ModalContent>
                    <ModalTitle>Save Changes</ModalTitle>
                    <ModalDescription>
                      Please review your updates to ensure all information is
                      correct. This will permanently update your account with
                      new details.
                    </ModalDescription>
                    <ModalDescription>
                      Would you like to proceed?
                    </ModalDescription>
                    <div className="flex justify-between">
                      <ModalClose asChild>
                        <Button
                          className="border border-dividerGrey hover:border-1
        hover:border-mediumPurple bg-faintGrey text-black rounded-lg p-2.5">
                          Cancel
                        </Button>
                      </ModalClose>
                      <ModalClose asChild>
                        <Button
                          className="whitespace-nowrap border-dividerGrey font-semibold border text-base rounded-lg bg-surfacePurple hover:bg-mediumPurple text-white"
                          onClick={handleSubmit}>
                          {updateLoading ? (
                            <Spinner className="fill-white" />
                          ) : (
                            'Save Changes'
                          )}
                        </Button>
                      </ModalClose>
                    </div>
                  </ModalContent>
                </Modal>
              ) : (
                <EditButton onButtonClick={() => setIsEditing(true)} />
              )}
            </div>

            <form onSubmit={(e) => e.preventDefault()}>
              <div className="flex text-grey gap-6">
                <div id="firstName" className="w-[480px]">
                  <FormInput
                    label="First Name"
                    required
                    className="w-full"
                    disabled={!isEditing}
                    name="firstName"
                    maxLength={60}
                    value={values?.firstName?.trim() || ''}
                    onChange={(firstName) =>
                      handleChange('firstName', firstName)
                    }
                    error={<RenderErrorMessage error={errors.firstName} />}
                  />
                </div>
                <div id="lastName" className="w-[480px]">
                  <FormInput
                    label="Last Name"
                    name="lastName"
                    required
                    maxLength={60}
                    className="w-full"
                    disabled={!isEditing}
                    value={values?.lastName?.trim() || ''}
                    onChange={(lastName) => handleChange('lastName', lastName)}
                    error={<RenderErrorMessage error={errors.lastName} />}
                  />
                </div>
              </div>
              <div className=" flex text-grey gap-6">
                <div id="phoneNumber">
                  <label className="sm:font-medium md:font-medium text-textGrey sm:text-[15px] md:text-[16px] text-sm font-bold flex mb-1 ml-1 items-center">
                    Phone Number *
                  </label>

                  <CustomPhoneInput
                    countryCode={user?.me?.phone?.countryCode || ''}
                    phoneNumber={values?.phoneNumber || ''}
                    isDisabled={!isEditing}
                    onChange={(_, phone) => {
                      handleChange('phoneNumber', phone);
                    }}
                    error={<RenderErrorMessage error={errors.phoneNumber} />}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="m-6 border p-8 flex-col border-dividerGrey rounded-lg h-auto flex">
            <h1 className="font-semibold text-2xl mb-6">Authentication</h1>

            <div className="flex items-center justify-between">
              <div>
                <p className="text-lg text-grey font-medium">Password</p>
              </div>
              <Button
                onClick={() => setShowPasswordModal(true)}
                className="px-4 py-2 h-[44px] whitespace-nowrap border border-dividerGrey font-semibold rounded-lg bg-faintGrey hover:border-mediumPurple text-fontGrey flex items-center gap-2">
                Update Password
                <ResetPwdLock />
              </Button>
            </div>
          </div>
        </div>
      )}
      <UpdatePassword
        isOpen={showPasswordModal}
        onClose={() => setShowPasswordModal(false)}
      />
    </>
  );
};

export default MyProfile;
