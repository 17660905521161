import {
  Button,
  Modal,
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalTitle,
  ModalTrigger,
  toast,
  Input,
} from '@cashiaApp/web-components';
import React, {useEffect, useState} from 'react';

import {ReactComponent as DeactivateIcon} from '../../../assets/icons/deactivateIcon.svg';
import {ReactComponent as ReactivateIcon} from '../../../assets/icons/reactivateIcon.svg';
import {ReactComponent as Tick} from '../../../assets/icons/tick_green.svg';
import CustomSpinner from '../../../components/common/Spinner';
import {
  User,
  useRequestPasswordResetOtpMutation,
  useResendSetAdminPasswordOtpMutation,
  UserStatus,
  useUpdateUserDetailsMutation,
} from '../../../generated';
import {cn} from '../../../utils/reusablefunctions';

type UserActionData = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'email' | 'status' | 'phone' | 'roles'
>;
export interface UserActionSectionProps {
  user: UserActionData;
  isAdmin?: boolean;
  entityType?: 'Admin' | 'Merchant';
}

const UserActionSection: React.FC<UserActionSectionProps> = ({
  user,
  isAdmin = false,
  entityType = 'User',
}) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const isDeactivated = user.status === UserStatus.Deactivated;
  const isCreated = user.status === UserStatus.Created;
  const isActive = user.status === UserStatus.Active;
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const isInitialPasswordSetup =
    user.status === UserStatus.Created && isAdmin === true;
  const [resetPasswordMutation] = useResendSetAdminPasswordOtpMutation();
  const [requestPasswordResetMutation] = useRequestPasswordResetOtpMutation();
  const [updateUserDetailsMutation, {loading: statusLoading}] =
    useUpdateUserDetailsMutation();

  const handleStatusToggle = async () => {
    setReason('');
    const newStatus = isActive ? UserStatus.Deactivated : UserStatus.Active;
    try {
      const response = await updateUserDetailsMutation({
        variables: {
          input: {
            id: user.id,
            status: newStatus,
            roles: user.roles,
          },
        },
      });
      if (response?.data) {
        if (newStatus === UserStatus.Deactivated) {
          setShowDeactivateModal(true);
        } else if (newStatus === UserStatus.Active) {
          setShowReactivateModal(true);
        }
      }
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  };

  const clearReason = () => {
    setReason('');
  };

  const handlePasswordAction = async () => {
    if (!user.email) {
      toast.error('User email is missing');
      return;
    }

    try {
      setLoading(true);

      if (isInitialPasswordSetup) {
        const response = await resetPasswordMutation({
          variables: {
            email: user.email,
          },
        });

        if (response.data?.resendSetAdminPasswordOTP) {
          setShowSuccessModal(true);
        } else {
          toast.error(
            'We couldn’t resend the activation email. Please try again later.'
          );
        }
      } else {
        const response = await requestPasswordResetMutation({
          variables: {
            email: user.email,
          },
        });

        if (response.data?.requestPasswordResetOTP) {
          setShowSuccessModal(true);
        } else {
          toast.error('Failed to send password reset email');
        }
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        toast.error(`Error: ${error.message}`);
      } else {
        toast.error('Something went wrong');
      }
      console.error('Error sending password email:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (showSuccessModal || showDeactivateModal || showReactivateModal) {
      timer = setTimeout(() => {
        setShowSuccessModal(false);
        setShowDeactivateModal(false);
        setShowReactivateModal(false);
      }, 3000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [showSuccessModal, showDeactivateModal, showReactivateModal]);

  const actionButtonText = isInitialPasswordSetup
    ? 'Resend Activation Email'
    : 'Reset Password';

  const successMessageText = isInitialPasswordSetup ? (
    <p>Activation email has been sent to {user.email}</p>
  ) : (
    <p>An email has been sent to the user</p>
  );

  const actionTitle = isInitialPasswordSetup
    ? 'Resend Activation Email'
    : 'Reset Password';

  const passwordActionDescription = isInitialPasswordSetup
    ? `Resend the activation email if the previous link expired or wasn’t received`
    : `Sends a password reset email to the user. They will be prompted to create a new password`;

  const deactivateDescription = isAdmin
    ? `This action deactivates this user. They will not be able to access the Admin platform`
    : `This action deactivates this merchant. They will not be able to transact on the platform`;

  const reactivateDescription = isAdmin
    ? `This action reactivates this user. They will be able to access the Admin platform`
    : `This action reactivates this merchant. They will be able to transact on the platform`;

  const title = isAdmin ? `User` : `Account`;

  const fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim();

  return (
    <div className="border p-8 m-6 border-dividerGrey rounded-lg flex flex-col">
      {!isDeactivated && (
        <div>
          <h3 className="font-semibold text-xl">{actionTitle}</h3>
          <div className="flex items-center justify-between">
            <p className="text-textGrey font-medium">
              {passwordActionDescription}
            </p>
            <Modal>
              <ModalTrigger asChild>
                <Button className="h-12 w-[215px] bg-surfacePurple hover:bg-mediumPurple text-white rounded-lg font-semibold px-3 text-base">
                  {actionButtonText}
                </Button>
              </ModalTrigger>
              <ModalContent>
                <ModalTitle>{actionTitle}</ModalTitle>
                <ModalDescription>
                  {isInitialPasswordSetup ? (
                    <p>
                      We’ll send a new activation email to{' '}
                      <strong>{fullName}</strong>. They’ll be guided to create
                      their password and activate their account
                    </p>
                  ) : (
                    <p>
                      This will send a password reset email to{' '}
                      <strong>{fullName}</strong>. Are you sure you want to
                      proceed?
                    </p>
                  )}
                </ModalDescription>
                <div className="flex justify-between mt-6">
                  <ModalClose asChild>
                    <Button className="border border-dividerGrey bg-faintGrey hover:border-1 hover:border-mediumPurple text-black rounded-lg px-6">
                      Cancel
                    </Button>
                  </ModalClose>
                  <ModalClose asChild>
                    <Button
                      className="bg-surfacePurple hover:bg-mediumPurple text-white rounded-lg font-semibold px-6"
                      onClick={handlePasswordAction}>
                      {loading ? (
                        <CustomSpinner fillColor="white" />
                      ) : (
                        actionButtonText
                      )}
                    </Button>
                  </ModalClose>
                </div>
              </ModalContent>
            </Modal>
          </div>
        </div>
      )}

      {!isCreated && (
        <div className="mt-1">
          <h3 className="font-semibold text-xl">
            {isActive ? `Deactivate ${title}` : `Reactivate ${title}`}
          </h3>
          <div className="flex items-center justify-between">
            <p className="text-textGrey font-medium">
              {isActive ? deactivateDescription : reactivateDescription}
            </p>
            <Modal>
              <ModalTrigger asChild>
                <Button
                  className={cn(
                    'h-12 w-[215px] rounded-lg font-semibold px-3 text-base',
                    isActive
                      ? 'bg-brightRed hover:bg-brightRed text-white'
                      : 'bg-faintGrey text-black'
                  )}>
                  {isActive ? `Deactivate ${title}` : `Reactivate ${title}`}
                </Button>
              </ModalTrigger>
              <ModalContent>
                <ModalTitle>
                  {isActive ? `Deactivate ${title}` : `Reactivate ${title}`}
                </ModalTitle>
                <ModalDescription>
                  {isActive ? (
                    <p>
                      This action will <strong>{fullName}’s </strong> account.
                      They will be logged out immediately and will no longer
                      have access to their account. Are you sure you want to
                      deactivate this account ?
                    </p>
                  ) : (
                    <p>
                      This action will reactivate <strong>{fullName}’s </strong>{' '}
                      account. Are you sure you want to reactivate this account
                      ?
                    </p>
                  )}

                  {isActive ? (
                    <Input
                      className="mt-2 w-full pb-[150px] "
                      placeholder="Give a reason here..."
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  ) : (
                    ''
                  )}
                </ModalDescription>
                <div className="flex justify-between mt-6">
                  <ModalClose asChild>
                    <Button
                      className="border border-dividerGrey bg-faintGrey hover:border-1 hover:border-mediumPurple text-black rounded-lg px-6"
                      onClick={clearReason}>
                      Cancel
                    </Button>
                  </ModalClose>
                  <ModalClose asChild>
                    <Button
                      disabled={isActive && !reason}
                      className={cn(
                        'text-white rounded-lg font-semibold px-3',
                        isActive
                          ? 'bg-brightRed hover:bg-brightRed'
                          : 'bg-surfacePurple hover:bg-surfacePurple',
                        isActive && !reason && 'bg-borderGrey text-grey'
                      )}
                      onClick={handleStatusToggle}>
                      {statusLoading ? (
                        <CustomSpinner fillColor="white" />
                      ) : isActive ? (
                        `Deactivate ${title}`
                      ) : (
                        `Reactivate ${title}`
                      )}
                    </Button>
                  </ModalClose>
                </div>
              </ModalContent>
            </Modal>
          </div>
        </div>
      )}

      <Modal open={showSuccessModal} onOpenChange={setShowSuccessModal}>
        <ModalContent>
          <div className="flex flex-col items-center p-4">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
              <Tick />
            </div>
            <ModalTitle className="text-center font-semibold text-2xl">
              {successMessageText}
            </ModalTitle>
          </div>
        </ModalContent>
      </Modal>

      <Modal open={showDeactivateModal} onOpenChange={setShowDeactivateModal}>
        <ModalContent>
          <div className="flex flex-col items-center p-4">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
              <DeactivateIcon />
            </div>
            <ModalTitle className="text-center font-semibold text-2xl">
              {fullName}’s account has been deactivated.
            </ModalTitle>
          </div>
        </ModalContent>
      </Modal>

      <Modal open={showReactivateModal} onOpenChange={setShowReactivateModal}>
        <ModalContent>
          <div className="flex flex-col items-center p-4">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
              <ReactivateIcon />
            </div>
            <ModalTitle className="text-center font-semibold text-2xl">
              {fullName}’s account has been reactivated.
            </ModalTitle>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default UserActionSection;
