import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import CustomSpinner from '../../components/common/CustomSpinner';
import EmptyState from '../../components/common/EmptyState';
import {HeaderStats} from '../../components/common/Header';
import Navbar from '../../components/common/Navbar';
import Paginator, {PAGE_SIZE} from '../../components/common/Paginator';
import CommonTable from '../../components/common/table';
import useQueryParamState from '../../components/common/useQueryParamState';
import {useGetUsersQuery, UserRole} from '../../generated';
import formatDate from '../../utils/reusablefunctions/formatDate';
import {TableColumnType, TableDataType} from '../../utils/types/table.types';
import {useUserAuth} from '../../utils/user';

const columns: TableColumnType[] = [
  'full_name',
  'email',
  'phone_number',
  'date_created',
  'status',
];

const AccountsPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {getParamsState, updateQueryParams} = useQueryParamState();
  const paramsState = getParamsState();
  const {roleAccess} = useUserAuth();
  const [count, setCount] = useState<number>(paramsState.page);
  const [currentCursor, setCurrentCursor] = useState<string | null>(
    paramsState.cursor
  );
  const [cursorStack, setCursorStack] = useState<string[]>(
    paramsState.cursorStack || []
  );

  useEffect(() => {
    updateQueryParams({
      page: count,
      cursor: currentCursor,
      cursorStack,
    });
  }, [count, currentCursor, cursorStack, updateQueryParams]);

  const {data, loading} = useGetUsersQuery({
    variables: {
      input: {
        roles: [UserRole.Merchant],
        cursor: {
          first: PAGE_SIZE,
          after: currentCursor,
        },
      },
    },
  });

  const transformedData = React.useMemo(() => {
    if (!data?.users.edges) return [];

    return data.users.edges.map(({node}) => ({
      id: node.id || undefined,
      full_name: [`${node.firstName || ''} ${node.lastName || ''}`.trim(), ''],
      email: node.email || undefined,
      phone_number: node.phone
        ? `${node.phone.countryCode}${node.phone.number}`
        : undefined,
      date_created: node.createdAt
        ? formatDate(new Date(node.createdAt as string), {
            withTime: true,
            withNumericDate: true,
          })
        : '---',
      status: [node.status, '', ''],
    })) as TableDataType[];
  }, [data]);

  const loadMore = useCallback(
    async (next: boolean) => {
      try {
        if (next && data?.users.pageInfo.hasNextPage) {
          const nextCursor = data.users.pageInfo.endCursor;
          if (nextCursor) {
            if (currentCursor) {
              setCursorStack((prev) => [...prev, currentCursor]);
            }
            setCurrentCursor(nextCursor);
            setCount((prev) => prev + 1);
          }
        } else if (!next && count > 1) {
          if (cursorStack.length > 0) {
            const previousCursor = cursorStack[cursorStack.length - 1];
            if (previousCursor) {
              setCursorStack((prev) => prev.slice(0, -1));
              setCurrentCursor(previousCursor);
              setCount((prev) => prev - 1);
            }
          } else {
            setCurrentCursor(null);
            setCount(1);
          }
        }
      } catch (error) {
        console.error('Error loading more accounts:', error);
      }
    },
    [data, currentCursor, cursorStack, count]
  );

  const handleAddAccount = () => {
    navigate('/accounts/add-account');
  };

  const handleViewDetails = (id: string) => {
    const currentParams = searchParams.toString();
    navigate(
      `/accounts/account-details/${id}${currentParams ? `?${currentParams}` : ''}`
    );
  };

  return (
    <div className="h-screen">
      <Navbar omitSearch title="Accounts" />
      <div className="px-6 py-4">
        {loading && !data ? (
          <CustomSpinner />
        ) : data?.users.edges.length ? (
          <>
            <HeaderStats
              action={
                roleAccess.canCreateAccount
                  ? {
                      label: 'Add New Account',
                      onClick: handleAddAccount,
                    }
                  : undefined
              }
            />

            <div className="flex flex-col h-full">
              <h2 className="text-2xl px-2 font-semibold" id="header">
                Accounts List
              </h2>

              <div className="bg-white rounded-lg" id="table">
                <CommonTable
                  columns={columns}
                  data={transformedData}
                  prettify
                  viewRecord={handleViewDetails}
                />
              </div>

              <div className="mt-auto h-[60px] p-6">
                <Paginator
                  count={count}
                  pageInfo={{
                    ...data.users.pageInfo,
                    hasPrevPage: count > 1,
                    hasNextPage: data.users.pageInfo.hasNextPage,
                    endCursor: data.users.pageInfo.endCursor,
                    startCursor: data.users.pageInfo.startCursor,
                    entries: data.users.pageInfo.entries,
                    pages: data.users.pageInfo.pages,
                  }}
                  loadMore={loadMore}
                />
              </div>
            </div>
          </>
        ) : (
          <EmptyState
            title="It's lonely out here"
            description="All accounts will be displayed here"
            action={{
              label: 'Add New Account',
              onClick: handleAddAccount,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AccountsPage;
