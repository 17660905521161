import {
  Button,
  Modal,
  ModalContent,
  ModalDescription,
  ModalTitle,
} from '@cashiaApp/web-components';
import React, {useMemo, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {ReactComponent as GreyExport} from '../../assets/icons/greyExport.svg';
import {ReactComponent as DefaultBankIcon} from '../../assets/logos/default_bank.svg';
import CustomSpinner from '../../components/common/CustomSpinner';
import ImgWithText from '../../components/common/ImgWithText';
import Navbar from '../../components/common/Navbar';
import {StatusBadge} from '../../components/common/StatusBadge';
import StatusDot from '../../components/common/StatusDot';
import {PayoutMode, PayoutStatus, useGetPayoutQuery} from '../../generated';
import {groupDigits} from '../../utils/helper/validation';
import capitalizeFirstLetter from '../../utils/reusablefunctions/capitalizeFirstLetter';
import formatDate from '../../utils/reusablefunctions/formatDate';
import formatMoney from '../../utils/reusablefunctions/formatMoney';
import formatPaymentMode from '../../utils/reusablefunctions/formatPaymentMode';
import getPaymentIcon from '../../utils/reusablefunctions/getPaymentIcon';
import shortenTxt from '../../utils/reusablefunctions/shortenText';

const PayoutDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const {id} = useParams<{id: string}>();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const breadcrumbs = useMemo(() => {
    const params = searchParams.toString();
    return [
      {
        name: 'Payouts',
        location: `/payouts${params ? `?${params}` : ''}`,
      },
      {
        name: ' Payout Details',
      },
    ];
  }, [searchParams]);

  const {data, loading} = useGetPayoutQuery({
    variables: {
      id: id || '',
    },
    skip: !id,
  });

  const payoutValues = useMemo(() => {
    const isFailed = data?.payout?.status === PayoutStatus.Failed;
    const isMpesa = data?.payout?.mode === PayoutMode.Mpesa;

    return [
      {title: 'Transaction ID', value: `#${data?.payout?.reference || ''}`},
      {
        title: 'Payout Status',
        value: (
          <StatusBadge
            className="rounded-md w-[79px] h-[28px]"
            status={
              data?.payout?.status === PayoutStatus.PendingVerification
                ? 'PENDING'
                : data?.payout?.status || ''
            }
          />
        ),
      },

      ...(isFailed
        ? [
            {
              title: 'Additional Information',
              value: (
                <ImgWithText
                  subText={<GreyExport onClick={() => setShowModal(true)} />}
                  text={
                    <span className="font-semibold text-base text-fontGrey">
                      {shortenTxt(String(data?.payout?.message || ''))}
                    </span>
                  }
                />
              ),
            },
          ]
        : []),
      {
        title: 'Payout Type',
        value: (
          <ImgWithText
            subText={
              <span className="font-semibold text-base text-fontGrey">
                {data?.payout?.mode === PayoutMode.Mpesa
                  ? 'Mobile Money'
                  : formatPaymentMode(data?.payout?.mode || 'Unknown')}
              </span>
            }
          />
        ),
      },
      {
        title: isMpesa ? 'Payout Method' : 'Bank Name',
        value: (
          <ImgWithText
            subText={
              <ImgWithText
                text={
                  isMpesa ? (
                    <span>{getPaymentIcon('mpesa')}</span>
                  ) : (
                    <DefaultBankIcon className="w-[32px] h-[32px]" />
                  )
                }
                subText={
                  <span className="font-semibold text-base text-fontGrey">
                    {isMpesa
                      ? 'Mobile Money'
                      : data?.payout.bankAccount?.bank.name}
                  </span>
                }
              />
            }
          />
        ),
      },
      {
        title:
          data?.payout?.mode === PayoutMode?.BankTransfer
            ? 'Account Number'
            : 'Phone Number',
        value:
          data?.payout?.mode === PayoutMode?.BankTransfer
            ? `*** *** *** *${data?.payout?.bankAccount?.accountNumber.slice(-3)}`
            : `${data?.payout?.mobileNumber?.countryCode}
                ${groupDigits(data?.payout?.mobileNumber?.number || '')}`,
      },
      {
        title: 'Payout Date',
        value: formatDate(new Date(data?.payout?.createdAt as string), {
          withTime: true,
          withNumericDate: true,
          with24Hour: true,
          withSeconds: true,
        }),
      },
    ];
  }, [data]);

  const additionalInfo = useMemo(
    () => [
      {
        title: 'Business Name',
        value: (
          <ImgWithText
            subText={
              <GreyExport
                className="w-4 h-4 cursor-pointer"
                onClick={() =>
                  navigate(
                    `/business-profiles/details/${data?.payout?.business?.id}`
                  )
                }
              />
            }
            text={
              <span className="font-semibold text-base text-fontGrey">
                {data?.payout?.business?.name || 'N/A'}
              </span>
            }
          />
        ),
      },
    ],
    [data, navigate]
  );

  const handleViewAccount = () => {
    navigate(
      `/accounts/account-details/${data?.payout?.business?.members?.[0]?.id}`
    );
  };

  return (
    <div>
      <Navbar title="Payout Details" omitSearch breadcrumbs={breadcrumbs} />

      {loading ? (
        <CustomSpinner />
      ) : (
        <div className="h-[calc(100vh-64px)] overflow-y-auto px-2 py-6 bg-displayGrey ">
          <div className="mx-6 border border-dividerGrey p-8 rounded-lg h-[116px] flex items-center">
            <div>
              <h1 className="text-foggy font-semibold text-xs">
                Transaction ID
              </h1>
              <h1 className="text-fontGrey font-semibold text-2xl">
                #{data?.payout?.reference || ''}
              </h1>
            </div>

            <div className="w-3/5 flex flex-row justify-evenly">
              <div className="flex items-center gap-2">
                {data?.payout?.status && (
                  <StatusDot
                    status={
                      data?.payout?.status === PayoutStatus.PendingVerification
                        ? PayoutStatus.PendingVerification
                        : data?.payout?.status
                    }
                  />
                )}
                <div className="flex flex-col">
                  <h1 className="text-foggy font-semibold text-xs">
                    Payout Status
                  </h1>
                  <span className="text-fontGrey text-base font-semibold">
                    {capitalizeFirstLetter(
                      String(
                        data?.payout?.status ===
                          PayoutStatus.PendingVerification
                          ? 'PENDING'
                          : data?.payout?.status || ''
                      ).toLowerCase()
                    )}
                  </span>
                </div>
              </div>
              <div>
                <h1 className="text-foggy font-semibold text-xs">
                  Payout Date
                </h1>
                <h1 className="font-semibold text-base">
                  {formatDate(new Date(data?.payout?.createdAt as string), {
                    withTime: true,
                    withNumericDate: true,
                    with24Hour: true,
                    withSeconds: true,
                  })}
                </h1>
              </div>
            </div>
          </div>

          <div className="m-6 border border-dividerGrey p-8  rounded-lg h-auto">
            <div className="mb-6 flex justify-between">
              <h1 className="font-semibold text-2xl whitespace-nowrap">
                Payout Details
              </h1>
            </div>
            <div className="flex flex-col gap-5">
              {payoutValues.map((values) => (
                <div
                  key={values.title}
                  className="flex items-center justify-between">
                  <h1 className="text-greyscale font-medium text-base">
                    {values?.title}
                  </h1>
                  <h1 className="font-semibold text-base text-fontGrey">
                    {values?.value || ''}
                  </h1>
                </div>
              ))}
              <div className="flex items-center pt-5 border-t border-surfaceGrey justify-between">
                <h1 className="text-greyscale font-medium text-base">Fees</h1>
                <h1 className="font-semibold text-base text-fontGrey">
                  KES 100
                </h1>
              </div>
              <div className="flex items-center pt-3 border-t border-surfaceGrey justify-between">
                <h1 className="text-fontGrey font-semibold text-lg">Amount</h1>
                <h1 className="font-semibold text-2xl text-fontGrey">
                  {data?.payout?.amount?.currencyCode}{' '}
                  {data?.payout?.amount && formatMoney(data?.payout?.amount)}
                </h1>
              </div>
            </div>
          </div>
          <div className="m-6 border border-dividerGrey p-8  rounded-lg h-auto">
            <div className="mb-6 flex items-center justify-between">
              <h1 className="font-semibold text-2xl whitespace-nowrap">
                Additional Information
              </h1>
              <Button
                onClick={handleViewAccount}
                className="bg-surfacePurple hover:bg-mediumPurple">
                View Account Details
              </Button>
            </div>
            <div className="flex flex-col gap-5">
              {additionalInfo.map((values) => (
                <div
                  key={values.title}
                  className="flex items-center justify-between">
                  <h1 className="text-greyscale font-medium text-base">
                    {values?.title}
                  </h1>
                  <h1 className="font-semibold text-base text-fontGrey">
                    {values?.value || ''}
                  </h1>
                </div>
              ))}

              <div className="mb-2 flex pt-5 border-t border-surfaceGrey  justify-between">
                <h1 className="font-semibold text-xl whitespace-nowrap">
                  Account Information
                </h1>
              </div>
              {data?.payout?.business.members.map((member, index) => (
                <React.Fragment key={member.id || index}>
                  <div
                    key={member.id || index}
                    className="flex items-center border-surfaceGrey justify-between">
                    <h1 className="text-greyscale font-medium text-base">
                      Full Name
                    </h1>
                    <h1 className="font-semibold text-base text-fontGrey">
                      {member.firstName} {member.lastName}
                    </h1>
                  </div>
                  <div className="flex items-center border-surfaceGrey justify-between">
                    <h1 className="text-greyscale font-medium text-base">
                      Email
                    </h1>
                    <h1 className="font-semibold text-base text-fontGrey">
                      {member.email}
                    </h1>
                  </div>
                  <div className="flex items-center border-surfaceGrey justify-between">
                    <h1 className="text-greyscale font-medium text-base">
                      Phone Number
                    </h1>
                    <h1 className="font-semibold text-base text-fontGrey">
                      {member.phone?.countryCode}
                      {member.phone?.number}
                    </h1>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}

      <Modal onOpenChange={setShowModal} open={showModal}>
        <ModalContent className="w-[33%] pb-10 p-10 border-lightGrey">
          <ModalTitle>
            <div className="flex flex-col">
              <p className="font-semibold text-2xl text-fontGrey">
                Additional Information
              </p>
            </div>
          </ModalTitle>
          <ModalDescription>
            <div className="bg-displayGrey rounded-md h-[90px] p-4">
              {data?.payout?.message}
            </div>
          </ModalDescription>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default PayoutDetails;
