import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  toast,
} from '@cashiaApp/web-components';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as CopyrightIcon} from '../../assets/icons/copyright.svg';
import {ReactComponent as OtpVerificationIcon} from '../../assets/icons/otpVerificationIcon.svg';
import LoadingModal from '../../components/common/LoadingModal';
import RenderErrorMessage from '../../components/common/errorMessage';
import {useVerifyLoginOtpMutation} from '../../generated';
import {setTokens, useAuth} from '../../utils/auth';
import {cn} from '../../utils/reusablefunctions';
import capitalizeFirstLetter from '../../utils/reusablefunctions/capitalizeFirstLetter';

const currentYear = new Date().getFullYear();

const REGEXP_ONLY_DIGITS = '^[0-9]+$';
const OtpVerification = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const [timeLeft, setTimeLeft] = useState(60);
  const [otpSent, setOtpSent] = useState(true);
  const [value, setValue] = React.useState('');

  const navigate = useNavigate();
  const [isValid, setIsValid] = useState<null | boolean>(null);
  const {makeLoginWithPost, setIsAuthenticated} = useAuth();
  const [verifyLoginOtp, {loading, error}] = useVerifyLoginOtpMutation();

  useEffect(() => {
    if (otpSent && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [otpSent, timeLeft]);

  const validateOtp = useCallback(
    async (otpValue: string) => {
      try {
        const success = await verifyLoginOtp({
          variables: {
            code: otpValue,
          },
        });

        if (success?.data?.verifyLoginOTP) {
          const accessToken = success?.data?.verifyLoginOTP?.accessToken;
          const refreshToken = success?.data?.verifyLoginOTP?.refreshToken;
          setIsValid(true);
          toast.success('OTP verified successfully!');
          setIsAuthenticated(true);
          setTokens(accessToken, refreshToken);
          navigate('/dashboard');
        }
      } catch (_error) {
        console.error('OTP validation error:', _error);
      }
    },
    [navigate, setIsAuthenticated, verifyLoginOtp]
  );

  const sendAgain = async () => {
    try {
      await makeLoginWithPost(email, password);
      toast.success('OTP sent successfully');
    } catch (_error) {
      toast.error('Failed to send OTP');
      console.error('Error sending OTP:', _error);
    }
  };

  useEffect(() => {
    if (value.length === 6) {
      validateOtp(value);
    } else {
      setIsValid(null);
    }
  }, [value, validateOtp]);

  useEffect(() => {
    if (error) {
      setIsValid(false);
      toast.error(
        capitalizeFirstLetter(error?.message || '') ||
          'An unexpected error occurred during verification'
      );
    }
  }, [error]);

  if (loading) {
    return <LoadingModal />;
  }

  return (
    <>
      <div className="flex justify-center h-[650px] flex-col">
        <div className="flex justify-center flex-col items-center">
          <OtpVerificationIcon />
          <p className="font-semibold text-3xl mt-4">Verify OTP</p>
          <p className="text-greyscale text-medium font-semibold mt-2">
            Enter 6 digit code sent to your email address
          </p>
          <p className="text-greyscale text-medium font-semibold">{email}</p>
        </div>
        <div className="flex justify-center pt-4 flex-col items-center">
          <div className="flex mt-5">
            <InputOTP
              maxLength={6}
              pattern={REGEXP_ONLY_DIGITS}
              value={value}
              onChange={(newValue) => setValue(newValue)}>
              <InputOTPGroup className="flex gap-x-2">
                {Array(6)
                  .fill(0)
                  .map((_, i) => (
                    <InputOTPSlot
                      className={cn(
                        'h-[48px] w-[48px] rounded-[10px] first:rounded-l-[10px] last:rounded-r-[10px]',
                        {
                          'border-successGreen bg-lightGreen bg-opacity-10 text-brightGreen ring-0 border font-semibold':
                            isValid === true,
                          'border-errorRed bg-lightRed bg-opacity-10 text-smoothRed border ring-0 font-semibold':
                            isValid === false,
                          'border border-mediumPurple ring-0 bg-lightPurple bg-opacity-10 font-semibold text-darkPurple':
                            isValid === null && value[i],
                          'border border-borderGrey ring-0':
                            isValid === null && !value[i],
                        }
                      )}
                      index={i}
                    />
                  ))}
              </InputOTPGroup>
            </InputOTP>
          </div>
        </div>
        <div className="flex justify-center items-center mt-2">
          <RenderErrorMessage
            error={
              isValid === false
                ? 'Sorry, we are unable to verify the code you entered. \nPlease make sure you input the right OTP code.'
                : ''
            }
          />
        </div>
        <div className="flex justify-center items-center">
          <p className="mt-4 text-medium font-semibold text-greyscale">
            OTP timeout in ({timeLeft}s).
          </p>
          <button
            onClick={() => {
              sendAgain();
              setOtpSent(true);
              setTimeLeft(60);
            }}
            className="pl-1 mt-4 flex text-mediumPurple font-semibold ml-2"
            disabled={otpSent && timeLeft > 0}>
            {otpSent && timeLeft === 0 ? 'Send again' : ''}
          </button>
        </div>
      </div>
      <div className="flex justify-center items-center gap-1 mt-20">
        <CopyrightIcon />
        <p className="text-xs text-textGrey">
          {currentYear} Cashia Limited All Rights Reserved
        </p>
      </div>
    </>
  );
};

export default OtpVerification;
