import {RefundStatus} from '../../../generated';

export const mapRefundStatus = (
  status: RefundStatus | undefined | null
): string => {
  if (!status) return '';

  switch (status) {
    case RefundStatus.Accepted:
      return 'Success';
    case RefundStatus.InReview:
      return 'Pending';
    case RefundStatus.Cancelled:
      return 'Failed';
    default:
      return status;
  }
};
