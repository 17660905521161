import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
  toast,
} from '@cashiaApp/web-components';
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {ValidationErrors} from './types/types';
import {ReactComponent as DropdownIcon} from '../../assets/icons/downArrow.svg';
import {ReactComponent as FacebookIcon} from '../../assets/icons/facebook.svg';
import {ReactComponent as ImagePlaceHolderIcon} from '../../assets/icons/img-placeholder.svg';
import {ReactComponent as InstagramIcon} from '../../assets/icons/instagram.svg';
import {ReactComponent as Delete} from '../../assets/icons/red-trash.svg';
import {ReactComponent as Tick} from '../../assets/icons/tick-circle.svg';
import {ReactComponent as TiktokIcon} from '../../assets/icons/tiktok.svg';
import CustomAutocomplete from '../../components/common/AddressInput';
import CustomPhoneInput from '../../components/common/CustomPhoneInput';
import DropDown from '../../components/common/DropDown';
import FormInput from '../../components/common/FormInput';
import Navbar from '../../components/common/Navbar';
import Spinner from '../../components/common/Spinner';
import {
  GetUserDocument,
  Media,
  useGetBanksQuery,
  useGetBusinessCategoriesQuery,
  useGetFeeProfilesQuery,
  User,
  useSubmitBusinessOnboardingMutation,
  useUpdateBusinessOnboardingMutation,
  useUploadMediaMutation,
  VerificationIdType,
} from '../../generated';
import {hasDigits} from '../../utils/helper/validation';
import {cn} from '../../utils/reusablefunctions';
import RenderErrorMessage from '../../utils/reusablefunctions/errorMessage';
import validateKenyanPhoneNumber from '../../utils/validatePhoneNumber';

interface FormErrors {
  [key: string]: string;
}

interface DropdownStates {
  category: boolean;
  idType: boolean;
  bank: boolean;
  feeProfileId: boolean;
}

interface FormData {
  businessInfo: {
    businessName?: string;
    category?: string;
    description?: string;
    phoneNumber?: {
      countryCode: string;
      number: string;
    };
    location?: string;
    building?: string;
  };
  ownerInfo: {
    firstName?: string;
    lastName?: string;
    idType?: VerificationIdType;
    idPhotos?: string[];
    idFile?: File | Media;
    address?: {
      location?: string;
      coordinates?: {
        lat: number;
        long: number;
      };
      buildingName?: string;
    };
  };
  bankInfo: {
    bankId?: string;
    accountHolder?: string;
    accountNumber?: string;
  };
  socialMedia: {
    website?: string;
    facebook?: string;
    instagram?: string;
    tiktok?: string;
  };
  fees: {
    feeProfileId: string;
  };
}

const FormFieldWrapper: React.FC<{
  label: string;
  required?: boolean;
  children: React.ReactNode;
}> = ({label, required, children}) => {
  return (
    <div className="mb-4 max-md:mb-0">
      <p className="font-medium text-grey sm:text-[15px] md:text-[16px] flex items-center">
        {label} {required && <span>*</span>}
      </p>
      {children}
    </div>
  );
};

const validateBusinessInfo = (data: FormData['businessInfo']): FormErrors => {
  const errors: FormErrors = {};
  if (!data?.businessName?.trim()) {
    errors.businessName = 'Business name is required';
  }
  if (!data?.category?.trim()) errors.category = 'Category is required';
  if (!data?.description?.trim()) {
    errors.description = 'Description is required';
  }
  if (!data?.phoneNumber?.countryCode?.trim()) {
    errors.phoneNumber = 'Country code is required';
  }
  if (!data?.phoneNumber?.number?.trim()) {
    errors.phoneNumber = 'Phone number is required';
  } else if (!validateKenyanPhoneNumber(data?.phoneNumber?.number?.trim())) {
    errors.phoneNumber = 'Phone number is invalid';
  }
  if (!data?.location?.trim()) errors.location = 'Location is required';

  return errors;
};

const validateOwnerInfo = (data: FormData['ownerInfo']): FormErrors => {
  const errors: FormErrors = {};
  if (!data?.firstName?.trim()) errors.firstName = 'First name is required';
  if (!data?.lastName?.trim()) errors.lastName = 'Last name is required';
  if (!data?.idType) errors.idType = 'ID type is required';
  if (!data?.idFile) errors.id = 'Identity document is required';
  if (!data?.address?.location?.trim()) errors.address = 'Address is required';

  return errors;
};

const validateBankInfo = (data: FormData['bankInfo']): FormErrors => {
  const errors: FormErrors = {};
  if (!data?.bankId) errors.bankId = 'Bank name is required';
  if (!data?.accountHolder?.trim()) {
    errors.accountHolder = 'Account holder name is required';
  }
  if (!data?.accountNumber?.trim()) {
    errors.accountNumber = 'Account number is required';
  } else {
    const num = data.accountNumber.trim();
    if (!/^\d*$/.test(num)) {
      errors.accountNumber =
        'Please enter a valid Account number using only numeric characters.';
    } else if (num.length < 10) {
      errors.accountNumber = 'Account number must be at least 10 digits.';
    } else if (num.length > 14) {
      errors.accountNumber = 'Account number must not exceed 14 digits.';
    }
  }
  return errors;
};

const validateFees = (data: FormData['fees']): FormErrors => {
  const errors: ValidationErrors['fees'] = {};
  if (!data.feeProfileId) errors.feeProfileId = 'Profile code is required';
  return errors;
};

const isFile = (file: File | Media): file is File => {
  return (file as File).name !== undefined;
};

const idTypes: Record<VerificationIdType, string> = {
  [VerificationIdType.AlienCard]: 'Alien',
  [VerificationIdType.NationalId]: 'National ID',
  [VerificationIdType.Passport]: 'Passport',
};

const CreateProfile = () => {
  const {id} = useParams<{id: string}>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<ValidationErrors>({
    businessInfo: {},
    ownerInfo: {},
    bankInfo: {},
    fees: {},
  });
  const [dropdownStates, setDropdownStates] = useState<DropdownStates>({
    category: false,
    idType: false,
    bank: false,
    feeProfileId: false,
  });

  const [formData, setFormData] = useState<FormData>({
    businessInfo: {
      phoneNumber: {
        countryCode: '+254',
        number: '',
      },
    },
    ownerInfo: {
      idPhotos: [],
      address: {
        coordinates: {
          lat: 0,
          long: 0,
        },
      },
    },
    bankInfo: {},
    socialMedia: {},
    fees: {
      feeProfileId: '',
    },
  });

  const {data: businessCategoriesData} = useGetBusinessCategoriesQuery();
  const {data: banksData} = useGetBanksQuery();
  const {data: feeProfilesData} = useGetFeeProfilesQuery();
  const feeProfiles = feeProfilesData?.feeProfiles || [];
  const [
    uploadMediaMutation,
    {loading: uploadMediaLoading, error: uploadMediaError},
  ] = useUploadMediaMutation();

  const [updateBusinessOnboarding] = useUpdateBusinessOnboardingMutation();
  const [submitBusinessOnboarding] = useSubmitBusinessOnboardingMutation();
  const [saveLoading, setSaveLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [addressValidationState, setAddressValidationState] = useState({
    businessAddress: true,
    residentialAddress: true,
  });
  const businessCategories = useMemo(() => {
    const copy = [...(businessCategoriesData?.businessCategories || [])];
    return copy.sort((a, b) => a?.name?.localeCompare(b?.name));
  }, [businessCategoriesData]);

  const banks = useMemo(() => {
    const sortedBanks = [...(banksData?.banks || [])];
    return sortedBanks.sort((a, b) => a.name.localeCompare(b.name));
  }, [banksData]);

  const isFormValid = useMemo(() => {
    const hasValidationErrors = Object.values(errors).some(
      (section: ValidationErrors[keyof ValidationErrors]) =>
        section &&
        typeof section === 'object' &&
        Object.values(section).some((error) => error !== undefined)
    );
    const businessInfoValid =
      Object.keys(validateBusinessInfo(formData.businessInfo)).length === 0 &&
      addressValidationState.businessAddress;
    const ownerInfoValid =
      Object.keys(validateOwnerInfo(formData.ownerInfo)).length === 0 &&
      addressValidationState.residentialAddress;
    const bankInfoValid =
      Object.keys(validateBankInfo(formData.bankInfo)).length === 0;
    const feesValid = Object.keys(validateFees(formData.fees)).length === 0;

    return (
      !hasValidationErrors &&
      businessInfoValid &&
      ownerInfoValid &&
      bankInfoValid &&
      feesValid
    );
  }, [formData, addressValidationState, errors]);

  const breadcrumbs = useMemo(() => {
    const params = searchParams.toString();
    return [
      {
        name: 'Accounts',
        location: `/accounts${params ? `?${params}` : ''}`,
      },

      {
        name: 'Account Details',
        location: `/accounts/account-details/${id}${params ? `?${params}` : ''}`,
      },

      {
        name: 'Add New Business Profile',
      },
    ];
  }, [searchParams, id]);

  const handleDropdownChange =
    (key: keyof DropdownStates) => (value: SetStateAction<boolean>) => {
      setDropdownStates((prev) => ({
        ...prev,
        [key]: typeof value === 'function' ? value(prev[key]) : value,
      }));
    };

  const handleSave = async () => {
    setSaveLoading(true);
    const businessInfoErrors = validateBusinessInfo(formData.businessInfo);
    const ownerInfoErrors = validateOwnerInfo(formData.ownerInfo);
    const bankInfoErrors = validateBankInfo(formData.bankInfo);
    const feesErrors = validateFees(formData.fees);

    setErrors({
      businessInfo: businessInfoErrors,
      ownerInfo: ownerInfoErrors,
      bankInfo: bankInfoErrors,
      fees: feesErrors,
    });

    if (
      Object.keys(businessInfoErrors).length > 0 ||
      Object.keys(ownerInfoErrors).length > 0 ||
      Object.keys(bankInfoErrors).length > 0 ||
      Object.keys(feesErrors).length
    ) {
      setSaveLoading(false);
      return;
    }
    {
      try {
        if (!formData.ownerInfo.idPhotos?.length) {
          setErrors((prev) => ({
            ...prev,
            ownerInfo: {
              ...prev.ownerInfo,
              id: 'Please upload an ID document',
            },
          }));
          setSaveLoading(false);
          return;
        }

        const updateRes = await updateBusinessOnboarding({
          variables: {
            input: {
              userId: id,
              name: formData.businessInfo.businessName,
              categoryId: formData.businessInfo.category,
              description: formData.businessInfo.description,
              phoneNumber: formData.businessInfo.phoneNumber,
              address: {
                location: formData.businessInfo.location || '',
                coordinates: {
                  lat: 0,
                  long: 0,
                },
                buildingName: formData.businessInfo.building,
              },
              owner: {
                firstName: formData.ownerInfo.firstName,
                lastName: formData.ownerInfo.lastName,
                idType: formData.ownerInfo.idType,
                idPhotos: formData.ownerInfo.idPhotos,
                address: {
                  location: formData.ownerInfo.address?.location || '',
                  coordinates: {
                    lat: 0,
                    long: 0,
                  },
                  buildingName: formData.ownerInfo.address?.buildingName,
                },
              },
              bankAccount: {
                bankId: formData.bankInfo.bankId,
                accountHolder: formData.bankInfo.accountHolder,
                accountNumber: formData.bankInfo.accountNumber,
              },
              socialMedia: formData.socialMedia,
              feeProfileId: formData.fees.feeProfileId,
            },
          },
        });

        if (updateRes.data?.updateBusinessOnboarding) {
          const submitRes = await submitBusinessOnboarding({
            variables: {
              businessOnboardingId: updateRes.data.updateBusinessOnboarding.id,
            },
            update: (cache) => {
              type GetUserQueryResult = {user: User};
              const existingData = cache.readQuery<GetUserQueryResult>({
                query: GetUserDocument,
                variables: {id},
              });

              if (existingData?.user) {
                const newBusiness = {
                  id: updateRes?.data?.updateBusinessOnboarding.id,
                  name: formData.businessInfo.businessName,
                  category: {
                    id: formData.businessInfo.category,
                    name:
                      businessCategories.find(
                        (cat) => cat.id === formData.businessInfo.category
                      )?.name || '',
                    __typename: 'BusinessCategory',
                  },
                  complianceStatus: 'PENDING',
                  __typename: 'Business',
                };

                cache.writeQuery({
                  query: GetUserDocument,
                  variables: {id},
                  data: {
                    user: {
                      ...existingData.user,
                      businesses: [
                        ...(existingData.user.businesses || []),
                        newBusiness,
                      ],
                    },
                  },
                });
              }
            },
          });

          if (submitRes.data?.submitBusinessOnboarding) {
            setShowSuccessModal(true);

            setTimeout(() => {
              setShowSuccessModal(false);
              navigate(`/accounts/account-details/${id}`);
            }, 2000);
          }
        }
      } catch (error) {
        console.error('Error submitting business profile:', error);
        toast.error('Failed to submit business profile');
      } finally {
        setSaveLoading(false);
      }
    }
  };

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
        toast.error('Please upload only JPG or PNG files');
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        toast.error('File size must not exceed 5MB');
        return;
      }
      setFormData((prev) => ({
        ...prev,
        ownerInfo: {
          ...prev.ownerInfo,
          idFile: file,
        },
      }));

      try {
        const res = await uploadMediaMutation({
          variables: {file},
        });

        if (res.errors) {
          toast.error(uploadMediaError?.message);
          return;
        }

        const uploadedId = res?.data?.uploadMedia?.id || '';

        if (uploadedId) {
          setFormData((prev) => ({
            ...prev,
            ownerInfo: {
              ...prev.ownerInfo,
              idPhotos: [uploadedId],
              idFile: file,
            },
          }));

          setErrors((prev) => ({
            ...prev,
            ownerInfo: {
              ...prev.ownerInfo,
              id: undefined,
            },
          }));
        } else {
          toast.error('Failed to upload file. Please try again.');
        }
      } catch {
        toast.error('Failed to upload file. Please try again.');
      }
    }
  };

  const handleFileDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    fileInputRef.current!.value = '';
    setFormData((prev) => ({
      ...prev,
      ownerInfo: {
        ...prev.ownerInfo,
        idPhotos: [],
        idFile: undefined,
      },
    }));
  };

  const handleBusinessAddressSelect = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      businessInfo: {
        ...prev.businessInfo,
        location: value,
      },
    }));
    setErrors((prev) => ({
      ...prev,
      businessInfo: {
        ...prev.businessInfo,
        invalidBusinessAddress: undefined,
        location: undefined,
      },
    }));
    setAddressValidationState((prev) => ({
      ...prev,
      businessAddress: true,
    }));
  };

  const handleBusinessAddressBlur = (isValid: boolean) => {
    setAddressValidationState((prev) => ({
      ...prev,
      businessAddress: isValid,
    }));

    if (!isValid) {
      setErrors((prev) => ({
        ...prev,
        businessInfo: {
          ...prev.businessInfo,
          invalidBusinessAddress:
            'Please select an address from the suggestions list',
        },
      }));
    }
  };

  const handleResidentialAddressSelect = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      ownerInfo: {
        ...prev.ownerInfo,
        address: {
          ...prev.ownerInfo.address,
          location: value,
        },
      },
    }));
    setErrors((prev) => ({
      ...prev,
      ownerInfo: {
        ...prev.ownerInfo,
        invalidResidentialAddress: undefined,
        address: undefined,
      },
    }));
    setAddressValidationState((prev) => ({
      ...prev,
      residentialAddress: true,
    }));
  };

  const handleResidentialAddressBlur = (isValid: boolean) => {
    setAddressValidationState((prev) => ({
      ...prev,
      residentialAddress: isValid,
    }));

    if (!isValid) {
      setErrors((prev) => ({
        ...prev,
        ownerInfo: {
          ...prev.ownerInfo,
          invalidResidentialAddress:
            'Please select an address from the suggestions list',
        },
      }));
    }
  };

  const handleFieldValidation = useCallback(() => {
    if (!formData) return;
    const {
      businessInfo: {phoneNumber},
      bankInfo: {accountNumber},
    } = formData;

    setErrors((prev) => {
      const newErrors = {...prev};

      if (phoneNumber?.number?.trim()) {
        const isValid = validateKenyanPhoneNumber(phoneNumber.number.trim());
        newErrors.businessInfo = {
          ...newErrors.businessInfo,
          phoneNumber: isValid ? undefined : 'Phone number is invalid',
        };
      }

      if (accountNumber?.trim()) {
        let accountNumberError;
        if (!/^\d*$/.test(accountNumber)) {
          accountNumberError = 'Please enter valid numeric characters only';
        } else if (accountNumber.length < 10) {
          accountNumberError = 'Account number must be at least 10 digits';
        } else if (accountNumber.length > 14) {
          accountNumberError = 'Account number must not exceed 14 digits';
        }
        newErrors.bankInfo = {
          ...newErrors.bankInfo,
          accountNumber: accountNumberError,
        };
      }

      return newErrors;
    });
  }, [formData]);

  useEffect(() => {
    handleFieldValidation();
  }, [handleFieldValidation]);

  return (
    <div>
      <Navbar
        title="Add New Business Profile"
        omitSearch
        breadcrumbs={breadcrumbs}
      />
      <div className="flex h-full border border-dividerGrey p-4 m-4 rounded-lg w-[75vw]">
        <div className="m-6 flex flex-col rounded-lg h-auto w-[75vw] overflow-hidden">
          <div className="mb-2 flex justify-between">
            <h1 className="font-semibold text-2xl whitespace-nowrap">
              Business information
            </h1>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="flex text-grey gap-6">
              <div className="w-[480px]" id="businessName">
                <FormInput
                  label="Business Name"
                  required
                  value={formData.businessInfo.businessName}
                  className="w-full text-grey text-[14px] font-[500]"
                  name="businessName"
                  maxLength={60}
                  error={
                    <RenderErrorMessage
                      error={errors.businessInfo?.businessName}
                    />
                  }
                  onChange={(val: string) =>
                    setFormData((prev) => ({
                      ...prev,
                      businessInfo: {
                        ...prev.businessInfo,
                        businessName: val,
                      },
                    }))
                  }
                />
              </div>
              <div>
                <p className="flex sm:font-medium md:font-medium  sm:text-[15px] md:text-[16px] font-[500] mb-2 gap-1 items-center leading-[16px] text-grey text-lg">
                  Business Category *
                  {errors.businessInfo?.category && (
                    <RenderErrorMessage error={errors.businessInfo.category} />
                  )}
                </p>
                <DropDown
                  dropDownPosition="center"
                  show={dropdownStates.category}
                  setShow={handleDropdownChange('category')}
                  className="w-full"
                  actionElement={
                    <div
                      id="businessCategory"
                      className={cn(
                        'px-[10px] flex flex-row items-center justify-between w-[480px] bg-transparent border-[1px] rounded-[5px] h-[46px] focus:outline-none focus:ring-0 focus:border-surfacePurple overflow-y-auto',
                        dropdownStates.category
                          ? 'border-surfacePurple'
                          : 'border-dividerGrey'
                      )}>
                      <p className="w-full overflow-hidden truncate font-[500] text-[14px]">
                        {businessCategories.find(
                          (x) => x.id === formData.businessInfo.category
                        )?.name || 'Select Category'}
                      </p>
                      <DropdownIcon />
                    </div>
                  }>
                  <div className="shadow-sm cursor-pointer w-full h-[412px] overflow-y-scroll">
                    {businessCategories.map((category) => (
                      <div
                        onClick={() => {
                          handleDropdownChange('category')(false);
                          setFormData((prev) => ({
                            ...prev,
                            businessInfo: {
                              ...prev.businessInfo,
                              category: category.id,
                            },
                          }));
                        }}
                        key={category.id}
                        id={`dropdown-item-${category.id}`}
                        className="hover:text-textPurple cursor-pointer flex items-center bg-white px-4 py-3 border-b-[1px] border-dividerGrey">
                        <p className="text-[14px] hover:text-textPurple text-grey font-[500]">
                          {category.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </DropDown>
              </div>
            </div>
            <div className="flex text-grey gap-6">
              <div className="w-[30vw] max-w-[480px] mb-4">
                <p className="flex sm:font-medium md:font-medium  sm:text-[15px] md:text-[16px] font-[500] mb-2 gap-1 items-center leading-[16px] text-grey text-lg">
                  Business Phone Number *
                </p>
                <div id="phoneNumber">
                  <CustomPhoneInput
                    countryCode={
                      formData.businessInfo.phoneNumber?.countryCode || ''
                    }
                    phoneNumber={
                      formData.businessInfo.phoneNumber?.number || ''
                    }
                    onChange={(countryCode: string, number: string) =>
                      setFormData((prev) => ({
                        ...prev,
                        businessInfo: {
                          ...prev.businessInfo,
                          phoneNumber: {countryCode, number},
                        },
                      }))
                    }
                    error={
                      errors.businessInfo?.phoneNumber ? (
                        <RenderErrorMessage
                          error={errors.businessInfo.phoneNumber}
                        />
                      ) : null
                    }
                  />
                </div>
              </div>
              <div className="w-[480px] mb-4">
                <p className="flex sm:font-medium md:font-medium  sm:text-[15px] md:text-[16px] font-[500] mb-2 gap-1 items-center leading-[16px] text-grey text-lg">
                  Business Address *
                </p>
                <div id="businessAddress" className="w-full">
                  <CustomAutocomplete
                    onSelect={handleBusinessAddressSelect}
                    onBlur={handleBusinessAddressBlur}
                    defaultValue={formData.businessInfo.location}
                    error={
                      errors.businessInfo?.location ||
                      errors.businessInfo?.invalidBusinessAddress
                    }
                  />
                </div>

                {(errors.businessInfo?.location ||
                  errors.businessInfo?.invalidBusinessAddress) && (
                  <RenderErrorMessage
                    error={
                      errors.businessInfo.location ||
                      errors.businessInfo.invalidBusinessAddress
                    }
                  />
                )}
              </div>
            </div>
            <div className="flex text-grey gap-6">
              <div className="w-[30vw] max-w-[480px]" id="businessDescription">
                <FormInput
                  label="Business Description"
                  required
                  value={formData.businessInfo.description}
                  className="w-full text-grey text-[14px] font-[500]"
                  error={
                    <RenderErrorMessage
                      error={errors.businessInfo?.description}
                    />
                  }
                  maxLength={60}
                  onChange={(val: string) =>
                    setFormData((prev) => ({
                      ...prev,
                      businessInfo: {
                        ...prev.businessInfo,
                        description: val,
                      },
                    }))
                  }
                />
              </div>
              <div className="w-[480px]" id="buildingName">
                <FormInput
                  label="Complex/Building Name"
                  value={formData.businessInfo.building}
                  className="w-full text-grey text-[14px] font-[500]"
                  onChange={(val: string) =>
                    setFormData((prev) => ({
                      ...prev,
                      businessInfo: {
                        ...prev.businessInfo,
                        building: val,
                      },
                    }))
                  }
                />
              </div>
            </div>
          </form>
          <div className="mb-2 flex justify-between">
            <h1 className="font-semibold text-2xl whitespace-nowrap">
              Business Owner Information
            </h1>
          </div>

          <form onSubmit={(e) => e.preventDefault()}>
            <div className=" flex text-grey gap-6">
              <div className="w-[480px]" id="ownerFirstName">
                <FormInput
                  label="First Name"
                  required
                  value={formData.ownerInfo.firstName}
                  className="w-full text-grey text-[14px] font-[500]"
                  error={
                    <RenderErrorMessage error={errors.ownerInfo?.firstName} />
                  }
                  onChange={(val: string) => {
                    setFormData((prev) => ({
                      ...prev,
                      ownerInfo: {
                        ...prev.ownerInfo,
                        firstName: val,
                      },
                    }));
                    if (hasDigits(val)) {
                      setErrors((prev) => ({
                        ...prev,
                        ownerInfo: {
                          ...prev.ownerInfo,
                          firstName:
                            'First name should not contain numeric values',
                        },
                      }));
                    } else {
                      setErrors((prev) => ({
                        ...prev,
                        ownerInfo: {
                          ...prev.ownerInfo,
                          firstName: undefined,
                        },
                      }));
                    }
                  }}
                />
              </div>
              <div className="w-[480px]" id="ownerLastName">
                <FormInput
                  label="Last Name"
                  required
                  value={formData.ownerInfo.lastName}
                  className="w-full text-grey text-[14px] font-[500]"
                  error={
                    <RenderErrorMessage error={errors.ownerInfo?.lastName} />
                  }
                  onChange={(val: string) => {
                    setFormData((prev) => ({
                      ...prev,
                      ownerInfo: {
                        ...prev.ownerInfo,
                        lastName: val,
                      },
                    }));

                    if (hasDigits(val)) {
                      setErrors((prev) => ({
                        ...prev,
                        ownerInfo: {
                          ...prev.ownerInfo,
                          lastName:
                            'Last name should not contain numeric values',
                        },
                      }));
                    } else {
                      setErrors((prev) => ({
                        ...prev,
                        ownerInfo: {
                          ...prev.ownerInfo,
                          lastName: undefined,
                        },
                      }));
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex text-grey gap-6">
              <div className="w-[480px]" id="idType">
                <p className="flex sm:font-medium md:font-medium  sm:text-[15px] md:text-[16px] font-[500] mb-2 gap-1 items-center leading-[16px] text-grey text-lg">
                  Identification Document Type *
                  {errors.ownerInfo?.idType && (
                    <RenderErrorMessage error={errors.ownerInfo.idType} />
                  )}
                </p>
                <DropDown
                  dropDownPosition="center"
                  show={dropdownStates.idType}
                  setShow={handleDropdownChange('idType')}
                  className="w-full"
                  actionElement={
                    <div
                      className={cn(
                        'px-[10px] flex flex-row items-center justify-between w-[33vw] max-w-[480px] bg-transparent border-[1px] rounded-[5px] h-[46px] focus:outline-none focus:ring-0 focus:border-surfacePurple overflow-y-auto',
                        dropdownStates.idType
                          ? 'border-surfacePurple'
                          : 'border-dividerGrey'
                      )}>
                      <p className="w-[450px] overflow-hidden truncate text-grey text-[14px] font-[500]">
                        {formData.ownerInfo.idType
                          ? idTypes[formData.ownerInfo.idType]
                          : 'Select ID Type'}
                      </p>
                      <DropdownIcon />
                    </div>
                  }>
                  <div className="shadow-sm cursor-pointer w-full overflow-y-scroll">
                    {Object.values(VerificationIdType).map((type) => (
                      <div
                        key={type}
                        id={`dropdown-item-${type}`}
                        onClick={() => {
                          handleDropdownChange('idType')(false);
                          setFormData((prev) => ({
                            ...prev,
                            ownerInfo: {
                              ...prev.ownerInfo,
                              idType: type,
                            },
                          }));
                        }}
                        className="hover:text-textPurple cursor-pointer flex items-center bg-white px-4 py-3 border-b-[1px] border-dividerGrey">
                        <p className="text-[14px] hover:text-textPurple text-grey font-[500]">
                          {idTypes[type]}
                        </p>
                      </div>
                    ))}
                  </div>
                </DropDown>
              </div>
              <div>
                <FormFieldWrapper
                  label="Copy of Valid Proof of Identity"
                  required>
                  <div className="flex flex-col gap-2 w-[480px]">
                    <div
                      className={cn(
                        'flex items-center gap-2 px-2 border-dashed border-borderColor border-[1px] w-[33vw] max-w-[480px] h-[48px] bg-disabledGrey rounded-lg',
                        errors.ownerInfo?.id
                          ? 'border-red-300'
                          : 'border-borderColor'
                      )}>
                      <ImagePlaceHolderIcon height={30} />
                      <span className="text-grey text-[14px] font-[500] truncate flex-1">
                        {uploadMediaLoading ? (
                          'Uploading...'
                        ) : formData.ownerInfo.idFile ? (
                          isFile(formData.ownerInfo.idFile) ? (
                            formData.ownerInfo.idFile.name
                          ) : (
                            'Image uploaded successfully'
                          )
                        ) : (
                          <p className="text-sm text-[14px] font-[500] text-gray-500">
                            Upload an image in PNG or JPG up to 5MB
                          </p>
                        )}
                      </span>
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        accept="image/jpeg,image/jpg,image/png"
                        onChange={handleFileSelect}
                      />
                      {formData.ownerInfo.idFile && !uploadMediaLoading ? (
                        <button onClick={handleFileDelete}>
                          <Delete />
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => fileInputRef.current?.click()}
                          disabled={uploadMediaLoading}
                          className={cn(
                            'px-3 py-1 border-dividerGrey border text-base hover:shadow-sm hover:shadow-surfacePurple hover:border-lightPurple rounded-lg bg-faintGrey text-fontGrey font-medium',
                            uploadMediaLoading &&
                              'opacity-50 cursor-not-allowed'
                          )}>
                          Browse
                        </button>
                      )}
                    </div>
                  </div>
                </FormFieldWrapper>
                {errors.ownerInfo?.id && (
                  <div className="mt-1">
                    <RenderErrorMessage error={errors.ownerInfo.id} />
                  </div>
                )}
              </div>
            </div>
            <div className="flex text-grey gap-6">
              <div className="w-[35vw] max-w-[480px]" id="residentialAddress">
                <p className="flex sm:font-medium md:font-medium  sm:text-[15px] md:text-[16px] font-[500] mb-2 gap-1 items-center leading-[16px] text-grey text-lg">
                  Residential Address *
                </p>
                <div id="ownerAddress">
                  <CustomAutocomplete
                    onSelect={handleResidentialAddressSelect}
                    onBlur={handleResidentialAddressBlur}
                    defaultValue={formData.ownerInfo.address?.location}
                    error={
                      errors.ownerInfo?.address ||
                      errors.ownerInfo?.invalidResidentialAddress
                    }
                  />
                </div>

                {(errors.ownerInfo?.address ||
                  errors.ownerInfo?.invalidResidentialAddress) && (
                  <RenderErrorMessage
                    error={
                      errors.ownerInfo.address ||
                      errors.ownerInfo.invalidResidentialAddress
                    }
                  />
                )}
              </div>
              <div className="w-[480px]">
                <FormInput
                  label="Complex/Building Name"
                  value={formData.ownerInfo.address?.buildingName}
                  className="w-full text-grey text-[14px] font-[500]"
                  onChange={(val: string) =>
                    setFormData((prev) => ({
                      ...prev,
                      ownerInfo: {
                        ...prev.ownerInfo,
                        address: {
                          ...prev.ownerInfo.address,
                          buildingName: val,
                        },
                      },
                    }))
                  }
                />
              </div>
            </div>
          </form>

          <div className="mb-2 flex justify-between">
            <h1 className="font-semibold text-2xl whitespace-nowrap">Fees</h1>
          </div>

          <form onSubmit={(e) => e.preventDefault()}>
            <p className="flex sm:font-medium md:font-medium sm:text-[15px] md:text-[16px] font-[500] mb-1 gap-1 items-center leading-[16px] text-grey text-lg">
              Fees profile *
              {errors.fees.feeProfileId && (
                <RenderErrorMessage error={errors.fees.feeProfileId} />
              )}
            </p>
            <p className="mb-1 font-light text-[12px] text-[#B2ABBF]">
              The fees type selected will affect the fees amount that are
              charged on payments
            </p>

            <div id="fees" className="mb-6">
              <DropDown
                dropDownPosition="center"
                show={dropdownStates.feeProfileId}
                setShow={handleDropdownChange('feeProfileId')}
                className="w-full"
                actionElement={
                  <div
                    className={cn(
                      'px-[10px] flex flex-row items-center justify-between w-[480px] bg-transparent border-[1px] rounded-[5px] h-[46px]',
                      dropdownStates.feeProfileId
                        ? 'border-surfacePurple'
                        : 'border-dividerGrey'
                    )}>
                    <p className="w-[450px] overflow-hidden truncate font-[500] text-[14px] text-grey">
                      {feeProfiles?.find(
                        (x) => x.id === formData.fees.feeProfileId
                      )?.name || 'Select Fee Profile'}
                    </p>
                    <DropdownIcon />
                  </div>
                }>
                <div className="shadow-sm cursor-pointer w-full overflow-y-scroll">
                  {feeProfiles?.map((profile) => (
                    <div
                      onClick={() => {
                        setDropdownStates(
                          (prev): DropdownStates => ({
                            ...prev,
                            feeProfileId: false,
                          })
                        );
                        setFormData(
                          (prev): FormData => ({
                            ...prev,
                            fees: {
                              ...prev.fees,
                              feeProfileId: profile.id,
                            },
                          })
                        );
                      }}
                      key={profile.id}
                      id={`dropdown-item-${profile.id}`}
                      className="hover:text-textPurple cursor-pointer flex items-center bg-white px-4 py-3 border-b-[1px] border-dividerGrey">
                      <p className="text-[14px] hover:text-textPurple text-grey font-[500]">
                        {profile.name}
                      </p>
                    </div>
                  ))}
                </div>
              </DropDown>
            </div>
          </form>

          <div className="mb-2 flex justify-between">
            <h1 className="font-semibold text-2xl whitespace-nowrap">
              Bank Account Details
            </h1>
          </div>

          <form onSubmit={(e) => e.preventDefault()}>
            <div className=" flex text-grey gap-6">
              <div className="w-[480px]">
                <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
                  Bank Name *
                  {errors.bankInfo?.bankId && (
                    <RenderErrorMessage error={errors.bankInfo.bankId} />
                  )}
                </p>
                <div id="bankName">
                  <DropDown
                    dropDownPosition="center"
                    show={dropdownStates.bank}
                    setShow={handleDropdownChange('bank')}
                    className="w-full"
                    actionElement={
                      <div
                        className={cn(
                          'px-[10px] flex flex-row items-center justify-between w-[480px] bg-transparent border-[1px] rounded-[5px] h-[46px]',
                          dropdownStates.bank
                            ? 'border-surfacePurple'
                            : 'border-dividerGrey'
                        )}>
                        <p className="w-[450px] overflow-hidden truncate font-[500] text-[14px]">
                          {banks.find((x) => x.id === formData.bankInfo.bankId)
                            ?.name || 'Select Bank'}
                        </p>
                        <DropdownIcon />
                      </div>
                    }>
                    <div className="shadow-sm cursor-pointer w-full h-[412px] overflow-y-scroll">
                      {banks.map((bank) => (
                        <div
                          onClick={() => {
                            setDropdownStates((prev) => ({
                              ...prev,
                              bank: false,
                            }));
                            setFormData((prev) => ({
                              ...prev,
                              bankInfo: {
                                ...prev.bankInfo,
                                bankId: bank.id,
                              },
                            }));
                          }}
                          key={bank.id}
                          id={`dropdown-item-${bank.id}`}
                          className="hover:text-textPurple cursor-pointer flex items-center bg-white px-4 py-3 border-b-[1px] border-dividerGrey">
                          <p className="text-[14px] hover:text-textPurple text-grey font-[500]">
                            {bank.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </DropDown>
                </div>
              </div>
              <div className="w-[480px]" id="accHolderName">
                <FormInput
                  label="Account Holder Name"
                  required
                  value={formData.bankInfo.accountHolder}
                  className="w-full text-grey text-[14px] font-[500]"
                  error={
                    errors.bankInfo.accountHolder ? (
                      <RenderErrorMessage
                        error={errors.bankInfo.accountHolder}
                      />
                    ) : null
                  }
                  onChange={(val: string) => {
                    setFormData((prev) => ({
                      ...prev,
                      bankInfo: {
                        ...prev.bankInfo,
                        accountHolder: val,
                      },
                    }));
                    if (hasDigits(val)) {
                      setErrors((prev) => ({
                        ...prev,
                        bankInfo: {
                          ...prev.bankInfo,
                          accountHolder:
                            'Account holder name should not contain numeric values',
                        },
                      }));
                    } else {
                      setErrors((prev) => ({
                        ...prev,
                        bankInfo: {
                          ...prev.bankInfo,
                          accountHolder: undefined,
                        },
                      }));
                    }
                  }}
                />
              </div>
            </div>
            <div className=" flex text-grey gap-6">
              <div className="w-[480px]" id="accNumber">
                <FormInput
                  label="Account Number"
                  required
                  value={formData.bankInfo.accountNumber}
                  className="w-full text-grey text-[14px] font-[500]"
                  error={
                    errors.bankInfo.accountNumber ? (
                      <RenderErrorMessage
                        error={errors.bankInfo.accountNumber}
                      />
                    ) : null
                  }
                  onChange={(val: string) =>
                    setFormData((prev) => ({
                      ...prev,
                      bankInfo: {
                        ...prev.bankInfo,
                        accountNumber: val.replace(/\D/g, ''),
                      },
                    }))
                  }
                />
              </div>
            </div>
          </form>

          <div className="mb-2 flex justify-between">
            <h1 className="font-semibold text-2xl whitespace-nowrap">
              Social Media
            </h1>
          </div>

          <form onSubmit={(e) => e.preventDefault()}>
            <div className="flex text-grey gap-6">
              <div className="w-[480px]" id="website">
                <FormInput
                  label="Website"
                  value={formData.socialMedia.website}
                  className="w-full text-grey text-[14px] font-[500]"
                  onChange={(val: string) =>
                    setFormData((prev) => ({
                      ...prev,
                      socialMedia: {
                        ...prev.socialMedia,
                        website: val,
                      },
                    }))
                  }
                />
              </div>
              <div className="flex flex-col text-grey">
                <p className="flex sm:font-medium md:font-medium  sm:text-[15px] md:text-[16px] font-[500] gap-1 items-center leading-[16px] text-grey text-lg flex-wrap">
                  Social Media
                </p>

                <div className="w-[480px]" id="facebook">
                  <FormInput
                    leftElement={<FacebookIcon />}
                    value={formData.socialMedia.facebook}
                    className="w-full text-grey text-[14px] font-[500]"
                    onChange={(val: string) =>
                      setFormData((prev) => ({
                        ...prev,
                        socialMedia: {
                          ...prev.socialMedia,
                          facebook: val,
                        },
                      }))
                    }
                  />
                </div>
                <div className="w-[480px]" id="instagram">
                  <FormInput
                    leftElement={<InstagramIcon />}
                    value={formData.socialMedia.instagram}
                    className="w-full text-grey text-[14px] font-[500]"
                    onChange={(val: string) =>
                      setFormData((prev) => ({
                        ...prev,
                        socialMedia: {
                          ...prev.socialMedia,
                          instagram: val,
                        },
                      }))
                    }
                  />
                </div>
                <div className=" flex text-grey">
                  <div className="w-[480px]" id="tiktok">
                    <FormInput
                      leftElement={<TiktokIcon />}
                      value={formData.socialMedia.tiktok}
                      className="w-full text-grey text-[14px] font-[500]"
                      onChange={(val: string) =>
                        setFormData((prev) => ({
                          ...prev,
                          socialMedia: {
                            ...prev.socialMedia,
                            tiktok: val,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Button
        id="saveButton"
        className={cn('m-4', {
          'bg-surfacePurple text-white': isFormValid,
          'bg-gray-300 text-gray-500': !isFormValid,
        })}
        onClick={handleSave}
        disabled={!isFormValid || saveLoading}>
        {saveLoading ? <Spinner className="fill-white" /> : 'Save'}
      </Button>
      <Modal open={showSuccessModal}>
        <ModalContent className="w-[33%] pb-10 p-10 border-lightGrey">
          <ModalTitle>
            <div className="flex flex-col justify-center items-center mb-4">
              <Tick className="mb-5 h-10 w-10" />
              <p className="font-semibold text-2xl">Business Profile Created</p>
              <p className="font-semibold text-2xl">Successfully</p>
            </div>
          </ModalTitle>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CreateProfile;
