import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';

export interface DateFilterObj {
  startDate?: Date;
  endDate?: Date;
}

export interface QueryParamState {
  statusFilters?: string[];
  methodFilters?: string[];
  dateFilters?: DateFilterObj[];
  actionedByFilters?: string[];
  activityTypeFilters?: string[];
  page: number;
  cursor: string | null;
  cursorStack?: string[];
  amountFilter?: {
    operator: string;
    amount?: string;
    maxAmount?: string;
  };
}

const useQueryParamState = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParamsState = useCallback(() => {
    const statusFilters = searchParams.get('status')?.split(',') || [];
    const methodFilters = searchParams.get('method')?.split(',') || [];
    const actionedByFilters = searchParams.get('actionedBy')?.split(',') || [];
    const activityTypeFilters =
      searchParams.get('activityType')?.split(',') || [];

    const parsedDateFilters: DateFilterObj[] = [];
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    if (startDate && endDate) {
      parsedDateFilters.push({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });
    }

    const amountOperator = searchParams.get('amountOperator') || undefined;
    const amountValue = searchParams.get('amountValue') || undefined;
    const maxAmountValue = searchParams.get('maxAmountValue') || undefined;

    const amountFilter = amountOperator
      ? {
          operator: amountOperator,
          amount: amountValue,
          maxAmount: maxAmountValue,
        }
      : undefined;

    const page = parseInt(searchParams.get('page') || '1', 10);
    const cursor = searchParams.get('cursor') || null;
    const cursorStack =
      searchParams.get('cursorStack')?.split(',').filter(Boolean) || [];

    return {
      statusFilters,
      methodFilters,
      dateFilters: parsedDateFilters,
      actionedByFilters,
      activityTypeFilters,
      amountFilter,
      page,
      cursor,
      cursorStack,
    };
  }, [searchParams]);

  const updateQueryParams = useCallback(
    ({
      statusFilters,
      methodFilters,
      dateFilters,
      actionedByFilters,
      activityTypeFilters,
      page,
      cursor,
      cursorStack,
      amountFilter,
    }: QueryParamState) => {
      const params = new URLSearchParams();

      if (Array.isArray(statusFilters) && statusFilters.length) {
        params.set('status', statusFilters.join(','));
      }

      if (Array.isArray(methodFilters) && methodFilters.length) {
        params.set('method', methodFilters.join(','));
      }

      if (Array.isArray(actionedByFilters) && actionedByFilters.length) {
        params.set('actionedBy', actionedByFilters.join(','));
      }

      if (Array.isArray(activityTypeFilters) && activityTypeFilters.length) {
        params.set('activityType', activityTypeFilters.join(','));
      }

      if (Array.isArray(dateFilters) && dateFilters.length > 0) {
        const latestDateFilter = dateFilters[0];
        if (latestDateFilter.startDate && latestDateFilter.endDate) {
          params.set('startDate', latestDateFilter.startDate.toISOString());
          params.set('endDate', latestDateFilter.endDate.toISOString());
        }
      }

      if (amountFilter?.operator) {
        params.set('amountOperator', amountFilter.operator);
        if (amountFilter.amount) {
          params.set('amountValue', amountFilter.amount);
        }
        if (amountFilter.maxAmount) {
          params.set('maxAmountValue', amountFilter.maxAmount);
        }
      }

      if (page > 1) {
        params.set('page', page.toString());
      }

      if (cursor) {
        params.set('cursor', cursor);
      }

      if (cursorStack?.length) {
        params.set('cursorStack', cursorStack.join(','));
      }

      setSearchParams(params);
    },
    [setSearchParams]
  );

  const resetState = useCallback(() => {
    setSearchParams(new URLSearchParams());
  }, [setSearchParams]);

  return {
    getParamsState,
    updateQueryParams,
    resetState,
  };
};

export default useQueryParamState;
