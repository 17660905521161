const shortenTxt = (
  txt: string,
  limit: number = 60,
  substringCount: number = 40
) => {
  if (txt?.length > limit) return `${txt.substring(0, substringCount)}...`;
  return txt;
};

export default shortenTxt;
