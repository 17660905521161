import React, {useState, useRef, useEffect, useMemo, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import ArrowRightIcon from '../../assets/icons/arrow-back-left.svg';
import Logo from '../../assets/icons/cashia_logo.svg';
import SearchStatus from '../../assets/icons/search-status.svg';
import getTermsAndConditionsData from '../../utils/T&C';
import {cn} from '../../utils/reusablefunctions';

interface SectionContent {
  id: string;
  title: string;
  content: string[];
}

const DocumentOutline: React.FC<{
  sections: SectionContent[];
  activeTab: string;
  onSectionClick: (sectionId: string) => void;
}> = ({sections, activeTab, onSectionClick}) => {
  const navigate = useNavigate();
  const outlineRef = useRef<HTMLDivElement>(null);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const activeItem = document.querySelector(`li[data-id="${activeTab}"]`);
    if (activeItem && outlineRef.current) {
      const outlineRect = outlineRef.current.getBoundingClientRect();
      const itemRect = activeItem.getBoundingClientRect();

      if (
        itemRect.top < outlineRect.top ||
        itemRect.bottom > outlineRect.bottom
      ) {
        activeItem.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }
  }, [activeTab]);

  return (
    <div className="border-r-2 border-displayGrey h-screen flex flex-col">
      <div className="flex gap-20 w-72 items-center p-4 mb-4 border-b-2 border-displayGrey">
        <img src={Logo} className="w-8 h-8" alt="Logo" />
        <div />
        <button
          onClick={goBack}
          className="flex items-center justify-between text-greyBlueish hover:text-textPurple transition-colors">
          <img
            className="h-[14px] w-[14px] mr-1"
            src={ArrowRightIcon}
            alt="Back arrow"
          />
          <span>Back</span>
        </button>
      </div>

      <div className="flex-1 overflow-y-auto" ref={outlineRef}>
        <div className="p-4">
          <div className="flex items-center mb-4">
            <img
              className="h-[14px] w-[14px] mr-1"
              src={SearchStatus}
              alt="Search icon"
            />
            <span className="text-grey font-medium">Document Outline</span>
          </div>

          <ul className="space-y-1">
            {sections.map((section, index) => (
              <li
                key={section.id}
                data-id={section.id}
                className={cn(
                  'py-2 cursor-pointer rounded-sm relative transition-colors duration-200',
                  activeTab === section.id
                    ? 'text-textPurple bg-lightPurple border-l-4 border-darkPurple pl-3'
                    : 'hover:text-textPurple text-gray pl-4'
                )}
                onClick={() => onSectionClick(section.id)}>
                <span className="text-sm">
                  {index + 1}. {section.title}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const DocumentContent: React.FC<{
  sections: SectionContent[];
  contentRef: React.RefObject<HTMLDivElement>;
  headerPadding: string;
}> = ({sections, contentRef, headerPadding}) => {
  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className={cn('p-6 transition-all duration-300', headerPadding)}>
        <h1
          className={cn(
            'font-bold text-darkPurple transition-all duration-300 text-3xl',
            {'text-xl': headerPadding.includes('text-xl')}
          )}>
          KASHIA SERVICES LIMITED ("KSL" "CASHIA") ADMINISTRATION PORTAL USER
          TERMS OF SERVICE
        </h1>
      </div>
      <div className="mx-8 border-b-2 border-displayGrey" />

      <div className="flex-1 overflow-y-auto p-8" ref={contentRef}>
        {sections.map((section, index) => (
          <div
            key={section.id}
            id={section.id}
            className="mb-12"
            style={
              index === sections.length - 1 ? {paddingBottom: '50vh'} : {}
            }>
            <h2 className="text-xl font-semibold mb-6 text-fontGrey">
              {section.title}
            </h2>
            <div className="space-y-4">
              {section.content.map((paragraph, idx) => {
                const isListItem = /^\d+\./.test(paragraph);

                if (isListItem) {
                  return (
                    <div key={idx} className="flex mb-3">
                      <span className="text-textGrey font-medium mr-2">
                        {paragraph.split('.')[0]}.
                      </span>
                      <p className="text-textGrey">
                        {paragraph.split('.').slice(1).join('.')}
                      </p>
                    </div>
                  );
                }

                return (
                  <p key={idx} className="text-textGrey mb-3">
                    {paragraph}
                  </p>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const throttle = <T extends (...args: unknown[]) => void>(
  func: T,
  delay: number
): T => {
  let lastCall = 0;
  return ((...args: Parameters<T>) => {
    const now = new Date().getTime();
    if (now - lastCall >= delay) {
      lastCall = now;
      func(...args);
    }
  }) as T;
};

const debounce = <T extends (...args: unknown[]) => void>(
  func: T,
  delay: number
): T => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  return ((...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
      timeoutId = null;
    }, delay);
  }) as T;
};

const TermsAndConditionsViewer: React.FC = () => {
  const termsAndConditions = useMemo(() => getTermsAndConditionsData(), []);
  const [activeTab, setActiveTab] = useState<string>(
    termsAndConditions[0]?.id || 'tab_item_0'
  );
  const [headerPadding, setHeaderPadding] = useState<string>('py-10 text-3xl');

  const contentRef = useRef<HTMLDivElement>(null);
  const manualScrollRef = useRef<boolean>(false);
  const timeoutRef = useRef<number | null>(null);
  const lastActiveTabRef = useRef<string>(activeTab);

  const determineActiveSection = useCallback(() => {
    if (manualScrollRef.current) return;

    const contentElement = contentRef.current;
    if (!contentElement) return;

    if (contentElement.scrollTop < 10) {
      const firstSection = termsAndConditions[0];
      if (firstSection && lastActiveTabRef.current !== firstSection.id) {
        lastActiveTabRef.current = firstSection.id;
        setActiveTab(firstSection.id);
      }
      return;
    }

    if (
      contentElement.scrollHeight -
        contentElement.scrollTop -
        contentElement.clientHeight <
      50
    ) {
      const lastSection = termsAndConditions[termsAndConditions.length - 1];
      if (lastSection && lastActiveTabRef.current !== lastSection.id) {
        lastActiveTabRef.current = lastSection.id;
        setActiveTab(lastSection.id);
      }
      return;
    }

    const sections = termsAndConditions.map((section) => {
      const element = document.getElementById(section.id);
      if (!element) return {id: section.id, top: Infinity};

      const rect = element.getBoundingClientRect();
      return {id: section.id, top: rect.top};
    });

    const closestSection = sections.reduce((prev, curr) =>
      Math.abs(curr.top) < Math.abs(prev.top) ? curr : prev
    );

    if (closestSection && lastActiveTabRef.current !== closestSection.id) {
      lastActiveTabRef.current = closestSection.id;
      setActiveTab(closestSection.id);
    }
  }, [termsAndConditions]);

  useEffect(() => {
    const handleScroll = () => {
      const contentElement = contentRef.current;
      if (!contentElement) return;

      const scrollPosition = contentElement.scrollTop;

      if (scrollPosition > 50) {
        setHeaderPadding('py-4 text-xl');
      } else {
        setHeaderPadding('py-10 text-3xl');
      }
      determineActiveSection();
    };

    const throttledScroll = throttle(handleScroll, 100);
    const debouncedScroll = debounce(handleScroll, 150);

    const scrollHandler = () => {
      throttledScroll();
      debouncedScroll();
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', scrollHandler, {passive: true});
    }

    return () => {
      if (contentElement) {
        contentElement.removeEventListener('scroll', scrollHandler);
      }
    };
  }, [determineActiveSection]);

  useEffect(() => {
    const timer = setTimeout(determineActiveSection, 300);
    return () => clearTimeout(timer);
  }, [determineActiveSection, termsAndConditions]);

  const scrollToSection = useCallback((sectionId: string) => {
    setActiveTab(sectionId);
    lastActiveTabRef.current = sectionId;
    manualScrollRef.current = true;
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    timeoutRef.current = window.setTimeout(() => {
      manualScrollRef.current = false;
    }, 1000);
  }, []);

  if (!termsAndConditions || termsAndConditions.length === 0) {
    return (
      <div className="p-8 text-center">
        No terms and conditions data available.
      </div>
    );
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <DocumentOutline
        sections={termsAndConditions}
        activeTab={activeTab}
        onSectionClick={scrollToSection}
      />
      <DocumentContent
        sections={termsAndConditions}
        contentRef={contentRef}
        headerPadding={headerPadding}
      />
    </div>
  );
};

export default TermsAndConditionsViewer;
