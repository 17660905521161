import {useEffect, useState} from 'react';

import Breadcrumb, {BreadcrumbType} from './Breadcrumb';
import Fab from './Fab';
import Tabs, {Tab} from './Tabs';
import TextInput from './TextInput';
import notificationIcon from '../../assets/icons/notification.svg';
import dashboardSearchIcon from '../../assets/icons/search-normal.svg';
import searchIcon from '../../assets/icons/search_icon.svg';
import useDebounce from '../../utils/helpers';

type Props = {
  title: string | JSX.Element;
  tabs?: Tab[];
  breadcrumbs?: BreadcrumbType[];
  placeholder?: string;
  omitSearch?: boolean;
  isDashboard?: boolean;
  tabContainerStyles?: string;
  tabLabelStyles?: string;
  status?: boolean;
  searchPhrase?: string;
  setSearchPhrase?: React.Dispatch<React.SetStateAction<string>>;
};

const Navbar = ({
  title,
  tabs,
  placeholder,
  breadcrumbs,
  omitSearch,
  isDashboard,
  tabContainerStyles,
  tabLabelStyles,
  status,
  searchPhrase,
  setSearchPhrase,
}: Props) => {
  const [localSearchPhrase, setLocalSearchPhrase] = useState('');
  const debouncedSearch = useDebounce(localSearchPhrase, 500);

  useEffect(() => {
    setSearchPhrase?.(debouncedSearch);
  }, [debouncedSearch, setSearchPhrase]);

  return (
    <div className=" border-b-2 border-dividerGrey px-8 py-7 w-full z-50 sticky top-0 bg-displayGrey">
      <div className="flex items-center justify-between gap-16 p-0">
        <div className="flex min-w-[200px] flex-col gap-2">
          <h4 className="text-capitalize text-4xl font-semibold mb-1">
            {title}
          </h4>
          {breadcrumbs && <Breadcrumb breadcrumbs={breadcrumbs} />}
        </div>

        <div className="flex-auto">
          {!omitSearch && (
            <TextInput
              endAdornment={<img src={searchIcon} className="mr-3" />}
              placeholder={placeholder}
              inputStyle="h-6"
              value={searchPhrase}
              onChange={(e) => setLocalSearchPhrase(e.target.value)}
            />
          )}
        </div>

        <span className="flex gap-3">
          {isDashboard && (
            <Fab>
              <img src={dashboardSearchIcon} />
            </Fab>
          )}
          {status && (
            <Fab hasAlert>
              <img src={notificationIcon} />
            </Fab>
          )}
        </span>
      </div>

      {tabs && (
        <Tabs
          tabs={tabs}
          tabContainerStyles={tabContainerStyles}
          tabLabelStyles={tabLabelStyles}
        />
      )}
    </div>
  );
};

export default Navbar;
